import Api from '../helpers/Api';
import { UPDATE_ZONE_DETAILS, STUDENT_ZONE_DETAILS, UPDATE_STUDENT_BUCKET } from '../reducers/progress';
import { TEST_OVERVIEW } from './insight';
import {
  jsonToQueryString, getParentPortalDomain,
} from '../helpers/Utils';

export const UPDATE_FILTERS = 'InstituteProgress/UPDATE_FILTERS';
export const RESET_FILTERS = 'Dashboard/RESET_FILTERS';
export const CLASSES = 'All Classes';
export const CAMPUSES = 'All Campuses';
export const SUBJECTS = 'All Subjects';
export const SUBJECT_SELECTED = 'subjectSelected';
export const CAMPUS_SELECTED = 'campusSelected';
export const CLASSROOM_SELECTED = 'classroomSelected';
export const TRACK_SELECTED = 'trackSelected';
export const TEST_TYPE_SELECTED = 'testTypeSelected';

export function resetFilters() {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: RESET_FILTERS });
    resolve();
  });
}

export function isInstituteGoalsDisabled() {
  return () => Api({
    method: 'get',
    url: '/institute_insights/is_institute_goals_disabled',
  }).then((response) => {
    if (response.success) {
      return response;
    }
  });
}

export function updateInstituteGoals(payload) {
  return () => Api({
    method: 'post',
    url: '/institute_insights/update_goal_settings',
    data: {
      test_type: payload.testType,
      safe_zone_cutoff: payload.safeZoneCutoff,
      upgrade_zone_cutoff: payload.upgradeZoneCutoff,
      no_of_students: payload.studentStrength,
      students_in_safe_zone: payload.studentsInSafeZone,
    },
  }).then((response) => {
    return response;
  });
}

export function getInstituteProgress(filters) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institute_insights/get_zone_stats${jsonToQueryString(filters)}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        campuses: response.campuses,
        classrooms: response.classrooms,
        testType: response.test_type,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
      dispatch({
        type: UPDATE_ZONE_DETAILS,
        payload: {
          data: response.data,
          testTypeName: response.test_type_name,
          isSampleData: response.is_sample_data,
          hasAccessToParentPortal: response.has_access_to_parent_portal ? response.has_access_to_parent_portal : true,
        },
      });
    }
    return response;
  });
}

export function getStudentBucketDetails(filters) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institute_insights/get_student_bucket${jsonToQueryString(filters)}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        testType: response.test_type,
        testTypeSelected: response.test_type_id,
        campusSelected: response.campus_id,
        classroomSelected: response.classroom_id,
        subjectSelected: response.subject_id,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
      dispatch({
        type: UPDATE_STUDENT_BUCKET,
        payload: {
          testTypeName: response.test_type_name,
          studentsBucketData: response.students_data,
        },
      });
    }
    return response;
  });
}

export function getStudentDetails(filters, zone) {
  return (dispatch) => Api({
    method: 'get',
    url: `/institute_insights/get_student_zone_details${jsonToQueryString(filters)}&zone=${zone}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: STUDENT_ZONE_DETAILS,
        payload: {
          studentsData: response.students_data,
          subjects: response.subjects,
        },
      });
      const payload = {
        campuses: response.campuses,
        classrooms: response.classrooms,
        testType: response.test_type,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function redirectToParentPortal(studentId, testId, sampleTestId) {
  return () => {
    const isServer = typeof window === 'undefined';
    if (typeof testId !== 'undefined') {
      const testIdToRedirect = !!sampleTestId ? sampleTestId : testId;
      if (!isServer) {
        window.open(`${getParentPortalDomain()}/test_report/${testIdToRedirect}?student_id=${studentId}`);
      }
    } else if (!isServer) {
      window.open(`${getParentPortalDomain()}?student_id=${studentId}`);
    }
  };
}
