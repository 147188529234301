import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Header, SideBar,
} from '../common';
import Activity from './activity';
import { SIDE_BAR_ITEMS_STUDENT_DETAILS } from '../../shared/constants/fieldTypes';
import { getStudentInsightsData } from '../../actions/studentProgress';

class StudentInsights extends PureComponent {
  render() {
    const { match } = this.props;
    const { type, id } = match.params;
    const isActivity = type === 'activity';
    return (
      <div style={{ width: '100%' }}>
        <Header
          backgroundColor="#F5F8FA"
          replaceLocation=""
          menuData={SIDE_BAR_ITEMS_STUDENT_DETAILS[type]}
        />
        <div style={{ marginTop: 60 }}>
          <SideBar
            menuList={SIDE_BAR_ITEMS_STUDENT_DETAILS}
            selectedOption={type}
          />
          <div>
            {isActivity && <Activity id={id} />}
          </div>
        </div>
      </div>
    );
  }
}

StudentInsights.propTypes = {
  match: PropTypes.object,
};

StudentInsights.defaultProps = {
  match: {},
};

export default connect(null, {
  getStudentInsightsData,
})(StudentInsights);
