import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import { isMobileDevice } from '../../helpers/Utils';
import { FilterLabel, Search, Text } from '.';
import FilterIcon from '../../shared/images/filter-icon.svg';
import Dropdown from '../../shared/images/Dropdown.svg';
import Close from '../../shared/images/x.svg';
import ArrowLeftBlack from '../../shared/images/arrow-left-black.svg';
import Menu from '../../shared/images/menu.svg';
import ChevronDown from '../../shared/images/chevron-down.svg';
import ArrowLeft from '../../shared/images/arrow-left.svg';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import {
  updateFilterModel, updateMenuModel, updateFilterData, resetFilters,
} from '../../actions';
import {
  HIGHLIGHTS, TRACKS, TEST_LIST_PAGE, TEST_SELECTED, COMPARISION_TEST_SELECTED,
  TEST_COMPARSION, FILTER, TEST_LIST, COMPARE_WITH,
} from '../../shared/constants/textConstants';

const IS_MOBILE = isMobileDevice();
class Header extends React.Component {
  updateFilters(label, type) {
    const { updateFilterData, updateResults } = this.props;
    let value = label;
    if (!IS_MOBILE) {
      updateFilterData(`${type}Label`, label);
      value = label.value;
    }
    if (type === TEST_SELECTED) {
      updateResults({ id: value });
    }
    updateFilterData(type, value);
    const {
      testId, campusSelected, classroomSelected, subjectSelected, comparisionTestSelected,
    } = this.props;
    const payload = {
      id: testId,
      campus_id: campusSelected || '',
      classroom_id: classroomSelected || '',
      subject_id: subjectSelected || '',
      selected_test_id: comparisionTestSelected || '',
    };
    updateResults(payload);
  }

  resetFields() {
    const { resetFilters, updateResults, testId } = this.props;
    resetFilters();
    updateResults({ id: testId });
  }

  renderDropDowns(dataSet, selectedItem, selectedFunction, item, showFilter = true) {
    const { subjectSelected, menuData, name } = this.props;
    const isComparision = menuData.key === TEST_COMPARSION;
    const length = (name.length * 8) + 100;
    const selectBoxStyle = {
      control: (base) => ({
        ...base,
        height: 22,
        width: isComparision ? '100%' : length,
        cursor: 'pointer',
        border: isComparision ? '1px solid #E2E2E2' : 'none',
        outline: 'none',
        background: isComparision ? '#F2F2F2' : 'transparent',
        boxShadow: '0 !important',
        color: '#454545',
      }),
      option: (base) => ({
        ...base,
        color: '#454545',
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        width: 0,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: StyleConstants.color.webTitle,
        fontSize: isComparision ? 14 : 16,
        fontWeight: isComparision ? 500 : 'bold',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: StyleConstants.color.webTitle,
      }),
    };
    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={Dropdown} alt="dropdown" />
        </components.DropdownIndicator>
      );
    };
    if (!showFilter) {
      return (
        <div />
      );
    }
    const filterItems = [];
    if (item === TRACKS && subjectSelected) {
      for (let i = 1; i <= parseInt(dataSet[subjectSelected]); i += 1) {
        filterItems.push({ label: `Track ${i}`, value: i });
      }
    } else {
      for (let i = 0; i < dataSet.length; i += 1) {
        filterItems.push({ label: dataSet[i].name, value: dataSet[i].id });
      }
    }
    const boxWidth = { width: menuData.key === TEST_COMPARSION ? '40%' : 'fit-content' };

    return (
      <div style={boxWidth}>
        <Select
          value={selectedItem || filterItems[0]}
          components={menuData.key === TEST_COMPARSION ? { DropdownIndicator } : ''}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          styles={selectBoxStyle}
          isSearchable={false}
        />
      </div>
    );
  }

  render() {
    const {
      menuData, otherTests, comparisionTestSelectedLabel, previousTests, name,
      isFilter, filterType, isMenuBar, testId, institute, backButton, isSearchable,
      updateFilterModel, updateMenuModel, goBack, goToZonePage, updateResults,
      completedCountForTest, isOnline, showSmsBalance, smsBalance,
      getSearchData,
    } = this.props;
    if (IS_MOBILE) {
      return (
        <nav
          className="navbar fixed-top navbar-expand-md App-header"
          style={{
            backgroundColor: StyleConstants.color.primary, padding: menuData.key === TEST_LIST_PAGE ? 0 : 20, zIndex: isMenuBar ? 0 : '', height: 60, display: 'flex',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
            {isFilter
              ? (
                <div style={{ fontWeight: 18, display: 'flex', alignItems: 'center' }}>
                  {filterType === FILTER || filterType === TEST_LIST || filterType === COMPARE_WITH
                    ? (
                      <img
                        height={12}
                        width={12}
                        src={Close}
                        alt={Close}
                        role="presentation"
                        onClick={() => updateFilterModel(false, FILTER)}
                      />
                    )
                    : (
                      <img
                        height={14}
                        width={14}
                        src={ArrowLeft}
                        alt={ArrowLeft}
                        role="presentation"
                        onClick={() => updateFilterModel(true, FILTER)}
                      />
                    )}
                  <div style={{ fontSize: 18, margin: '0 15px' }}>{filterType}</div>
                </div>
              )
              : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {menuData.key !== TEST_LIST_PAGE
                    && (
                      <img
                        src={Menu}
                        alt={{ Menu }}
                        width={18}
                        height={12}
                        role="presentation"
                        onClick={() => updateMenuModel()}
                      />
                    )}
                  {menuData.key === HIGHLIGHTS
                    ? (
                      <div
                        style={{ display: 'flex', alignItems: 'center' }}
                        role="presentation"
                        onClick={() => { updateFilterModel(true, TEST_LIST); }}
                      >
                        <div style={{
                          margin: '0 15px', width: '80%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                        }}
                        >
                          {name}

                        </div>
                        <img
                          src={ChevronDown}
                          alt="ChevronDown"
                          height={6}
                          width={12}
                        />
                      </div>
                    )
                    : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {menuData.key === TEST_LIST_PAGE && (
                          <div style={{
                            fontFamily: 'Nunito Sans', fontSize: 24, fontWeight: 'bolder', textAlignLast: 'left', cursor: 'pointer', zIndex: 0, minWidth: '20%',
                          }}
                          >
                            <div
                              style={{
                                background: '#EDEDED', height: 35, width: 35, borderRadius: 3, margin: '0 5px 0 15px', textAlignLast: 'center',
                              }}
                              role="presentation"
                              onClick={() => { window.location.href = '/'; }}
                            >
                              <span style={{ color: StyleConstants.color.error }}>I</span>
                              <span style={{ color: '#43464B' }}>B</span>
                            </div>

                          </div>
                        )}
                        <div style={{ margin: '0 15px' }}>{menuData.name}</div>
                      </div>
                    )}
                </div>
              )}
          </div>
          {menuData && menuData.isFilter && !isFilter
            && (
              <div>
                <img
                  src={FilterIcon}
                  alt="FilterIcon"
                  width={20}
                  height={18}
                  role="presentation"
                  onClick={() => updateFilterModel(true, FILTER)}
                />
              </div>
            )}
        </nav>
      );
    }
    return (
      <div>
        <nav
          className="navbar fixed-top navbar-expand-md"
          style={{
            padding: 0, display: 'flex', background: StyleConstants.color.white, width: '100%', height: StyleConstants.headerHeight.web, boxShadow: '0 3px 6px #343B4014',
          }}
        >
          {menuData && menuData.key === TEST_LIST_PAGE
            ? (
              <div>
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '20%' }}>
                  <div style={{
                    fontFamily: 'Nunito Sans', fontSize: 26, fontWeight: 'bolder', textAlignLast: 'left', cursor: 'pointer', zIndex: 0, minWidth: '20%',
                  }}
                  >
                    <div
                      style={{
                        background: '#EDEDED', height: 40, width: 40, borderRadius: 3, margin: '15px 5px 15px 15px', textAlignLast: 'center',
                      }}
                      role="presentation"
                      onClick={() => { window.location.href = '/'; }}
                    >
                      <span style={{ color: StyleConstants.color.error }}>I</span>
                      <span style={{ color: '#43464B' }}>B</span>
                    </div>

                  </div>
                  {institute.instituteLogo
                  && (
                  <div style={{ background: '#EDEDED', marginLeft: 10, height: 40 }}>
                    <img src={institute.instituteLogo} width={96} alt={institute.name} style={{ display: 'block', margin: '7px 25px', height: 24 }} />
                  </div>
                  )}
                </div>
              </div>
            )
            : (
              <div style={{
                display: 'flex', alignItems: 'center', minWidth: '20%', background: '#132B50', height: '100%',
              }}
              >
                <div style={{
                  fontFamily: 'Nunito Sans', fontSize: 16, fontWeight: 'bolder', textAlignLast: 'left', cursor: 'pointer', zIndex: 0, minWidth: '20%',
                }}
                >
                  <div
                    style={{ marginLeft: 30 }}
                    role="presentation"
                    onClick={() => { window.location.href = menuData.isInstituteGoals ? '/' : `/${TEST_LIST_PAGE}`; }}
                  >
                    <span style={{ color: '#FF6946', fontSize: 20 }}>Insights </span>
                    <span style={{ fontSize: 20, color: StyleConstants.color.white }}>Board</span>
                  </div>
                </div>
              </div>
            )}
          {menuData
            && (
              <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 80px', width: '80%',
              }}
              >
                {backButton && backButton === 'ZonePage' && (
                  <div
                    style={{
                      marginLeft: 20, height: 30, width: 30, zIndex: 5, cursor: 'pointer',
                    }}
                    onClick={() => { goBack(); }}
                    role="presentation"
                  >
                    <img src={ArrowLeftBlack} alt="" />
                  </div>
                )}
                {backButton && backButton === 'StudentZoneDetails' && (
                  <div
                    style={{
                      marginLeft: 20, height: 30, width: 30, zIndex: 5, cursor: 'pointer',
                    }}
                    onClick={() => { goToZonePage(); }}
                    role="presentation"
                  >
                    <img src={ArrowLeftBlack} alt="" />
                  </div>
                )}
                {isSearchable
                  && (
                  <div style={{ marginLeft: 10 }}>
                    <Search
                      placeHolder="Search student name/id"
                      getSearchData={getSearchData}
                      searchBar
                    />
                  </div>
                  )}
                {menuData.isTestList
                  && otherTests && Object.keys(otherTests).length > 0
                  ? this.renderDropDowns(Object.values(otherTests), { label: name, value: testId }, (selectedListItem) => this.updateFilters(selectedListItem, TEST_SELECTED), 'All Tests')
                  : (
                    <div style={{
                      margin: '0 10px', whiteSpace: 'nowrap', overflow: 'hidden', color: StyleConstants.color.webTitle, fontWeight: 'bold',
                    }}
                    >
                      {name}
                    </div>
                  )}
                <div style={{
                  padding: '2%', width: 'fit-content', color: '#9EA0A5', fontWeight: StyleConstants.textSize,
                }}
                >
                  {menuData.isTestComparision && <div>vs</div>}

                </div>
                {menuData.isTestComparision && previousTests
                && Object.keys(previousTests).length > 0
                  && this.renderDropDowns(previousTests.over_all,
                    comparisionTestSelectedLabel, (selectedListItem) => this.updateFilters(selectedListItem, COMPARISION_TEST_SELECTED), 'All Tests')}
                {menuData.isFilter
                  && (
                    <FilterLabel
                      updateResults={updateResults}
                      menuData={menuData}
                      testId={testId}
                    />
                  )}
                  {menuData.key === HIGHLIGHTS && isOnline && (
                  <div>
                    <Text
                      fontWeight={StyleConstants.textWeight.semiBold}
                      fontSize={StyleConstants.textSize.subHeader}
                      text={`TestTaken : ${completedCountForTest} ${completedCountForTest > 1 ? 'Students' : 'Student'}`}
                    />
                  </div>
                  ) }
              </div>
            )}
          {showSmsBalance && (
          <div style={{ whiteSpace: 'nowrap', margin: 15, fontSize: 18 }}>
            SMS Balance:&nbsp;
            {smsBalance.toLocaleString()}
          </div>
          )}
        </nav>
      </div>
    );
  }
}

Header.propTypes = {
  updateFilterData: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  updateResults: PropTypes.func.isRequired,
  updateFilterModel: PropTypes.func.isRequired,
  updateMenuModel: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goToZonePage: PropTypes.func.isRequired,
  getSearchData: PropTypes.func.isRequired,
  menuData: PropTypes.object,
  name: PropTypes.string,
  subjectSelected: PropTypes.number,
  institute: PropTypes.object,
  otherTests: PropTypes.object,
  testSelectedLabel: PropTypes.object,
  previousTests: PropTypes.object,
  campusSelected: PropTypes.number,
  testSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  comparisionTestSelectedLabel: PropTypes.object,
  comparisionTestSelected: PropTypes.number,
  testId: PropTypes.number,
  isFilter: PropTypes.bool,
  completedCountForTest: PropTypes.number,
  filterType: PropTypes.string,
  isMenuBar: PropTypes.bool,
  backButton: PropTypes.string,
  isSearchable: PropTypes.bool,
  isOnline: PropTypes.bool,
  showSmsBalance: PropTypes.bool,
  smsBalance: PropTypes.number,
};

Header.defaultProps = {
  menuData: {},
  name: '',
  subjectSelected: '',
  campusSelected: '',
  classroomSelected: '',
  testSelected: '',
  institute: {},
  otherTests: {},
  completedCountForTest: 0,
  testSelectedLabel: {},
  comparisionTestSelectedLabel: {},
  comparisionTestSelected: '',
  previousTests: {},
  testId: 0,
  isFilter: false,
  filterType: FILTER,
  isMenuBar: false,
  backButton: '',
  isSearchable: false,
  isOnline: false,
  showSmsBalance: false,
  smsBalance: 0,
};


const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  institute: insight.institute,
  completedCountForTest: insight.completedCountForTest,
  otherTests: insight.otherTests,
  testSelectedLabel: insight.testSelectedLabel,
  previousTests: insight.previousTests,
  campusSelected: insight.campusSelected,
  testSelected: insight.testSelected,
  classroomSelected: insight.classroomSelected,
  comparisionTestSelectedLabel: insight.comparisionTestSelectedLabel,
  comparisionTestSelected: insight.comparisionTestSelected,
  subjectSelected: insight.subjectSelected,
  isFilter: insight.isFilter,
  filterType: insight.filterType,
  isMenuBar: insight.isMenuBar,
  isOnline: insight.isOnline,
});

export default connect(mapStateToProps, {
  updateFilterData, resetFilters, updateFilterModel, updateMenuModel,
})(Header);
