import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { SUBJECT_SELECTED } from '../../shared/constants/textConstants';
import { updateFilterData } from '../../actions';

class SubjectView extends PureComponent {

  updateFilters(value) {
    const { updateResults, updateFilterData } = this.props;
    updateFilterData(SUBJECT_SELECTED, value);
    const {
      testId, campusSelected, classroomSelected,
    } = this.props;
    const payload = {
      id: testId,
      campus_id: campusSelected || '',
      classroom_id: classroomSelected || '',
      subject_id: value,
    };
    updateResults(payload);
  }

  render() {
    const {
      subjects, subjectSelected,
    } = this.props;
    return (
      <div
        className="position-sticky Card-View"
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          overflowX: 'auto',
          marginRight: 0,
          top: 60,
          position: 'sticky',
          justifyContent: 'space-between',
          zIndex: 5,
        }}
      >
        <div style={{ margin: '13px 13px 0' }}>
          <div
            style={{
              paddingBottom: 13,
              color: subjectSelected
                ? StyleConstants.color.inActive
                : StyleConstants.color.primary,
            }}
            role="presentation"
            onClick={() => this.updateFilters('')}
          >
            Overall
          </div>
          <div style={{
            marginTop: -4,
            height: 4,
            background: subjectSelected ? '' : StyleConstants.color.primary,
            alignSelf: 'center',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          />
        </div>
        {Object.keys(subjects).map((subjectId) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              role="presentation"
              onClick={() => this.updateFilters(subjects[subjectId].id)}
            >
              <div style={{ color: StyleConstants.color.inActive }}>|</div>
              <div style={{ margin: '13px 13px 0' }}>
                <div
                  style={{
                    paddingBottom: 13,
                    color: subjectSelected === subjects[subjectId].id
                      ? StyleConstants.color.primary : StyleConstants.color.inActive,
                  }}
                >
                  {subjects[subjectId].name}
                </div>
                <div style={{
                  marginTop: -4,
                  height: 4,
                  background: subjects[subjectId].id === subjectSelected ? StyleConstants.color.primary : '',
                  alignSelf: 'center',
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

SubjectView.propTypes = {
  subjectSelected: PropTypes.number,
  updateResults: PropTypes.func.isRequired,
  updateFilterData: PropTypes.func.isRequired,
  subjects: PropTypes.object,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  testId: PropTypes.number,
};

SubjectView.defaultProps = {
  subjectSelected: '',
  subjects: {},
  campusSelected: '',
  classroomSelected: '',
  testId: 0,
};

const mapStateToProps = ({ insight }) => ({
  subjects: insight.subjects,
  subjectSelected: insight.subjectSelected,
  campusSelected: insight.campusSelected,
  classroomSelected: insight.classroomSelected,
});
export default connect(mapStateToProps, { updateFilterData })(SubjectView);
