import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../node_modules/react-vis/dist/style.css';
import upArrow from '../shared/images/up.svg';
import downArrow from '../shared/images/down.svg';
import { isMobileDevice } from '../helpers/Utils';
import {
  Header, SideBar, Loader, Filter, TestHeader,
} from './common';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';
import { getTestOverviewData, redirectToParentPortal, enableLoader } from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';

const IS_MOBILE = isMobileDevice();
const PERFORMANCE = ['increased', 'decreased'];


class performanceShifts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
    };
    this.updateResults = this.updateResults.bind(this);
  }


  componentDidMount() {
    const { id } = this.state;
    const { getTestOverviewData } = this.props;
    const payload = { id, performance: true };
    getTestOverviewData(payload);
    window.addEventListener('resize', this.resize);
  }


  updateResults(payload) {
    const { id } = this.state;
    const { getTestOverviewData, enableLoader } = this.props;
    if (payload.id !== id) {
      window.location.href = `/performance_shifts/${payload.id}`;
    }
    enableLoader();
    const performancePayload = payload;
    performancePayload.performance = true;
    getTestOverviewData(performancePayload);
  }

  handleRedirectToParentPortal(id, studentId) {
    const { sampleTestId, redirectToParentPortal } = this.props;
    redirectToParentPortal(studentId, id, sampleTestId);
  }

  render() {
    const { id } = this.state;
    const {
      studentsPerformanceChange, isFilter, campusSelected, campuses, name, date,
      classrooms, classroomSelected, showLoader,
    } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.PERFORMANCE_SHIFTS} />
          <Filter updateResults={this.updateResults} testId={id} menuData={SIDE_BAR_ITEMS.PERFORMANCE_SHIFTS} />
        </div>
      );
    }
    if (!IS_MOBILE) {
      return (
        <div className="row" style={{ backgroundColor: '#F5F8FA', userSelect: 'none', paddingTop: StyleConstants.headerHeight.web }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.PERFORMANCE_SHIFTS} />
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="PERFORMANCE_SHIFTS" testId={id} />
          </div>
          <div style={{ width: '80%', padding: '40px 80px 40px 40px' }}>
            {(Object.keys(studentsPerformanceChange.increased).length > 0
            || Object.keys(studentsPerformanceChange.decreased).length > 0)
              ? (
                <div
                  className="Card-View"
                  style={{
                    width: '100%', marginTop: 5, padding: 7,
                  }}
                >
                  <div style={{ color: '#43464B', fontSize: 18 }}>Major Rank Change</div>
                  <div style={{
                    display: 'flex', textAlign: 'center', placeContent: 'center', justifyContent: 'space-between',
                  }}
                  >
                    {PERFORMANCE.map((type) => {
                      return (
                        Object.keys(studentsPerformanceChange[type]).length > 0
                      && (
                      <table
                        className="table table-striped"
                        style={{
                          textAlign: 'center', margin: 15, fontSize: StyleConstants.textSize.text, color: '#43464B',
                        }}
                      >
                        <thead>
                          <tr style={{ fontWeight: 'bold' }}>
                            <td>RANK</td>
                            <td>NAME</td>
                            <td>SCORE</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(studentsPerformanceChange[type]).map((student) => {
                            return (
                              <tr style={{ fontSize: 12 }}>
                                <td style={{ whiteSpace: 'pre-wrap', color: type === 'increased' ? '#00C674' : StyleConstants.color.error, fontWeight: 'bold' }}>
                                  {studentsPerformanceChange[type][student].rank}
                                  (
                                  {type === 'increased' ? '+' : '-'}
                                  {Math.abs(
                                    studentsPerformanceChange[type][student].score_variation,
                                  )}
                                  )
                                </td>
                                <td
                                  style={{ cursor: 'pointer' }}
                                  role="presentation"
                                  onClick={() => this.handleRedirectToParentPortal(
                                    id, studentsPerformanceChange[type][student].student_id,
                                  )}
                                >
                                  {studentsPerformanceChange[type][student].name}

                                </td>
                                <td>{studentsPerformanceChange[type][student].current_total}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      )
                      );
                    })}
                  </div>
                </div>
              )
              : <div style={{ marginTop: 100, textAlign: 'center', fontSize: StyleConstants.textSize.secondaryHeader }}>There Are No Major Rank Changes</div>}
          </div>
        </div>
      );
    }
    return (
      <div style={{ marginTop: 60 }}>
        <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.PERFORMANCE_SHIFTS} />
        <TestHeader testName={name} testDate={date} />
        <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="PERFORMANCE_SHIFTS" testId={id} />
        <div style={{ margin: '0 20px' }}>
          <div style={{ display: 'flex', marginBottom: 15, marginTop: 15 }}>
            <div style={{
              background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '5px 10px', width: 'fit-content', fontSize: 12, borderRadius: 4, marginRight: 5,
            }}
            >
              {campusSelected && campuses[campusSelected] ? campuses[campusSelected].name : 'All campuses'}

            </div>
            <div style={{
              background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '5px 10px', width: 'fit-content', fontSize: 12, borderRadius: 4,
            }}
            >
              {classroomSelected && classrooms[classroomSelected] ? classrooms[classroomSelected].name : 'All classrooms'}
            </div>
          </div>
          <div>
            {PERFORMANCE.map((type) => {
              return (
                <div style={{ color: type === 'increased' ? '#00BF70' : StyleConstants.color.error, marginBottom: 10 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={type === 'increased' ? upArrow : downArrow} alt="" height={13} width={13} />
                    <div style={{ fontSize: 18, marginLeft: 4 }}>
                      {Object.keys(studentsPerformanceChange[type] || {}).length}
                      {' '}
                      Students
                    </div>
                  </div>
                  <div style={{ fontSize: 12 }}>{type === 'increased' ? 'significantly improved' : 'marks dropped sharply'}</div>
                  <div>
                    {Object.keys(studentsPerformanceChange[type]).map((student) => {
                      return (
                        <div
                          className="Card-View"
                          style={{
                            display: 'flex', marginTop: 5, alignItems: 'center', padding: 7, flexDirection: 'row',
                          }}
                        >
                          <img src={type === 'increased' ? upArrow : downArrow} alt="" height={13} width={13} />
                          <div style={{
                            fontSize: 10, marginRight: 10, marginLeft: 5, width: 21,
                          }}
                          >
                            {Math.abs(studentsPerformanceChange[type][student].score_variation)}

                          </div>
                          <div style={{
                            fontSize: 12,
                            marginRight: 10,
                            height: 33,
                            width: 33,
                            borderRadius: 22,
                            background: type === 'increased' ? StyleConstants.testHeader.backgroundColor : '#FFDBDB',
                            borderColor: type === 'increased' ? '#35C97B' : StyleConstants.color.error,
                            border: '1px solid',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          >
                            <div>{studentsPerformanceChange[type][student].rank}</div>

                          </div>
                          <div style={{
                            fontSize: StyleConstants.textSize.text, color: StyleConstants.textLineColors.dark, fontWeight: '600', marginRight: 'auto', textAlign: 'left',
                          }}
                          >
                            {studentsPerformanceChange[type][student].name}
                          </div>
                          <div style={{
                            fontSize: 12,
                            color: StyleConstants.textLineColors.dark,
                          }}
                          >
                            {studentsPerformanceChange[type][student].current_total}

                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

performanceShifts.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  campuses: PropTypes.object,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  classrooms: PropTypes.object,
  isFilter: PropTypes.bool,
  sampleTestId: PropTypes.number,
  studentsPerformanceChange: PropTypes.object,
  getTestOverviewData: PropTypes.func.isRequired,
  redirectToParentPortal: PropTypes.func.isRequired,
  enableLoader: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  match: PropTypes.object,
};

performanceShifts.defaultProps = {
  name: '',
  date: '',
  campuses: {},
  campusSelected: '',
  classroomSelected: '',
  classrooms: {},
  isFilter: false,
  sampleTestId: '',
  studentsPerformanceChange: {},
  showLoader: false,
  match: {},
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  date: insight.date,
  campuses: insight.campuses,
  studentsPerformanceChange: insight.studentsPerformanceChange,
  campusSelected: insight.campusSelected,
  classroomSelected: insight.classroomSelected,
  classrooms: insight.classrooms,
  isFilter: insight.isFilter,
  sampleTestId: insight.sampleTestId,
  showLoader: insight.showLoader,
});

export default connect(mapStateToProps,
  { getTestOverviewData, redirectToParentPortal, enableLoader })(performanceShifts);
