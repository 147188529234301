import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class LocaleDate extends PureComponent {
  render() {
    const { date, isDay } = this.props;
    if (!date) {
      return <div />;
    }
    return (
      <>
        <span style={{ paddingRight: 2 }}>
          {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', day: '2-digit' })}
            &nbsp;
        </span>
        <span>
          {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', month: 'short' })}
        </span>
        {isDay && (
        <span>
          <span>
            ,&nbsp;
          </span>
          <span>
            {date.toLocaleString('en-US', { timeZone: 'Asia/Kolkata', weekday: 'long' })}
          </span>
        </span>
        )}
      </>
    );
  }
}

export default connect(null, {})(LocaleDate);
