import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../node_modules/react-vis/dist/style.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select, { components } from 'react-select';
import Dropdown from '../shared/images/Dropdown.svg';
import {
  Header, Loader, SideBar, BackToTop,
} from './common';
import { textTruncate } from '../helpers/Utils';
import { INSTITUTE_GOALS_MENU } from '../shared/constants/fieldTypes';
import StyleConstants from '../shared/constants/styleConstants/styles';
import { getStudentBucketDetails, redirectToParentPortal } from '../actions/progress';
import { getTestOverviewData } from '../actions/insight';


class StudentBucket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      isLoading: true,
      activePage: 1,
      itemsCountPerPage: 20,
      displayScroll: false,
      fetchMoreData: false,
      studentBucketData: { label: 'Top', value: 'desc' },
      percentCount: { label: '10%', value: '10' },
    };
    this.updateResults = this.updateResults.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getStudentBucketDetails({ count_percent: 10, student_bucket: 'desc' }).then((response) => {
      if (response.success) {
        this.setState({ isLoading: false });
      }
    });
  }

  updateResults(filters) {
    const activeFilters = {};
    this.setState({ isLoading: true });
    const { studentBucketData, percentCount } = this.state;
    filters.count_percent = percentCount.value;
    filters.student_bucket = studentBucketData.value;
    this.props.getStudentBucketDetails(filters).then((response) => {
      if (response.success) {
        this.setState({ isLoading: false });
      }
    });
    Object.keys(filters).map((type) => {
      if (filters[type]) {
        activeFilters[type] = filters[type];
      }
    });
    this.setState({ filters: activeFilters });
  }

  fetchMoreData() {
    const { itemsCountPerPage } = this.state;
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        itemsCountPerPage: itemsCountPerPage + 20,
      });
    }, 500);
  }

  updateresultData() {
    const {
      testId, campusSelected, classroomSelected, subjectSelected,
      testTypeSelected,
    } = this.props;
    const { studentBucketData, percentCount } = this.state;
    const payload = {
      id: testId,
      campus_id: campusSelected || '',
      classroom_id: classroomSelected || '',
      subject_id: subjectSelected || '',
      test_type_id: testTypeSelected || '',
      count_percent: percentCount.value || '10',
      student_bucket: studentBucketData.value || 'desc',
    };
    return payload;
  }

  handleredirectToParentPortal(studentId) {
    this.props.redirectToParentPortal(studentId);
  }

  renderStudentList(data, index, minCount) {
    const textBreak = 30;
    const { campuses, campusSelected } = this.props;
    return (
      <tr id={`data-${parseInt(index, 10) + parseInt(minCount, 10)}`} key={`Student_${data.badge_id}}`}>
        <td>{index}</td>
        <td
          role="presentation"
          onClick={() => this.handleredirectToParentPortal(data.student_id)}
          style={{ whiteSpace: (data.name && data.name.length > textBreak) ? 'pre-wrap' : 'nowrap', textAlign: 'left' }}
        >
          {data.name ? textTruncate(data.name, textBreak) : ''}
        </td>
        <td style={{ fontWeight: 700 }}>{data.ib_score}</td>
        <td>{data.badge_id ? data.badge_id : ''}</td>
        <td style={{ whiteSpace: (data.classroom_name && data.classroom_name.length > textBreak) ? 'pre-wrap' : 'nowrap' }}>
          {data.classroom_name ? textTruncate(data.classroom_name, textBreak) : ''}
        </td>
        { Object.keys(campuses).length > 1 && !campusSelected && (
        <td style={{ whiteSpace: (data.campus_name && data.campus_name.length > textBreak) ? 'pre-wrap' : 'nowrap', textAlign: 'left' }}>
          {data.campus_name ? data.campus_name : ''}
        </td>
        ) }
        <style jsx>
          {`
            tr.border_bottom td {
              border-bottom:1px solid black;
            }            
          `}
        </style>
      </tr>
    );
  }

  render() {
    const {
      activePage, itemsCountPerPage, displayScroll, studentBucketData, percentCount,
    } = this.state;
    const {
      studentsBucketData, campusSelectedLabel, classroomSelectedLabel, subjectSelectedLabel,
      testTypeName, campuses, campusSelected,
    } = this.props;
    const minCount = activePage >= 2 ? (activePage - 1) * itemsCountPerPage : 0;
    const maxCount = (activePage) * itemsCountPerPage;

    let availableScreenHeight = 0;
    if (typeof window !== 'undefined') {
      availableScreenHeight = window.innerHeight;
    }
    const selectBoxStyle = {
      control: (base) => ({
        ...base,
        height: 37,
        backgroundColor: '#F2F2F2',
        border: '1px solid #E2E2E2',
        width: 140,
        fontSize: StyleConstants.textSize.text,
        cursor: 'pointer',
      }),
      option: (base) => ({
        ...base,
        border: '1px solid #F8F8F8',
        color: '#343B40',
      }),
      indicatorSeparator: (provided) => (
        {
          ...provided,
          width: 0,
        }
      ),
      singleValue: (provided) => ({
        ...provided,
        color: '#122E48',
        fontSize: StyleConstants.textSize.text,
        fontWeight: 'bold',
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#122E48',
          fontSize: StyleConstants.textSize.text,
        };
      },
    };
    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={Dropdown} alt="dropdown" />
        </components.DropdownIndicator>
      );
    };

    if (this.state.isLoading) {
      return <Loader />;
    }
    return (
      <div style={{ height: availableScreenHeight, backgroundColor: '#F5F8FA' }}>
        <Header
          menuData={INSTITUTE_GOALS_MENU[2]}
          updateResults={this.updateResults}
          backgroundColor="#F5F8FA"
        />
        <SideBar menuList={INSTITUTE_GOALS_MENU} selectedOption={2} />
        <div style={{
          backgroundColor: '#F5F8FA',
          paddingLeft: '20%',
          paddingRight: 0,
          zIndex: 0,
          marginTop: 62,
        }}
        >
          <div style={{
            display: 'flex', paddingTop: 25, justifyContent: 'space-between', marginRight: 30,
          }}
          >
            <div style={{
              display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: 30, fontSize: StyleConstants.textSize.tertiaryHeader, fontFamily: 'Nunito', fontWeight: '600',
            }}
            >
              { studentBucketData.label.concat(' ', percentCount.label, ' of ', testTypeName || 'NEET', ' ', subjectSelectedLabel ? subjectSelectedLabel.label : '', ' in ', classroomSelectedLabel ? (classroomSelectedLabel.label.concat(' Classroom, ')) : '', campusSelectedLabel ? campusSelectedLabel.label.concat(' Campus') : 'All Campuses') }
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Select
                components={{ DropdownIndicator }}
                value={studentBucketData}
                onChange={(selectedItem) => {
                  this.setState({ studentBucketData: selectedItem }, () => this.updateResults(this.updateresultData()));
                }}
                options={[{ label: 'Top', value: 'desc' }, { label: 'Bottom', value: 'asc' }]}
                styles={selectBoxStyle}
                isSearchable={false}
              />
              <div style={{ paddingLeft: 20 }}>
                <Select
                  components={{ DropdownIndicator }}
                  value={percentCount}
                  onChange={(selectedItem) => {
                    this.setState({ percentCount: selectedItem }, () => this.updateResults(this.updateresultData()));
                  }}
                  options={[{ label: '10%', value: '10' }, { label: '20%', value: '20' }]}
                  styles={selectBoxStyle}
                  isSearchable={false}
                />
              </div>

            </div>
          </div>
          <div style={{ margin: 'auto', padding: 30, fontFamily: 'Nunito' }}>
            { studentsBucketData.length > 0
              ? (
                <InfiniteScroll
                  dataLength={itemsCountPerPage}
                  next={this.fetchMoreData}
                  hasMore
                >
                  {displayScroll && <BackToTop />}
                  <table className="table table-striped " style={{ textAlign: 'center', marginBottom: 0 }}>
                    <thead>
                      <tr style={{ fontSize: StyleConstants.textSize.subHeader, fontFamily: 'Nunito', fontWeight: '400' }}>
                        <th rowSpan="2" style={{ verticalAlign: 'middle' }}> INDEX </th>
                        <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'left' }}> NAME </th>
                        <th rowSpan="2" style={{ verticalAlign: 'middle' }}> IB SCORE</th>
                        <th rowSpan="2" style={{ verticalAlign: 'middle' }}> BADGE ID </th>
                        <th rowSpan="2" style={{ verticalAlign: 'middle' }}> CLASS </th>
                        { Object.keys(campuses).length > 1 && !campusSelected && ( <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'left' }}> CAMPUS </th>) }
                      </tr>
                    </thead>
                    <tbody>
                      {studentsBucketData.slice(minCount, maxCount).map((data, index) => (
                        this.renderStudentList(data, index + 1, minCount)))}
                    </tbody>
                  </table>
                </InfiniteScroll>
              )
              : <div style={{ textAlign: 'center', marginTop: '20%' }}>No Students in this Bucket</div>}
          </div>
        </div>
      </div>
    );
  }
}

StudentBucket.propTypes = {
  studentsBucketData: PropTypes.array,
  testTypeName: PropTypes.string,
  testTypeSelected: PropTypes.string,
  campusSelected: PropTypes.string,
  classroomSelected: PropTypes.string,
  subjectSelected: PropTypes.string,
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  subjects: PropTypes.object,
  isFilter: PropTypes.bool,
  campusSelectedLabel: PropTypes.object,
  classroomSelectedLabel: PropTypes.object,
  subjectSelectedLabel: PropTypes.object,
};

StudentBucket.defaultProps = {
  studentsBucketData: [],
  testTypeName: '',
  testTypeSelected: '',
  campusSelected: '',
  classroomSelected: '',
  subjectSelected: '',
  campuses: {},
  classrooms: {},
  subjects: {},
  isFilter: {},
  campusSelectedLabel: {},
  classroomSelectedLabel: {},
  subjectSelectedLabel: {},
};

const mapStateToProps = ({ instituteProgress, insight }) => ({
  studentsBucketData: instituteProgress.studentsBucketData,
  testTypeName: instituteProgress.testTypeName,
  testTypeSelected: insight.testTypeSelected,
  campuses: insight.campuses,
  campusSelected: insight.campusSelected,
  campusSelectedLabel: insight.campusSelectedLabel,
  classroomSelectedLabel: insight.classroomSelectedLabel,
  subjectSelectedLabel: insight.subjectSelectedLabel,
  classrooms: insight.classrooms,
  subjects: insight.subjects,
  classroomSelected: insight.classroomSelected,
  subjectSelected: insight.subjectSelected,
  isFilter: insight.isFilter,
});

export default connect(mapStateToProps, { getStudentBucketDetails, redirectToParentPortal })(StudentBucket);
