import React, { Component } from 'react';
import Header from './common/header';
import { connect } from 'react-redux'
import { INSTITUTE_GOALS_MENU, TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';
import { updateInstituteGoals } from '../actions/progress';
import { ZONE_GOAL_COLORS, TEST_TYPE_SCORES,STUDENT_STRENGTH,STUDENTS_IN_SAFE_ZONE, MESSAGE_AFTER_UPDATE } from '../reducers/progress';
import SideBar from './common/SideBar';
import { showToast } from '../actions'
import FrontArrowBlue from '../shared/images/front-arrow-blue.svg';
import BackArrowBlue from '../shared/images/back-arrow-blue.svg';
import { isMobileDevice } from '../helpers/Utils';
const IS_MOBILE = isMobileDevice()
class InstituteGoalSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      safeZoneCutOff: '',
      upgradeZoneCutoff: '',
      isLoading: true,
      filters: {},
      testTypeSelected: 'NEET',
      studentStrength: '',
      studentsInSafeZone: '',
      inputTagString: '',
      warningSafe: false,
      warningBorder: false,
      testTypes: ['JEE', 'NEET'],
    };
    this.inputFieldChange = this.inputFieldChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goFront = this.goFront.bind(this);
    this.submit = this.submit.bind(this);
  }

  inputFieldChange(id, value) {
    const { testTypeSelected, safeZoneCutOff, studentStrength, testTypes  } = this.state;
    if (id === 'safe-cutoff') {
      if (value > 0 && value < TEST_TYPE_SCORES[testTypeSelected].MAX_SCORE) {
        this.setState({ safeZoneCutOff: value, warningSafe: false });
      } else {
        this.setState({ warningSafe: true });
      }
      if (value === '') {
        this.setState({ safeZoneCutOff: '', warningSafe: false });
      }
    } else if (id === 'upgrade-cutoff') {
      let safeZoneCutOffValue = TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE
      if (safeZoneCutOff !== '') {
        safeZoneCutOffValue = parseInt(safeZoneCutOff);
      }
      if (value === '') {
        this.setState({ upgradeZoneCutoff: '', warningBorder: false });
      } else if (value > 0 && value < safeZoneCutOffValue) {
        this.setState({ upgradeZoneCutoff: value, warningBorder: false });
      } else {
        this.setState({ warningBorder: true });
      }
    } else if (id === 'safe-students') {
      let studentStrengthValue = STUDENT_STRENGTH
      if (studentStrength !=='') {
         studentStrengthValue = parseInt(studentStrength)
      }
      if (value === '') {
        this.setState({ warningSafeStudents: false, studentsInSafeZone: value });
      }
      else if ( value > 0 && value < studentStrengthValue) {
        this.setState({ warningSafeStudents: false, studentsInSafeZone: value });
      }
      else {
        this.setState({ warningSafeStudents: true });  
      }
    } else if (id === 'test-type-neet' || id === 'test-type-jee') {
      if (testTypeSelected === 'NEET') {
        { this.clearState(); }
        this.setState({ testTypeSelected: testTypes[0] });
      } else if (testTypeSelected === 'JEE') {
        { this.clearState(); }
        this.setState({ testTypeSelected: testTypes[1] });
      }
    }
  }

  validateZoneCutoffs() {
    const { testTypeSelected } = this.state
    const safeZoneCutOff = this.state.safeZoneCutOff !=="" ? parseInt(this.state.safeZoneCutOff) : TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE; 
    if (safeZoneCutOff < TEST_TYPE_SCORES[testTypeSelected].MAX_SCORE && safeZoneCutOff > 0) {
      { this.goFront(); }
    } 
  }

  clickDone() {
    { this.submit(); }
  }
  
  clearState() {
    this.setState({
      currentPage: 0,
      safeZoneCutOff: '',
      upgradeZoneCutoff: '',
      isLoading: true,
      filters: {},
      testTypeSelected: 'NEET',
      studentStrength: '',
      studentsInSafeZone: '',
      inputTagString: '',
      warningSafe: false,
      warningBorder: false,
    });
  }

  submit = async() => {
    const { testTypeSelected, safeZoneCutOff, upgradeZoneCutoff, studentStrength, studentsInSafeZone } = this.state
    const safeZoneCutOffValue = safeZoneCutOff === '' ? TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE : safeZoneCutOff;
    let upgradeZoneCutoffValue = upgradeZoneCutoff !=='' ? upgradeZoneCutoff : (safeZoneCutOff === '' ? TEST_TYPE_SCORES[testTypeSelected].DEFAULT_UPGRADE : parseInt(safeZoneCutOffValue/2))

    const studentStrengthValue = studentStrength === '' ? STUDENT_STRENGTH : studentStrength;
    let studentsInSafeZoneValue = studentsInSafeZone !== '' ? studentsInSafeZone : STUDENTS_IN_SAFE_ZONE 
    if (studentsInSafeZoneValue > studentStrengthValue) {
      studentsInSafeZoneValue = parseInt(studentStrengthValue/2)
    }
    const payload = {
      testType: testTypeSelected,
      safeZoneCutoff: safeZoneCutOffValue,
      upgradeZoneCutoff: upgradeZoneCutoffValue,
      studentStrength: studentStrengthValue,
      studentsInSafeZone: studentsInSafeZoneValue,
    };
    this.props.dispatch(updateInstituteGoals(payload)).then((response) => {
      if (response.success) {
        this.props.dispatch(showToast(MESSAGE_AFTER_UPDATE.SUCCESS,TOAST_MESSAGE_TYPES.SUCCESS))
      } else {
        this.props.dispatch(showToast(MESSAGE_AFTER_UPDATE.ERROR,TOAST_MESSAGE_TYPES.ERROR))
      }
    });
    { this.clearState(); }
  }

  renderInputTag(zoneName) {
    const { testTypeSelected } = this.state;
    let id; 
    let placeholder; 
    let value;
    if (zoneName === 'Safe') {
      id = 'safe-cutoff';
      placeholder =  `${TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE}`;
      value = this.state.safeZoneCutOff;
    } else {
      id = 'upgrade-cutoff';
      placeholder = `${TEST_TYPE_SCORES[testTypeSelected].DEFAULT_UPGRADE}`;
      value = this.state.upgradeZoneCutoff;
    }
    return (
      <input
        onChange={(event) => this.inputFieldChange(id, event.target.value)}
        type="text"
        name="cutoff"
        maxLength="3"
        placeholder={placeholder}
        onKeyPress={(event) => event.key === 'Enter' && this.validateZoneCutoffs()}
        value={value}
        style={{
          width: '100%', border: 'none', marginBottom: 0, height: 35,
        }}
        autoFocus
        style={{ width: 80, marginTop: 30, marginBottom: 30 }}
      />
    );
  }

  goBack() {
    this.setState({ currentPage: this.state.currentPage - 1 });
  }

  goFront() {
    if (this.state.currentPage === 4) {
      { this.submit(); }
    }
    this.setState({ currentPage: this.state.currentPage + 1 });
  }

  renderArrows() {
    const { currentPage } = this.state;
    return (
      <>
        <div style={{ position: 'absolute', bottom: IS_MOBILE ? 10 : 25, right: IS_MOBILE ? 75 : 140 }}>
          <div
            className="square"
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', opacity: currentPage === 0 && 0.4,
            }}
            onClick={() => (currentPage != 0) && this.goBack()}
          >
            <img style={{ padding: 5 }} src={BackArrowBlue} alt="BackArrowBlue" />
          </div>
        </div>
        <div style={{ position: 'absolute', bottom: IS_MOBILE ? 10 : 25, right: IS_MOBILE ? 25 : 90 }}>
          <div
            className="square"
            onClick={() => this.goFront()}
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
            }}
          >
            <img style={{ padding: 5 }} src={FrontArrowBlue} alt="FrontArrowBlue" />
          </div>
        </div>
      </>
    );
  }

  renderStudentStrength() {
    const { testTypeSelected, currentPage } = this.state;
    return (
      <div style={{ backgroundColor: '#F5F8FA', borderRadius: '4px', boxShadow: '1px 2px 6px #8B9DAF26;' }}>
        <div className="col-md-9" style={{ marginLeft: IS_MOBILE ? '' : '24%', fontFamily: 'Nunito', paddingTop: 1 }}>
          <div style={{
            fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, marginTop: '4%', color: '#11426C',
          }}
          >
            Define Your Institute's Goals
          </div>
          <div style={{
            backgroundColor: '#FFFFFF', height: IS_MOBILE ? 400 : 575, fontSize: IS_MOBILE ? 12 : 20, width: IS_MOBILE ? '100%' : '95%', postition: 'relative'
          }}
          >
            {this.renderArrows()}
            <div style={{ marginLeft: '5%' }}>
              <div style={{ color: '#11426C', fontFamily: 'Nunito Sans', fontWeight: 600 }}>
                <br />
                {'Define Your '}
                {testTypeSelected}
                {' '}
                students' strength
              </div>
              <input
                className="input-field"
                id="strength"
                onChange={(event) => this.setState({ studentStrength: event.target.value })}
                type="text"
                name="quantity"
                maxLength="4"
                placeholder={`${STUDENT_STRENGTH}`}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.goFront();
                  }
                }}
                value={this.state.studentStrength}
                style={{
                  width: '100%', border: 'none', marginBottom: 0, height: 35,
                }}
                autoFocus
                style={{ width: 100, marginTop: 30, marginBottom: 30 }}
              />
              <div>
                <div style={{ marginBottom: '1%', marginTop: '1%', fontSize: IS_MOBILE ? 16 : 22 }}>
                  Press
                  {' '}
                  <button style={{ borderColor: '#40A7FF', color: '#40A7FF' }} onClick={() => this.goFront()} variant="primary">Enter</button>
                  {' '}
                  to continue
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderZone(zoneName, zoneText) {
    const {
      testTypeSelected, safeZoneCutOff, upgradeZoneCutoff, warningSafe, warningBorder, currentPage,
    } = this.state;
    let maxScore = TEST_TYPE_SCORES[testTypeSelected].MAX_SCORE; 
    let safeScoreDefault = TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE; 
    let safePercentWidth = safeZoneCutOff != '' ? ((maxScore - safeZoneCutOff) * 100) / maxScore : testTypeSelected === 'NEET' ? 37.5 : 50;
    let upgradePercentWidth = upgradeZoneCutoff != '' ? (safeZoneCutOff != '' ? ((safeZoneCutOff - upgradeZoneCutoff) * 100) / maxScore : ((safeScoreDefault - upgradeZoneCutoff) * 100 / maxScore)) : safeZoneCutOff !== '' ? ((100 - safePercentWidth)/2) : (testTypeSelected === 'NEET' ? 21 : 25);
    const remedyPercentWidth = `${100 - safePercentWidth - upgradePercentWidth}%`;
    safePercentWidth += '%';
    upgradePercentWidth += '%';
    return (
      <div style={{
        backgroundColor: '#F5F8FA', borderRadius: '4px', height: 500, boxShadow: '1px 2px 6px #8B9DAF26;',
      }}
      >
        <div className="col-md-9" style={{ marginLeft: IS_MOBILE ? '2%' : '24%', fontFamily: 'Nunito', paddingTop: 1 }}>
          <div style={{
            fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, marginTop: '4%', color: '#11426C',
          }}
          >
            Define Your Institute's Goals
          </div>
          <div style={{
            backgroundColor: '#FFFFFF', height: IS_MOBILE ? 400 : 575, fontSize: IS_MOBILE ? 12 : 20, width: IS_MOBILE ? '100%' : '95%',
          }}
          >
            <div style={{ marginLeft: '5%' }}>
              <br />
              {
                        currentPage === 1
                        && (
                        <div style={{
                          fontSize: 12, marginBottom: '2%', width: 300, height: 40,
                        }}
                        >
                          <div style={{ display: 'flex', height: 20, width: '100%' }}>
                            <div style={{
                              height: 20, width: safePercentWidth, display: 'flex', justifyContent: 'space-between',
                            }}
                            >
                              <div>
                                {TEST_TYPE_SCORES[testTypeSelected].MAX_SCORE }
                              </div>
                              <div>
                                {(safeZoneCutOff || TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE)}
                              </div>
                            </div>
                            <div style={{ height: 20, width: upgradePercentWidth }}>
                            </div>
                            <div style={{
                              height: 20, width: remedyPercentWidth, display: 'flex', justifyContent: 'space-between',
                            }}
                            >
                              <div>
                                { (upgradeZoneCutoff === '' ? (safeZoneCutOff === '' ? (TEST_TYPE_SCORES[testTypeSelected].DEFAULT_UPGRADE) :  Math.round(safeZoneCutOff/2)) : (upgradeZoneCutoff || TEST_TYPE_SCORES[testTypeSelected].DEFAULT_UPGRADE)) }
                              </div>
                              <div>
                                min
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', height: 10, width: '100%' }}>
                            <div style={{
                              height: 10, width: safePercentWidth, backgroundColor: '#69ED51', borderColor: '1px solid #20D000',
                            }}
                            />
                            <div style={{ border: '1px solid #E4E7EE', width: upgradePercentWidth }} />
                            <div style={{
                              border: '1px solid #E4E7EE', width: remedyPercentWidth,
                            }}
                            />
                          </div>
                        </div>
                        )
                        }
              {
                        currentPage === 2
                        && (
                        <div style={{
                          fontSize: 12, marginBottom: '2%', width: 300, height: 40,
                        }}
                        >
                          <div style={{ display: 'flex', height: 20, width: '100%' }}>
                            <div style={{
                              height: 20, width: safePercentWidth, display: 'flex', justifyContent: 'space-between',
                            }}
                            >
                              <div>
                                {TEST_TYPE_SCORES[testTypeSelected].MAX_SCORE}
                              </div>
                              <div>
                                {safeZoneCutOff || TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE}
                              </div>
                            </div>
                            <div style={{ height: 20, width: upgradePercentWidth }}>
                            </div>
                            <div style={{
                              height: 20, width: remedyPercentWidth, display: 'flex', justifyContent: 'space-between',
                            }}
                            >
                              <div>
                              { (upgradeZoneCutoff === '' ? (safeZoneCutOff === '' ? (TEST_TYPE_SCORES[testTypeSelected].DEFAULT_UPGRADE) :  Math.round(safeZoneCutOff/2)) : (upgradeZoneCutoff || TEST_TYPE_SCORES[testTypeSelected].DEFAULT_UPGRADE)) }
                              </div>
                              <div>
                                min
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', height: 10, width: '100%' }}>
                            <div style={{
                              height: 10, width: safePercentWidth, backgroundColor: '#69ED51', borderColor: '1px solid #20D000',
                            }}
                            />
                            <div style={{ width: upgradePercentWidth, background: '#FFC888 0% 0% no-repeat padding-box', border: '1px solid #FFA740' }} />
                            <div style={{
                              border: '1px solid #E4E7EE', width: remedyPercentWidth,
                            }}
                            />
                          </div>
                        </div>
                        )
                        }


              <div style={{
                fontWeight: 600, color: ZONE_GOAL_COLORS[zoneName.toUpperCase()], fontFamily: 'Nunito Sans', marginBottom: '1%',
              }}
              >
                {zoneName}
                {' '}
                Zone
              </div>
              <div style={{ fontFamily: 'Nunito' }}>
                {zoneText}
              </div>
              <div style={{
                color: '#11426C', fontFamily: 'Nunito Sans', fontWeight: 600, marginTop: '7%',
              }}
              >
                Set Cut-Off for
                {' '}
                {zoneName}
                {' '}
                Zone.
              </div>
              <div>
                (You are inputting a
                {' '}
                {testTypeSelected}
                {' '}
                score)
              </div>
              {this.renderInputTag(zoneName)}
              {warningSafe
              && (
              <div>
                <span style={{ fontSize: 14, color: '#FF3E3E' }}>
                  {' '}
                  Please input a number between 0 and&nbsp;
                  {TEST_TYPE_SCORES[testTypeSelected].MAX_SCORE}
                </span>
              </div>
              )}
              {warningBorder && !(warningSafe)
                && (
                <div>
                  <span style={{ fontSize: 14, color: '#FF3E3E' }}>
                    {' '}
                    Please input a number between 0 and&nbsp;
                    { safeZoneCutOff || TEST_TYPE_SCORES[testTypeSelected].DEFAULT_SAFE}
                  </span>
                </div>
                )}
              <div style={{ marginBottom: '1%', marginTop: '1%', fontSize: IS_MOBILE ? 16 : 22 }}>
                Press
                {' '}
                <button style={{ borderColor: '#40A7FF', color: '#40A7FF' }} onClick={() => this.validateZoneCutoffs()} variant="primary">Enter</button>
                {' '}
                to continue
              </div>

              <div style={{ fontSize: IS_MOBILE ? 12 : 18, color: '#52606C' }}>
                <div style={{ fontWeight: 600, fontFamily: 'Nunito Sans' }}>NOTE:</div>
                <div style={{ fontFamily: 'Nunito' }}>By clicking ‘Enter’ without input, you are conforming</div>
                {' '}
                <div style={{ fontFamily: 'Nunito' }}>to the default number suggested by the system.</div>
              </div>
              <div>
                {this.renderArrows()}
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  renderSafeStudents() {
    const id = 'safe-students';
    const { testTypeSelected, warningSafe, currentPage } = this.state;
    return (
      <>
        <div style={{ backgroundColor: '#F5F8FA', borderRadius: '4px', boxShadow: '1px 2px 6px #8B9DAF26' }}>
          <div className="col-md-9" style={{ marginLeft: IS_MOBILE ? '2%' : '24%', fontFamily: 'Nunito', paddingTop: 1 }}>
            <div style={{
              fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, marginTop: '4%', color: '#11426C',
            }}
            >
              Define Your Institute's Goals
            </div>
            <div style={{
              backgroundColor: '#FFFFFF', height: IS_MOBILE ? 400 : 575, fontSize: IS_MOBILE ? 12 : 20, width: IS_MOBILE ? '100%' : '95%', position: 'relative'
            }}
            >
              <div style={{ marginLeft: '5%' }}>
                <br />
                <div style={{
                  fontWeight: 600, color: ZONE_GOAL_COLORS['Safe'.toUpperCase()], fontFamily: 'Nunito Sans', marginBottom: '1%',
                }}
                >
                  Students in Safe Zone
                </div>
                <div style={{ fontFamily: 'Nunito' }} />
                <div style={{
                  color: '#11426C', fontFamily: 'Nunito Sans', fontWeight: 600, marginTop: '7%',
                }}
                >
                  Set Number of Students you want in the Safe Zone
                </div>
                <div>
                  Your Insitute's Strength:
                  {' '}
                  {this.state.studentStrength || STUDENT_STRENGTH }
                </div>
                <div />
                <input
                  onChange={(event) => this.inputFieldChange(id, event.target.value)}
                  type="text"
                  name="cutoff"
                  maxLength="4"
                  placeholder={`${STUDENTS_IN_SAFE_ZONE}`}
                  onKeyPress={(event) => event.key === 'Enter' && this.validateZoneCutoffs()}
                  value={this.state.studentsInSafeZone}
                  style={{
                    width: '100%', border: 'none', marginBottom: 0, height: 35,
                  }}
                  autoFocus
                  style={{ width: 80, marginTop: 30, marginBottom: 30 }}
                />
                <div style={{ marginBottom: '1%', marginTop: '1%' }}>
                  Press
                  {' '}
                  <button style={{ borderColor: '#40A7FF', color: '#40A7FF' }} onClick={() => this.submit()} variant="primary">Enter</button>
                  {' '}
                  to Submit
                </div>
                <div style={{ fontSize: IS_MOBILE ? 12 : 18, color: '#52606C' }}>
                  <div style={{ fontWeight: 600, fontFamily: 'Nunito Sans' }}>NOTE:</div>
                  <div style={{ fontFamily: 'Nunito' }}>By clicking ‘Enter’ without input, you are conforming</div>
                  {' '}
                  <div style={{ fontFamily: 'Nunito' }}>to the default number suggested by the system.</div>
                </div>
                <div>
                  <div style={{ position: 'absolute', bottom: IS_MOBILE ? 10 : 25, right: 115 }}>
                    <div
                      className="square"
                      style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: currentPage === 1 && 0.4, cursor: 'pointer',
                      }}
                      onClick={() => (currentPage != 1) && this.goBack()}
                    >
                      <img style={{ padding: 5 }} src={BackArrowBlue} alt="BackArrowBlue" />
                    </div>
                  </div>
                  <div style={{ position: 'absolute', bottom: IS_MOBILE ? 10 : 25, right: 25 }}>
                    <div
                      style={{
                        height: 30, width: 70, backgroundColor: '#40A7FF', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer',
                      }}
                      onClick={() => this.clickDone()}
                    >
                      <span style={{ color: '#FFFFFF', fontFamily: 'Nunito', fontSize: 14 }}>Done</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }

  renderTestTypeSelection() {
    const neetID = 'test-type-neet';
    const jeeID = 'test-type-jee';
    return (
      <div style={{ backgroundColor: '#F5F8FA', borderRadius: '4px', boxShadow: '1px 2px 6px #8B9DAF26;' }}>
        <div className="col-md-9" style={{ marginLeft: IS_MOBILE ? '2%' : '24%', fontFamily: 'Nunito', paddingTop: 1 }}>
          <div style={{
            fontFamily: 'Nunito Sans', fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, marginTop: '4%', color: '#11426C',
          }}
          >
            Define Your Institute's Goals
          </div>
          <div style={{
            backgroundColor: '#FFFFFF', height: IS_MOBILE ? 400 : 575, fontSize: IS_MOBILE ? 12 : 20, width: IS_MOBILE ? '100%' : '95%',
          }}
          >
            <br />
            <div style={{ marginLeft: '5%' }}>
              <div style={{
                color: '#11426C', fontWeight: 600, fontFamily: 'Nunito Sans',
              }}
              >
                Select the exam for which you are setting the goals
              </div>
              <div>
                <form style={{marginTop: IS_MOBILE ? '4%' : '2%'}}>
                  <label style={{ marginRight: 50 }}>
                    <input
                      onChange={(event) => this.inputFieldChange(neetID, event.target.value)}
                      type="radio"
                      name="neet"
                      checked={this.state.testTypeSelected === 'NEET'}
                      onKeyPress={(event) => event.key === 'Enter' && this.goFront()}
                      style={{ marginRight: 10 }}
                    />
                    NEET
                  </label>
                  <label>
                    <input
                      onChange={(event) => this.inputFieldChange(jeeID, event.target.value)}
                      type="radio"
                      name="jee"
                      checked={this.state.testTypeSelected === 'JEE'}
                      onKeyPress={(event) => event.key === 'Enter' && this.goFront()}
                      style={{ marginRight: 10 }}
                    />
                    JEE
                  </label>
                </form>
              </div>
              {this.renderArrows()}
              <div style={{
                marginBottom: '3%', marginTop: '3%', fontSize: IS_MOBILE ? 16 : 22
              }}
              >
                Press&nbsp;
                {' '}
                <button
                  style={{
                    borderColor: '#40A7FF', color: '#40A7FF',
                  }}
                  onClick={() => this.goFront()}
                  variant="primary"
                >
                  Enter
                </button>
                {' '}
              &nbsp;to continue
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let zoneName; 
    let zoneText;
    let availableScreenHeight = 0
    if (typeof window !== 'undefined') {
      availableScreenHeight = window.innerHeight;
    }
    const { testTypeSelected } = this.state;
    const { currentPage } = this.state;
    if (currentPage === 1 || currentPage === 2) {
      if (currentPage === 1) {
        zoneName = 'Safe';
        zoneText = `Students in this zone clear ${testTypeSelected} cutoff`;
      } else {
        zoneName = 'Upgrade';
        zoneText = `Students in this zone are Borderline to clear ${testTypeSelected} cutoff`;
      }
    }
    return (
      <div style={{ height: availableScreenHeight, backgroundColor: '#F5F8FA' }}>
        <Header
          menuData={INSTITUTE_GOALS_MENU[1]}
          backgroundColor="#F5F8FA"
        />
        <SideBar menuList={INSTITUTE_GOALS_MENU} selectedOption={1} />
        <div style={{ marginTop: 60 }}>
          {currentPage == 0 && this.renderTestTypeSelection()}
          {(currentPage == 1 || currentPage == 2) && this.renderZone(zoneName, zoneText)}
          {currentPage == 3 && this.renderStudentStrength()}
          {currentPage == 4 && this.renderSafeStudents()}
        </div>
      </div>
    );
  }
}
export default connect()(InstituteGoalSettings);
