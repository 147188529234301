import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateFilterModel, updateFilterData } from '../../actions';
import {
  CAMPUS_SELECTED, TEST_LIST, CLASSROOM_SELECTED, COMPARE_WITH,
  FILTER, CAMPUS, CLASSROOM, TEST_TYPE, TEST_TYPE_SELECTED,
  TEST_SELECTED, PAYLOAD,
} from '../../shared/constants/textConstants';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import Loader from './loader';
import ChevronRight from '../../shared/images/chevron-right.svg';
import Close from '../../shared/images/x-black.svg';

const TestDivision = ['week', 'month', 'others'];
const styles = {
  select: {
    display: 'flex', justifyContent: 'space-between',
  },
  margin:
  {
    margin: '60px 20px 40px 20px',
  },
  selectedItems:
  {
    width: 'fit-content', placeItems: 'center', background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '2px 4px', marginTop: 7,
  },
};

class Filter extends Component {
  updateTestType(testId) {
    const { updateFilters } = this.props;
    updateFilters(testId, TEST_SELECTED);
    updateFilterModel(false, FILTER);
  }

  updateFilters(value, type) {
    const {
      testId, campusSelected, classroomSelected, testSelected, 
      testTypeSelected, subjectSelected, updateResults, updateFilterModel, updateFilterData
    } = this.props;
    if (type === CAMPUS_SELECTED) {
      updateFilterData(CLASSROOM_SELECTED, '');
    }
    updateFilterData(type, value);
    const payload = {
      id: testSelected.value ? testSelected.value : testId,
      campus_id: campusSelected || '',
      classroom_id: type === CAMPUS_SELECTED ? '' : (classroomSelected || ''),
      subject_id: subjectSelected,
      test_type_id: testTypeSelected || '',
    };
    payload[PAYLOAD[type]] = value;
    updateResults(payload);
    updateFilterModel(true, FILTER);
  }

  renderTests(testList) {
    const { filterType, menuData } = this.props;
    return (
      <div>
        {
          testList.map((test) => {
            return (
              <div
                className="Card-View"
                role="presentation"
                style={{ margin: '2px 0px', padding: 15, textAlignLast: 'left' }}
                onClick={filterType === COMPARE_WITH ? () => { this.updateTestType(test.id); } : () => { window.location.href = `/${menuData.key}/${test.id}`; }}
              >
                <div style={{ fontSize: StyleConstants.textSize.subHeader, fontWeight: 'bold' }}>{test.name}</div>
                <div style={{ fontSize: StyleConstants.textSize.subHeader, fontWeight: 'regular' }}>{test.date}</div>
              </div>
            );
          })
        }
      </div>
    );
  }

  renderSelect(id, name, selected, field) {
    return (
      <div
        style={{
          display: 'flex', alignItems: 'center', paddingTop: 15, justifyContent: 'space-between',
        }}
        role="presentation"
        onClick={() => ((selected) ? '' : this.updateFilters(selected ? '' : id, field))}
      >
        <div>{name}</div>
        <div style={{
          textAlign: 'center', width: '6px', height: '13px', border: '2px solid', borderColor: selected ? 'transparent #00A800 #00A800 transparent' : 'transparent', transform: 'rotate(45deg)',
        }}
        />
      </div>
    );
  }

  render() {
    const {
      filterType, testList, previousTests, campusSelected,
      campuses, classrooms, classroomSelected, testType,
      testTypeSelected, updateFilterModel, showLoader,
    } = this.props;
    if (showLoader && filterType === TEST_LIST) {
      return (
        <Loader />
      );
    }
    if (filterType === TEST_LIST || filterType === COMPARE_WITH) {
      const list = filterType === TEST_LIST ? testList : previousTests;
      return (
        <div style={{ ...styles.margin, padding: '25px 0' }}>
          {
            TestDivision.map((division) => list && list[division].length > 0
              && (
                <div>
                  <div style={{ fontSize: StyleConstants.textSize.subHeader, fontWeight: 'bolder' }}>{division === 'others' ? 'Others' : `This ${division}`}</div>
                  {
                    this.renderTests(list[division])
                  }
                </div>
              ))
          }
        </div>
      );
    }

    if (filterType === FILTER) {
      return (
        <div style={{ ...styles.margin }}>
          <div
            style={{
              ...styles.select, border: '1px solid #6C5CE7', borderRadius: 7, padding: 7, marginTop: 75, color: StyleConstants.color.primary,
            }}
            role="presentation"
            onClick={() => updateFilterModel(true, CAMPUS)}
          >
            <div>{CAMPUS}</div>
            <img src={ChevronRight} alt="ChevronRight" height={18} width={18} />
          </div>
          {
      testType && testTypeSelected
        && (
        <div style={{ ...styles.select, ...styles.selectedItems }}>
          <div style={{ paddingRight: 10, fontSize: 12 }}>{classrooms[classroomSelected].name}</div>
          <img
            src={Close}
            role="presentation"
            alt="Close"
            height={8}
            width={8}
            onClick={() => this.updateFilters('', TEST_TYPE_SELECTED)}
          />
        </div>
        )
      }
          {
      campuses && campusSelected
        && (
        <div style={{ ...styles.select, ...styles.selectedItems }}>
          <div style={{ paddingRight: 10, fontSize: 12 }}>{campuses[campusSelected].name}</div>
          <img
            src={Close}
            role="presentation"
            alt="Close"
            height={8}
            width={8}
            onClick={() => this.updateFilters('', CAMPUS_SELECTED)}
          />
        </div>
        )
      }
          {
        campusSelected && (
        <div
          style={{
            ...styles.select, border: '1px solid #6C5CE7', borderRadius: 7, padding: 7, marginTop: 15, color: StyleConstants.color.primary,
          }}
          role="presentation"
          onClick={() => updateFilterModel(true, 'Classrooms')}
        >
          <div style={{ paddingRight: 10 }}>Classroom</div>
          <img src={ChevronRight} alt="ChevronRight" height={18} width={18} />
        </div>
        )
      }
          {
        classroomSelected && (
        <div style={{ ...styles.select, ...styles.selectedItems }}>
          <div style={{ paddingRight: 10, fontSize: 12 }}>{classrooms[classroomSelected].name}</div>
          <img
            src={Close}
            role="presentation"
            alt="Close"
            height={8}
            width={8}
            onClick={() => this.updateFilters('', CLASSROOM_SELECTED)}
          />
        </div>
        )
      }
        </div>
      );
    }
    if (filterType === TEST_TYPE) {
      return (
        <div style={{ ...styles.margin }}>
          {Object.keys(testType).length > 0 ? Object.keys(testType).map((type) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center', paddingTop: 15 }}>
                <div style={{
                  marginRight: '3%',
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: testTypeSelected === testType[type].id
                    ? StyleConstants.color.primary : StyleConstants.color.white,
                  height: '19px',
                  width: '19px',
                  borderRadius: '4px',
                  border: classroomSelected === testType[type].id ? '1px solid #6C5CE7' : '1px solid #CBCBCB',
                }}
                >
                  <input
                    type="checkbox"
                    style={{
                      position: 'absolute', height: '20px', width: '20px', opacity: '0', zIndex: '1',
                    }}
                    onClick={() => this.updateFilters(testType[type].id === testTypeSelected ? '' : testType[type].id, TEST_TYPE_SELECTED)}
                  />
                  <p style={{
                    textAlign: 'center', width: '6px', height: '13px', border: '2px solid', borderColor: 'transparent #FFFFFF #FFFFFF transparent', transform: 'rotate(45deg)',
                  }}
                  />
                </div>
                <div>{testType[testType].name}</div>
              </div>
            );
          })
            : <div style={{ textAlign: 'center', fontSize: 16, paddingTop: '40%' }}>No Campuses </div>}
        </div>

      );
    }
    if (filterType === CAMPUS) {
      return (
        <div style={{ ...styles.margin }}>
          {this.renderSelect('', 'All Campuses',
            !campusSelected, CAMPUS_SELECTED)}
          {Object.keys(campuses).length > 0 ? Object.keys(campuses).map((campus) => (
            this.renderSelect(campuses[campus].id, campuses[campus].name,
              campuses[campus].id === campusSelected, CAMPUS_SELECTED)
          ))
            : <div style={{ textAlign: 'center', fontSize: 16, paddingTop: '40%' }}>No Campuses </div>}
        </div>

      );
    }
    if (filterType === CLASSROOM) {
      return (
        <div style={{ ...styles.margin }}>
          {this.renderSelect('', 'All Classrooms',
            !classroomSelected, CLASSROOM_SELECTED)}
          {Object.keys(classrooms).length > 0 ? Object.keys(classrooms).map((classroom) => (
            this.renderSelect(classrooms[classroom].id, classrooms[classroom].name,
              classrooms[classroom].id === classroomSelected, CLASSROOM_SELECTED)
          ))
            : <div style={{ textAlign: 'center', fontSize: 16, paddingTop: '40%' }}>No Classrooms </div>}
        </div>
      );
    }
    return (
      <div style={{ marginTop: 66 }} />
    );
  }
}

Filter.propTypes = {
  name: PropTypes.string,
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  campusSelected: PropTypes.string,
  testSelected: PropTypes.string,
  classroomSelected: PropTypes.string,
  filterType: PropTypes.string,
  testList: PropTypes.array,
  previousTests: PropTypes.array,
  testType: PropTypes.object,
  testTypeSelected: PropTypes.string,
  subjectSelected: PropTypes.string,
  menuData: PropTypes.object,
  testId: PropTypes.string,
  updateFilters: PropTypes.func.isRequired,
  updateFilterModel: PropTypes.func.isRequired,
  updateFilterData: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
};

Filter.defaultProps = {
  name: '',
  campuses: {},
  classrooms: {},
  campusSelected: '',
  testSelected: '',
  classroomSelected: '',
  filterType: FILTER,
  testList: [],
  previousTests: [],
  testType: {},
  testTypeSelected: '',
  menuData: {},
  testId: 0,
  subjectSelected: '',
  showLoader: false,
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  campusSelected: insight.campusSelected,
  testSelected: insight.testSelected,
  classroomSelected: insight.classroomSelected,
  filterType: insight.filterType,
  testList: insight.testList,
  previousTests: insight.previousTests,
  testType: insight.testType,
  subjectSelected: insight.subjectSelected,
  showLoader: insight.showLoader,
});


export default connect(mapStateToProps, { updateFilterModel, updateFilterData })(Filter);
