import Api from '../helpers/Api';
import { UPDATE_STATUS, SMS_PERMISSION } from '../shared/constants/textConstants';
import { showToast } from './global';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';

export const TEST_OVERVIEW = 'Dashboard/TEST_OVERVIEW';
export const UPDATE_QUESTION_LIST = 'Dashboard/UPDATE_QUESTION_LIST';
export const RESET_FILTERS = 'Dashboard/RESET_FILTERS';
export const UPDATE_DATA_MANAGEMENT = 'Dashboard/UPDATE_DATA_MANAGEMENT';
export const UPDATE_DATA_MANAGEMENT_MODAL = 'Dashboard/UPDATE_DATA_MANAGEMENT_MODAL';
export const STUDENT_RESULT_UPLOAD_STATUS_DELETE = 'Dashboard/STUDENT_RESULT_UPLOAD_STATUS_DELETE';
export const UPDATE_TEST_DETAIL = 'Dashboard/UPDATE_TEST_DETAIL';
export const UPDATE_MODAL = 'Dashboard/UPDATE_MODAL';
export const UPDATE_TEST_SUMMARY = 'Dashboard/UPDATE_TEST_SUMMARY';
export const STUDENT_RESULT_UPLOAD_STATUS = 'Dashboard/STUDENT_RESULT_UPLOAD_STATUS';
export const GET_ASSIGNMENT_LIST = 'Dashboard/GET_ASSIGNMENT_LIST';
export const GET_SMS_STATUS = 'Dashboard/GET_SMS_STATUS';
export const SMS_DETAILS = 'Dashboard/SMS_DETAILS';

export function getAssignmentList() {
  return (dispatch) => Api({
    method: 'get',
    url: `/membership_tests/get_user_assignment_list?is_test=${true}`,
  }).then((response) => {
    if (response.data.success) {
      const payload = {
        testsList: response.test_list,
        testTaken: response.test_taken,
        liveTestIds: response.live_test_ids,
        showSentSms: response.show_send_sms,
        smsBalance: response.sms_balance || 0,
      };
      dispatch({
        type: GET_ASSIGNMENT_LIST,
        payload,
      });
    }
  });
}

export function getLiveStudentCount(testIds) {
  return (dispatch) => Api({
    golangEndpoint: true,
    method: 'get',
    url: `/active_test_student_count?test_id=${testIds}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        liveStudentCount: response.active_test_student_count,
      };
      dispatch({
        type: GET_ASSIGNMENT_LIST,
        payload,
      });
    }
    return response;
  });
}

export function sendSms(id) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/send_sms_to_parents',
    data: { id },
  })
    .then((response) => {
      if (response.success) {
        const payload = {
          testId: id,
          smsStatus: response.sms_status,
          failureReason: response.failure_mode,
          smsBalance: response.sms_balance,
        };
        dispatch({
          type: GET_SMS_STATUS,
          payload,
        });
        dispatch(showToast('Sms Sent Successfully'));
      } else {
        dispatch(showToast(`Institute has only ${response.sms_balance || 0} sms left. But this test has ${response.students_count} students. Please Contact us.`));
      }
    });
}

export function uploadStudentResults(file, testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/upload_student_result_file',
    attachment: file,
    type: 'spreadsheet',
    data: { test_id: testId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_TEST_SUMMARY,
        id: testId,
        status: UPDATE_STATUS.PROCESS,
      });
      dispatch({
        type: UPDATE_MODAL,
        payload: false,
      });
    }
  });
}

export function updateAssignmentModal(bool) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_MODAL, payload: bool });
    resolve();
  });
}

export function deleteStudentResults(testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/delete_offline_test_data',
    data: { id: testId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: STUDENT_RESULT_UPLOAD_STATUS,
        status: response.status,
      });
    }
  });
}

export function updateTestAttribute(id, status) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_TEST_SUMMARY, id, status });
    resolve();
  });
}
