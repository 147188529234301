import Constants from '../shared/constants/environmentConfig';

const constants = Constants();

export const isMobileDevice = () => {
  return (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1);
};

export const getParentPortalDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.parentPortalDomain;
};

export const getqbankDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.qbank;
};
export const getAccountsDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.accountsDomain;
};

export const getHomeDomain = () => {
  const protocolScheme = constants.cookie.secure ? 'https://' : 'http://';
  return protocolScheme + constants.cookie.homeDomain;
};

export const styleRules = (...rules) => {
  return rules.filter(Boolean).reduce((result, rule) => {
    return { ...result, ...rule };
  }, {});
};

export const toTitleCase = (string) => {
  return string[0].toUpperCase() + string.slice(1, string.length);
};

export const UUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r&0x3|0x8); // eslint-disable-line
    return v.toString(16);
  });
};

export const jsonToQueryString = (json) => {
  return `?${
    Object.keys(json).map((key) => {
      return `${encodeURIComponent(key)}=${
        encodeURIComponent(json[key])}`;
    }).join('&')}`;
};

export const secondsToHm = (seconds) => {
  const day = Math.floor(seconds / 86400);
  const hour = Math.floor((seconds % 86400) / 3600);
  const minute = Math.floor(((seconds % 86400) % 3600) / 60);
  const second = ((seconds % 86400) % 3600) % 60;
  const dayDisplay = day > 0 ? day + (day === 1 ? 'day' : 'days') : '';
  const secondDisplay = second > 0 ? second + (second === 1 ? 's' : 's') : '';
  const hourDisplay = hour > 0 ? hour + (hour === 1 ? ' h' : ' h') : '';
  const minuteDisplay = minute > 0 ? minute + (minute === 1 ? ' m' : ' m') : '';
  const isDataAfterDay = dayDisplay && (secondDisplay || minuteDisplay || hourDisplay) ? ', ' : '';
  const isDataAfterHour = hourDisplay && (secondDisplay || minuteDisplay) ? ', ' : '';
  const isDataAfterMinute = (minuteDisplay && secondDisplay) ? ', ' : '';
  return `${dayDisplay}${isDataAfterDay}${hourDisplay}${isDataAfterHour}${minuteDisplay}${isDataAfterMinute}${secondDisplay}`;
};

export const sortByKey = (array, key, type = 'asc') => {
  return array.sort((a, b) => {
    const x = a[key]; const y = b[key];
    if (type === 'asc') {
      const data = (x > y) ? 1 : 0;
      return ((x < y) ? -1 : (data));
    }
    const data = (x < y) ? 1 : 0;
    return ((x > y) ? -1 : (data));
  });
};

export const textTruncate = (text, textBreak) => {
  const { length } = text;
  let slicedWord = '';
  if (length > textBreak * 2) {
    slicedWord = `${text.slice(0, textBreak)}\n${text.slice(textBreak, textBreak * 2)}....`;
  } else if (length > textBreak) {
    slicedWord = `${text.slice(0, textBreak)}\n${text.slice(textBreak, textBreak * 2)}`;
  } else {
    slicedWord = text;
  }
  return slicedWord;
};
