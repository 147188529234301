import { RESET_FILTERS } from '../actions';

export const UPDATE_FILTERS = 'InstituteProgress/UPDATE_FILTERS';
export const UPDATE_ZONE_DETAILS = 'InstituteProgress/UPDATE_ZONE_DETAILS';
export const STUDENT_ZONE_DETAILS = 'InstituteProgress/STUDENT_ZONE_DETAILS';
export const UPDATE_STUDENT_BUCKET = 'InstituteProgress/UPDATE_STUDENT_BUCKET';

export const MESSAGE_AFTER_UPDATE = {
  SUCCESS: 'Successfully updated Institute Goals',
  ERROR: "Couldn't update Goal Settings! UNAUTHORIZED",
};

export const ZONES = {
  SAFE: 'safe',
  UPGRADE: 'upgrade',
  REMEDY: 'remedy',
};

export const STUDENTS_IN_SAFE_ZONE = 200;
export const STUDENT_STRENGTH = 500;
export const TEST_TYPE_SCORES = {
  NEET: {
    DEFAULT_SAFE: 450,
    DEFAULT_UPGRADE: 300,
    MAX_SCORE: 720,
  },
  JEE: {
    DEFAULT_SAFE: 150,
    DEFAULT_UPGRADE: 75,
    MAX_SCORE: 300,
  },
};
export const ZONE_GOAL_COLORS = {
  SAFE: '#20D000',
  UPGRADE: '#FFA740',
};
export const ZONE_MARK_COLORS = {
  SAFE: '#52606C',
  UPGRADE: '#FF633E',
  REMEDY: '#FF633E',
};
export const ZONE_COLORS = {
  SAFE: '#189F00',
  UPGRADE: '#FF9F40',
  REMEDY: '#FF6946',
};

export const ZONE_SHADOW = {
  SAFE: '#20D00033',
  UPGRADE: '#FFA74033',
  REMEDY: '#FF3E3E33',
};
const initialState = {
  overall: {},
  trend: {},
  maxStudent: [20],
  yIndex: '',
  studentsData: {},
  isSampleData: false,
  hasAccessToParentPortal: true,
  months: 6,
  subjects: [],
  studentsBucketData: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ZONE_DETAILS: {
      return {
        ...state,
        overall: action.payload.data.overall,
        trend: action.payload.data.trend,
        maxStudent: action.payload.data.max_students,
        yIndex: action.payload.data.y_index,
        thresholdPercentage: action.payload.data.threshold_percentage,
        testType: action.payload.testType,
        testTypeName: action.payload.testTypeName,
        isSampleData: action.payload.isSampleData,
        campuses: action.payload.campuses,
        classrooms: action.payload.classrooms,
        hasAccessToParentPortal: action.payload.hasAccessToParentPortal,
      };
    }
    case STUDENT_ZONE_DETAILS: {
      return {
        ...state,
        studentsData: action.payload.studentsData.students_data,
        subjects: action.payload.subjects,
      };
    }
    case UPDATE_FILTERS: {
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };
    }
    case UPDATE_STUDENT_BUCKET: {
      return {
        ...state,
        studentsBucketData: action.payload.studentsBucketData,
        testTypeName: action.payload.testTypeName,
        subjects: action.payload.subjects,
      };
    }
    case RESET_FILTERS: {
      return {
        ...state,
        campusSelected: '',
        classroomSelected: '',
        testTypeSelected: '',
      };
    }
    default:
      return state;
  }
}