import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles';

class Text extends PureComponent {
  render() {
    const {
      fontSize, color, fontWeight, text, vertical, style,
    } = this.props;
    const styles = Object.keys(style).length > 0 ? style : {
      color,
      fontSize,
      fontWeight,
      padding: 2,
      display: 'inline',
      writingMode: vertical && 'tb-rl',
    };
    return (
      <p style={styles}>
        {text}
      </p>
    );
  }
}

Text.propTypes = {
  vertical: PropTypes.bool,
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  style: PropTypes.object,
};

Text.defaultProps = {
  fontSize: StyleConstants.textSize.text,
  color: StyleConstants.textLineColors.secondary,
  fontWeight: '0',
  style: {},
  vertical: false,
};

export default Text;
