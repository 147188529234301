import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import Select from 'react-select';
import HighchartsReact from 'highcharts-react-official';
import * as AnnotationsModule from 'highcharts/modules/annotations';
import { getDropDownStyle } from '../../shared/constants/textConstants';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { Loader } from '../common';
import { getSubscriptionData } from '../../actions/studentProgress';

AnnotationsModule(Highcharts);


class StudentInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      selectedInstitute: {},
    };
    this.renderDropDown = this.renderDropDown.bind(this);
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { selectedInstitute } = this.state;
    this.props.getSubscriptionData(selectedInstitute.value).then(() => {
      const { defaultSelectedInstitute } = this.props;
      this.setState({ selectedInstitute: defaultSelectedInstitute }, () => {
        this.setState({ showLoader: false });
      });
    });
  }

  setOptions(graphData, chartType) {
    return ({
      title: { text: '' },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'white',
        style: {
          width: 'inherit',
          height: 450,
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
      tooltip: {
        formatter() {
          let date = this.x;
          Object.keys(graphData).map((data) => {
            if (data.startsWith(this.x)) {
              date = data;
            }
          });
          return `<div><b>${this.y} ${this.y === 1 ? 'Student' : 'Students'}</b><br/><b>${date}</b></div>`;
        },
      },
      xAxis: {
        title: { text: 'Time Period' },
        categories: Object.keys(graphData).map((data) => {
          return data.split(',')[0];
        }),
      },
      yAxis: {
        title: { text: 'Students Count' },
        labels: {
          formatter() {
            return this.value;
          },
        },
      },
      type: chartType,
      series: [{
        type: chartType,
        color: '#FFAF5F',
        data: Object.values(graphData),
      }],
    });
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, isClearable = false) {
    const name = selectedItem.name ? selectedItem.name : item;
    const length = (name.length * 10);
    const selectBoxStyle = getDropDownStyle(length);
    const boxWidth = { width: 'fit-content', marginLeft: 30 };

    return (
      <div style={boxWidth}>
        <Select
          value={selectedItem.value && selectedItem}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          styles={selectBoxStyle}
          isClearable={isClearable}
          isSearchable
        />
      </div>
    );
  }

  renderGraph(options) {
    return (
      <div
        className="Simple-Card-View Header-content"
        style={{
          display: 'flex', flexDirection: 'column', padding: 0, width: '100%', backgroundColor: StyleConstants.color.white, marginTop: 5, marginBottom: 30, justifyContent: 'center', boxShadow: '0px 0px 12px rgba(65, 61, 74, 0.1)',
        }}
      >
        <div style={{ padding: '20px 0px 0px 20px', display: 'flex', flexDirection: 'column' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            style={{ zIndex: 0 }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { showLoader, selectedInstitute } = this.state;
    const { subscriptionData, registrationData, instituteData } = this.props;
    if (showLoader) {
      return (
        <Loader />
      );
    }
    const subscriptions = this.setOptions(subscriptionData);
    const registrations = this.setOptions(registrationData);
    return (
      <div style={{
        width: '80%', marginLeft: '20%', padding: 80, fontFamily: 'nunito',
      }}
      >
        <div style={{
          display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: 40,
        }}
        >
          {this.renderDropDown(instituteData, selectedInstitute, (selectedItem) => {
            this.setState({ selectedInstitute: selectedItem }, () => {
              this.getData(1);
            });
          }, 'Select Institute')}
        </div>
        <div style={{
          fontSize: 22, fontWeight: 'bold', marginBottom: 20,
        }}
        >
          Registrations
        </div>
        {this.renderGraph(registrations)}
        <div style={{
          fontSize: 22, fontWeight: 'bold', marginTop: 20, marginBottom: 20,
        }}
        >
          Subscriptions
        </div>
        {this.renderGraph(subscriptions)}
      </div>
    );
  }
}

StudentInsights.propTypes = {
  subscriptionData: PropTypes.object,
  registrationData: PropTypes.object,
  instituteData: PropTypes.object,
  defaultSelectedInstitute: PropTypes.object,
};

StudentInsights.defaultProps = {
  subscriptionData: {},
  registrationData: {},
  defaultSelectedInstitute: {},
  instituteData: {},
};

const mapStateToProps = ({ studentProgress }) => ({
  subscriptionData: studentProgress.subscriptionData,
  instituteData: studentProgress.instituteData,
  registrationData: studentProgress.registrationData,
  defaultSelectedInstitute: studentProgress.selectedInstitute,
});

export default connect(mapStateToProps, {
  getSubscriptionData,
})(StudentInsights);
