import React from 'react';
import { connect } from 'react-redux';
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  FlexibleWidthXYPlot,
  Crosshair,
} from 'react-vis';
import PropTypes from 'prop-types';
import {
  Filter, Loader, CustomAxisLabel, TestHeader, Header, SideBar,
} from './common';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import '../../node_modules/react-vis/dist/style.css';
import { isMobileDevice } from '../helpers/Utils';
import ChevronRight from '../shared/images/chevron-right.svg';
import { SIDE_BAR_ITEMS, BOTTOM_BAR_ITEMS } from '../shared/constants/fieldTypes';
import { getTestComparison, updateFilterModel, updateFilterData, enableLoader } from '../actions';

const IS_MOBILE = isMobileDevice();

const cardStyle = {
  barColor:
  {
    height: 16, width: 20, marginRight: 10,
  },
  cardTitle:
  {
    fontSize: 18, fontWeight: 'bold', marginBottom: 5, marginTop: 15,
  },
  barColorContainer:
  {
    display: 'flex', padding: '0 90px',
  },
  select:
  {
    border: '1px solid #CBCBCB', color: '#43464B', background: StyleConstants.color.white, borderRadius: 5, width: '40%',
  },
  subjectContainer:
  {
    display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginBottom: 15, color: StyleConstants.color.white,
  },
  subjectCard:
  {
    borderRadius: 4, padding: '10px 80px', marginBottom: 5, display: 'flex', flexDirection: 'column', placeItems: 'center',
  },
  subjectCardTitle:
  {
    fontWeight: 12,
  },
  subjectCardValue:
  {
    fontWeight: 24,
  },
};

class TestComparision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
      id: props.match.params.id,
    };
    this.updateResults = this.updateResults.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.onMouseOverUpdateGraph = this.onMouseOverUpdateGraph.bind(this);
    this.onMouseLeaveUpdateGraph = this.onMouseLeaveUpdateGraph.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    const { getTestComparison } = this.props;
    const payload = { id };
    getTestComparison(payload);
  }

  onMouseLeaveUpdateGraph() {
    this.setState({ crosshairValues: [] });
  }

  onMouseOverUpdateGraph(value, { index }) {
    const {
      currentTestPerformance, previousTestPerformance, maxScore, scoreInterval,
    } = this.props;
    const interval = scoreInterval;
    const score = Object.keys(currentTestPerformance);
    const currentTestStudentCount = Object.values(currentTestPerformance);
    const previousTestStudentCount = Object.values(previousTestPerformance);
    let xInterval1 = 0;
    let xInterval2 = 0;
    const currentTest = score.map((currentValue, idx) => {
      xInterval1 = parseInt(currentValue, 10) * interval;
      xInterval2 = (parseInt(currentValue, 10) + 1) * interval;
      xInterval2 = xInterval2 > maxScore ? maxScore : xInterval2;
      if (idx !== score.length - 1) {
        return { x: `${xInterval1}-${xInterval2}`, y: currentTestStudentCount[idx] };
      }
    });
    const previousTest = score.map((previousValue, idx) => {
      xInterval1 = parseInt(previousValue, 10) * interval;
      xInterval2 = (parseInt(previousValue, 10) + 1) * interval;
      xInterval2 = xInterval2 > maxScore ? maxScore : xInterval2;
      if (idx !== score.length - 1) {
        return { x: `${xInterval1}-${xInterval2}`, y: previousTestStudentCount[idx] };
      }
    });
    currentTest.pop();
    previousTest.pop();
    currentTest.unshift({ x: ` > ${0}`, y: currentTestPerformance[-1] });
    previousTest.unshift({ x: ` > ${0}`, y: previousTestPerformance[-1] });
    const crosshairValues = [];
    crosshairValues.push(currentTest[index]);
    crosshairValues.push(previousTest[index]);
    this.setState({ crosshairValues });
  }

  updateResults(payload) {
    const { id } = this.state;
    const { getTestComparison, enableLoader } = this.props;
    if (payload.id && payload.id !== id) {
      window.location.href = `/test_comparision/${payload.id}`;
    }
    enableLoader();
    getTestComparison(payload);
  }

  updateFilters(value, type) {
    const {
      updateFilterData,
    } = this.props;
    const { id } = this.state;
    updateFilterData('ComparisionTestSelected', value);
    const payload = {
      id,
    };
    this.updateResults(payload);
  }


  renderMedianGraph() {
    const {
      subjectsMedian, subjects, name, previousTestName,
    } = this.props;
    let maxXAxisDomain = 0;
    const currentTest = Object.keys(subjects).map((subjectId) => {
      if (subjectsMedian[name][`s_${subjectId}`] > maxXAxisDomain) {
        maxXAxisDomain = subjectsMedian[name][`s_${subjectId}`];
      }
      return { name: subjects[subjectId].name, mark: subjectsMedian[name][`s_${subjectId}`] };
    });

    const selectedTest = Object.keys(subjects).map((subjectId) => {
      if (subjectsMedian[previousTestName][`s_${subjectId}`] > maxXAxisDomain) {
        maxXAxisDomain = subjectsMedian[previousTestName][`s_${subjectId}`];
      }
      return { name: subjects[subjectId].name, mark: subjectsMedian[previousTestName][`s_${subjectId}`] };
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 90px' }}>
        <div style={{ ...cardStyle.subjectContainer }}>
          {currentTest.map((subject) => {
            return (
              <div style={{ background: StyleConstants.tests.current, width: IS_MOBILE ? 'fit-content' : `${(100 / currentTest.length + 1) - 2.2}%`, ...cardStyle.subjectCard }}>
                <div style={{ ...cardStyle.subjectCardValue }}>{subject.mark}</div>
                <div style={{ ...cardStyle.subjectCardTitle }}>{subject.name}</div>
              </div>
            );
          })}
        </div>
        <div style={{ ...cardStyle.subjectContainer }}>
          {selectedTest.map((subject) => {
            return (
              <div style={{ background: StyleConstants.tests.previous, width: IS_MOBILE ? 'fit-content' : `${(100 / selectedTest.length + 1) - 2.2}%`, ...cardStyle.subjectCard }}>
                <div style={{ ...cardStyle.subjectCardValue }}>{subject.mark || 0}</div>
                <div style={{ ...cardStyle.subjectCardTitle }}>{subject.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }


  renderPreviousTestComparison() {
    const {
      currentTestPerformance, previousTestPerformance, maxScore, scoreInterval,
    } = this.props;
    const { crosshairValues } = this.state;
    const interval = scoreInterval;
    const score = Object.keys(currentTestPerformance);
    const currentTestStudentCount = Object.values(currentTestPerformance);
    const previousTestStudentCount = Object.values(previousTestPerformance);
    const maxValue = Math.max(...Object.values(currentTestPerformance),
      ...Object.values(previousTestPerformance));
    let roundedValue = maxValue / 5;
    roundedValue = (roundedValue * 5) / 100;
    let xInterval1 = 0;
    let xInterval2 = 0;
    const currentTest = score.map((value, idx) => {
      xInterval1 = parseInt(value, 10) * interval;
      xInterval2 = (parseInt(value, 10) + 1) * interval;
      xInterval2 = xInterval2 > maxScore ? maxScore : xInterval2;
      if (idx !== score.length - 1) {
        let yvalue = currentTestStudentCount[idx];
        yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
        yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue
          : roundedValue;
        return { x: `${xInterval1}-${xInterval2}`, y: yvalue };
      }
    });
    const previousTest = score.map((value, idx) => {
      xInterval1 = parseInt(value, 10) * interval;
      xInterval2 = (parseInt(value, 10) + 1) * interval;
      xInterval2 = xInterval2 > maxScore ? maxScore : xInterval2;
      if (idx !== score.length - 1) {
        let yvalue = previousTestStudentCount[idx];
        yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
        yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue
          : roundedValue;
        return { x: `${xInterval1}-${xInterval2}`, y: yvalue };
      }
    });
    currentTest.pop();
    previousTest.pop();
    let currentTestLastValue = currentTestPerformance[-1];
    currentTestLastValue = currentTestLastValue
    && currentTestLastValue !== 'undefined' ? currentTestLastValue : 0;
    currentTestLastValue = (currentTestLastValue > roundedValue
      || currentTestLastValue === 0 || roundedValue < 1) ? currentTestLastValue
      : roundedValue;
    let previousTestLastValue = previousTestPerformance[-1];
    previousTestLastValue = previousTestLastValue
    && previousTestLastValue !== 'undefined' ? previousTestLastValue : 0;
    previousTestLastValue = (previousTestLastValue > roundedValue
      || previousTestLastValue === 0 || roundedValue < 1) ? previousTestLastValue
      : roundedValue;
    currentTest.unshift({ x: ` > ${0}`, y: currentTestLastValue });
    previousTest.unshift({ x: ` > ${0}`, y: previousTestLastValue });
    return (
      <FlexibleWidthXYPlot xType="ordinal" height={300} xDistance={50} margin={{ left: IS_MOBILE ? 60 : 80, bottom: 80 }}>
        <VerticalGridLines style={{ stroke: '#e6e6e9' }} left={80} />
        <HorizontalGridLines style={{ stroke: '#e6e6e9' }} />
        <XAxis title="" style={{ ticks: { fontSize: 10, color: '#9EA0A5' } }} margin={{ left: 200 }} />
        <YAxis title="" style={{ ticks: { fontSize: 10, color: '#9EA0A5' } }} />
        <CustomAxisLabel innerHeight={270} style={{ fontSize: 12 }} title="No Of Students" />
        <CustomAxisLabel innerHeight={230} style={{ fontSize: 12 }} title="Score Range" xAxis />
        <VerticalBarSeries data={currentTest} color={StyleConstants.tests.current} barWidth={0.6} style={{ stroke: '#fff', strokeWidth: 2 }} onNearestX={this.onMouseOverUpdateGraph} />
        <VerticalBarSeries data={previousTest} color={StyleConstants.tests.previous} barWidth={0.6} style={{ stroke: '#fff', strokeWidth: 2 }} onNearestX={this.onMouseOverUpdateGraph} />
        <Crosshair
          values={crosshairValues}
          titleFormat={(data) => ({ title: 'Marks', value: data[0].x })}
          itemsFormat={(data) => {
            return [{ title: 'Current Test', value: `${data[0].y}` }, { title: 'Previous Test', value: `${data[1].y}` }];
          }}
        />
      </FlexibleWidthXYPlot>
    );
  }

  render() {
    const { id } = this.state;
    const {
      name, previousTestName, subjectSelected, pageError, isFilter, date, showLoader, updateFilterModel,
    } = this.props;
    if (showLoader) {
      return (
        <div>
          <Loader />
        </div>
      );
    }
    if (pageError) {
      return (
        <div>
          <div className="row" style={{ backgroundColor: 'rgb(243,248,250)', marginTop: 50 }}>
            <div style={{
              width: '20%', padding: 0, backgroundColor: StyleConstants.color.white, zIndex: 1,
            }}
            >
              <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_COMPARISION" testId={id} />
              <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_COMPARISION} />
            </div>
            <div style={{ width: '80%', paddingBottom: 100, padding: 0 }}>
              <p>Previous Test Data for comparison is not there</p>
            </div>
          </div>
        </div>
      );
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_COMPARISION} />
          <Filter
            updateResults={this.updateResults}
            updateFilters={this.updateFilters}
            testId={id}
            menuData={SIDE_BAR_ITEMS.TEST_COMPARISION}
          />
        </div>
      );
    }
    if (IS_MOBILE) {
      return (
        <div style={{ backgroundColor: 'rgb(243,248,250)', userSelect: 'none' }}>
          <Header testId={id} updateResults={this.updateResults} menuData={BOTTOM_BAR_ITEMS[2]} />
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_COMPARISION" testId={id} />
          <div style={{ marginTop: 60 }}>
            <TestHeader testName={name} testDate={date} />
            <div style={{ marginLeft: 20, fontWeight: 'bold', paddingTop: 10 }}>Compare with</div>
            <div
              style={{
                margin: 20, padding: 4, borderRadius: 4, justifyContent: 'space-between', display: 'flex', alignItems: 'center', border: '1px solid #CBCBCB',
              }}
              role="presentation"
              onClick={() => updateFilterModel(true, 'Compare With')}
            >
              <div>{previousTestName}</div>
              <img src={ChevronRight} alt={ChevronRight} height={30} width={30} />
            </div>
            <div className="Card-View" style={{ padding: 20, marginBottom: 40 }}>
              {this.renderPreviousTestComparison()}
              <div style={{ marginLeft: 20 }}>
                <div style={{ display: 'flex', marginBottom: 5 }}>
                  <div style={{
                    ...cardStyle.barColor,
                    backgroundColor: StyleConstants.tests.current,
                  }}
                  />
                  <div style={{ fontSize: 12 }}>{name}</div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{
                    ...cardStyle.barColor,
                    backgroundColor: StyleConstants.tests.previous,
                  }}
                  />
                  <div style={{ fontSize: 12 }}>{previousTestName}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ userSelect: 'none' }}>
        <style jsx>
          {`
              p {
                font-size: 18px;
                margin-bottom: 0;
              }
              .div-border {
                border-radius: 4px;
                border: 1px solid #BFBFBF;
                padding: 25px;
                margin-right: 20px;
              }
              .verticalBarSeries rect {
                margin: 10px;
              }
          `}
        </style>
        <div className="row" style={{ backgroundColor: '#F5F8FA', marginTop: IS_MOBILE ? 50 : StyleConstants.headerHeight.web }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_COMPARISION" testId={id} />
          </div>
          <div style={{ width: '80%', paddingBottom: 100, padding: 0 }}>
            <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_COMPARISION} />
            <div>
              <div style={{ boxShadow: '0px 1px 0px #8DFFDF', background: StyleConstants.testHeader.backgroundColor, padding: '20px 0' }}>
                {!subjectSelected
                  && (
                  <div>
                    {this.renderMedianGraph()}
                    <div style={{ ...cardStyle.barColorContainer }}>
                      <div style={{
                        ...cardStyle.barColor,
                        backgroundColor: StyleConstants.tests.current,
                      }}
                      />
                      <div style={{ fontSize: 12 }}>{name}</div>
                      <div style={{
                        ...cardStyle.barColor,
                        backgroundColor: StyleConstants.tests.previous,
                        marginLeft: 20,
                      }}
                      />
                      <div style={{ fontSize: 12 }}>{previousTestName}</div>
                    </div>
                  </div>
                  )}
              </div>
              <div style={{ padding: '0px 90px' }}>
                <div style={{ ...cardStyle.cardTitle }}>Score Distribution</div>
                <div className="Card-View" style={{ marginBottom: 40, padding: 30 }}>
                  {this.renderPreviousTestComparison()}
                  <div style={{ ...cardStyle.barColorContainer, padding: 0 }}>
                    <div style={{
                      ...cardStyle.barColor,
                      backgroundColor: StyleConstants.tests.current,
                    }}
                    />
                    <div style={{ fontSize: 12 }}>{name}</div>
                    <div style={{
                      ...cardStyle.barColor,
                      backgroundColor: StyleConstants.tests.previous,
                      marginLeft: 20,
                    }}
                    />
                    <div style={{ fontSize: 12 }}>{previousTestName}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestComparision.propTypes = {
  showLoader: PropTypes.bool,
  currentTestPerformance: PropTypes.object,
  previousTestPerformance: PropTypes.object,
  scoreInterval: PropTypes.number,
  maxScore: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  subjects: PropTypes.object,
  subjectsMedian: PropTypes.object,
  subjectSelected: PropTypes.number,
  previousTestName: PropTypes.string,
  pageError: PropTypes.bool,
  isFilter: PropTypes.bool,
  match: PropTypes.object,
  updateFilterModel: PropTypes.func.isRequired,
  getTestComparison: PropTypes.func.isRequired,
  enableLoader: PropTypes.func.isRequired,
};

TestComparision.defaultProps = {
  showLoader: false,
  currentTestPerformance: {},
  previousTestPerformance: {},
  scoreInterval: 0,
  maxScore: 0,
  name: '',
  date: '',
  subjects: {},
  subjectsMedian: {},
  subjectSelected: '',
  previousTestName: '',
  pageError: false,
  isFilter: false,
  match: {},
};

const mapStateToProps = ({ insight }) => ({
  subjectsMedian: insight.subjectsMedian,
  subjects: insight.subjects,
  name: insight.name,
  date: insight.date,
  campusSelected: insight.campusSelected,
  classroomSelected: insight.classroomSelected,
  subjectSelected: insight.subjectSelected,
  previousTestName: insight.previousTestName,
  currentTestPerformance: insight.currentTestPerformance,
  previousTestPerformance: insight.previousTestPerformance,
  pageError: insight.pageError,
  isFilter: insight.isFilter,
  scoreInterval: insight.scoreInterval,
  maxScore: insight.maxScore,
  showLoader: insight.showLoader,
});

export default connect(mapStateToProps, {
  updateFilterModel, getTestComparison, updateFilterData, enableLoader,
})(TestComparision);
