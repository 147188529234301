import React from 'react';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { isMobileDevice, getqbankDomain } from '../helpers/Utils';
import { logout, isInstituteGoalsDisabled } from '../actions';
import testInsights from '../shared/images/testInsights.svg';
import instituteInsights from '../shared/images/instituteInsights.svg';
import studentInsights from '../shared/images/student-insights.svg';

const cookies = new Cookies();
const IS_MOBILE = isMobileDevice();

class InsightsLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInstituteGoalsDisabled: true,
      isStudentInsightsDisabled: true,
    };
  }

  componentDidMount() {
    this.props.isInstituteGoalsDisabled().then((response) => {
      if (response) {
        this.setState({
          isInstituteGoalsDisabled: response.is_disabled,
          isStudentInsightsDisabled: response.is_student_insights_disabled,
        });
      }
    });
  }

  renderSpecificDomain(img, text, info, backcolor, fontColor, access, type) {
    const boxShadow = (!access) ? '0px 3px 6px #343B4014' : '';
    const textStyle = {
      fontSize: IS_MOBILE ? '16px' : '24px', fontWeight: 'bolder', color: fontColor, fontFamily: 'Nunito Sans',
    };
    const infoStyle = {
      alignItems: 'center', fontSize: IS_MOBILE ? '14px' : '20px', fontWeight: 200, color: '#52606C', fontFamily: 'Nunito',
    };
    return (
      <a
        className="cursor repeat-block"
        style={{
          alignItems: 'center', cursor: 'pointer', backgroundColor: '#FFFFFF', height: IS_MOBILE ? 170 : 312, textAlign: 'center', display: 'flex', justifyContent: 'center', width: IS_MOBILE ? 220 : 500, padding: IS_MOBILE ? 10 : 20, boxShadow, userSelect: 'none', color: '#FFFFFF', margin: '20px',
        }}
        href={`/${type}`}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', borderWidthColor: 'black', borderWidth: 1, height: 'fit-content',
        }}
        >
          <div style={{ padding: IS_MOBILE ? 10 : 20, height: '40%' }}>
            <img src={img} alt={text} style={{ marginTop: IS_MOBILE ? 10 : 30, height: IS_MOBILE ? '55px' : '130px' }} />
          </div>
          <div style={{
            backgroundColor: backcolor, height: '50%', width: '100%', minHeight: IS_MOBILE ? '60px' : '100px', alignContent: 'center',
          }}
          >
            <div style={textStyle}>{text}</div>
            <div style={infoStyle}>{info}</div>
          </div>
        </div>
      </a>
    );
  }

  renderDomain(img, text, type, info, backcolor, fontColor, linkDisabled, access) {
    if (linkDisabled) {
      return (
        <div style={{ opacity: 0.4 }} />
      );
    }
    return (
      <div>
        {this.renderSpecificDomain(img, text, info, backcolor, fontColor, access, type)}
      </div>
    );
  }

  render() {
    const user = cookies.get('user');
    let availableScreenHeight = 0;
    const { isInstituteGoalsDisabled, isStudentInsightsDisabled } = this.state;
    const { logout } = this.props;
    if (typeof window !== 'undefined') {
      availableScreenHeight = window.innerHeight;
    }
    const pagesData = [
      {
        img: instituteInsights,
        url: 'progress',
        name: 'instituteInsights',
        text: 'Institute Insights',
        info: 'You can see institute related dashboards here',
        backColor: '#FFFFFF',
        fontColor: '#11426C',
        isDisabled: isInstituteGoalsDisabled,
        access: true,
      },
      {
        img: testInsights,
        url: 'test_list',
        name: 'testInsights',
        text: 'Test Insights',
        info: 'You can see Test related dashboards here',
        backColor: '#FFFFFF',
        fontColor: '#11426C',
        isDisabled: false,
        access: true,
      },
      {
        img: studentInsights,
        url: 'student_insights/overview',
        name: 'studentInsights',
        text: 'Student Insights',
        info: 'You can see students related dashboards here',
        backColor: '#FFFFFF',
        fontColor: '#11426C',
        isDisabled: isStudentInsightsDisabled,
        access: true,
      },
    ];
    return (
      <div style={{ backgroundColor: '#F5F8FA', height: availableScreenHeight }}>
        <div style={{ width: '7%' }}>
          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'center', shadow: '#8B9DAF26', width: IS_MOBILE ? 60 : 90, backgroundColor: '#112F56', minHeight: '100vh', position: 'fixed', alignItems: 'center',
          }}
          >
            <div
              role="presentation"
              style={{
                color: 'rgb(232, 50, 66)', fontSize: 32, fontWeight: 'bold', userSelect: 'none', position: 'relative', cursor: 'pointer',
              }}
              onClick={() => { window.location.href = `${getqbankDomain()}`; }}
            >
              <div>
                <span style={{ color: 'rgb(232, 50, 66)', fontWeight: 'bold', fontSize: IS_MOBILE ? 15 : 27 }}>I</span>
                <span style={{ color: '#fff', fontWeight: 600, fontSize: IS_MOBILE ? 15 : 27 }}>B</span>
                <div style={{
                  textAlign: 'center', fontSize: IS_MOBILE ? 10 : 16, fontWeight: '600', color: '#E8E8E8',
                }}
                >
                  Insights
                </div>
              </div>
            </div>
            <div
              key="navbar-login"
              className="nav-item dropdown"
              style={{
                display: 'flex', alignItems: 'center', bottom: IS_MOBILE ? 30 : 50, position: 'fixed',
              }}
            >
              <div className="navbar" style={{ display: 'flex' }}>
                <div style={{
                  width: IS_MOBILE ? 34 : 50, height: IS_MOBILE ? 34 : 50, padding: 5, borderRadius: 25, background: '#FFFFFF',
                }}
                >
                  <div style={{
                    width: IS_MOBILE ? 24 : 40, height: IS_MOBILE ? 24 : 40, background: '#4F5F6F', borderRadius: IS_MOBILE ? 12 : 20, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#FFFFFF',
                  }}
                  >
                    { user ? user.name.charAt(0) : ''}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => logout()}
              style={{
                color: '#8D9FB4', marginBottom: IS_MOBILE ? 10 : 25, fontWeight: 400, fontSize: IS_MOBILE ? 10 : 14, cursor: 'pointer', letterSpacing: 0.2,
              }}
            >
              Log out
            </div>
          </div>
        </div>
        <div>
          <div style={{ backgroundColor: '#F5F8FA', padding: '2%' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', margin: 'auto', alignItems: 'center',
            }}
            >
              <div
                className="nunito-body"
                style={{
                  padding: '4%', display: 'flex', flexFlow: 'row wrap', width: '90%', alignItems: 'center', marginLeft: IS_MOBILE ? '16%' : '8%',
                }}
              >
                {
                    pagesData.map((pageData) => {
                      return this.renderDomain(pageData.img, pageData.text, pageData.url, pageData.info, pageData.backColor, pageData.fontColor, pageData.isDisabled, pageData.access);
                    })
                  }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
InsightsLandingPage.defaultProps = {
  isInstituteGoalsDisabled: true,
};
export default connect(null, { isInstituteGoalsDisabled, logout })(InsightsLandingPage);
