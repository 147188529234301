import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ReactModal from 'react-modal';
import { isMobileDevice } from '../../helpers/Utils';
import { updateMenuModel, logout } from '../../actions';
import { TEST_LIST_PAGE } from '../../shared/constants/textConstants';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

const cookies = new Cookies();
const IS_MOBILE = isMobileDevice();

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverMenuIndex: false,
    };
  }

  routePage(link) {
    const { testId, menuList } = this.props;
    window.location.href = menuList && menuList[0] && menuList[0].isInstituteGoals ? `/${link}` : `/${link}/${testId}`;
  }

  showMenu(menu, key) {
    const { hoverMenuIndex } = this.state;
    const { selectedOption } = this.props;
    const isSelected = selectedOption === key;
    const hoverColor = IS_MOBILE ? '#6C5CE7' : '#fbfbfb';
    const hoverBackgroundColor = IS_MOBILE ? '#EBE8FF' : '#003b61';
    const selectedOptionBackgroundColor = IS_MOBILE ? '#6C5CE7' : '#007fd2';
    let textStyle = {
      color: hoverMenuIndex === key ? hoverColor : '#6E7D90',
      fontWeight: 400,
      fontSize: StyleConstants.textSize.subHeader,
      marginBottom: 0,
      letterSpacing: 0.2,
      paddingLeft: 30,
      paddingTop: 12,
      paddingBottom: 12,
    };
    let boxStyle = {
      backgroundColor: hoverMenuIndex === key
        ? hoverBackgroundColor : '',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: 2,
      marginTop: 5,
      marginBottom: 5,
      ':hover': { backgroundColor: 'red' },
    };
    if (isSelected) {
      textStyle = {
        color: hoverColor,
        fontSize: StyleConstants.textSize.subHeader,
        marginBottom: 0,
        fontWeight: StyleConstants.textWeight.semiBold,
        letterSpacing: 0.2,
        paddingLeft: 30,
        paddingTop: 12,
        paddingBottom: 12,
      };
      boxStyle = {
        backgroundColor: hoverBackgroundColor,
        justifyContent: 'end',
        cursor: 'pointer',
        borderRadius: 2,
        marginTop: 5,
        marginBottom: 5,
      };
    }
    return (
      <div
        onMouseEnter={() => this.setState({ hoverMenuIndex: key })}
        role="presentation"
        onMouseLeave={() => this.setState({ hoverMenuIndex: false })}
        onClick={() => (isSelected ? '' : this.routePage(menu.key))}
        key={key}
        style={boxStyle}
      >
        <div style={{ display: 'flex', alignItems: 'center', height: 50 }}>
          <div style={{
            backgroundColor: isSelected
              ? selectedOptionBackgroundColor : '',
            width: 4,
            height: '60%',
            borderBottomRightRadius: 15,
            borderTopRightRadius: 15,
          }}
          />
          <div style={textStyle}>{menu.name}</div>
          <div style={{
            backgroundColor: isSelected
              ? selectedOptionBackgroundColor : '',
            width: 5,
            height: '60%',
            marginLeft: 'auto',
            borderBottomLeftRadius: 15,
            borderTopLeftRadius: 15,
          }}
          />
        </div>
      </div>
    );
  }

  render() {
    const user = cookies.get('user');
    const {
      menuList, isMenuBar, isOnline, logout, updateMenuModel,
    } = this.props;
    return (
      <div>
        {!IS_MOBILE
          ? (
            <div style={{
              minWidth: '20%',
              backgroundColor: '#112F56',
              minHeight: '100vh',
              position: 'fixed',
              padding: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            >
              <div style={{ width: '100%' }}>
                {menuList && Object.keys(menuList).map(
                  (key) => (!isOnline || menuList[key].isOnline)
                  && this.showMenu(menuList[key], key),
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}>
                <div style={{
                  width: 60,
                  height: 60,
                  padding: 5,
                  borderRadius: 30,
                  background: StyleConstants.color.white,
                  marginBottom: 15,
                  marginTop: 15,
                }}
                >
                  <div style={{
                    width: 50,
                    height: 50,
                    background: '#4F5F6F',
                    borderRadius: 25,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: StyleConstants.color.white,
                  }}
                  >
                    {user ? user.name.charAt(0) : ''}
                  </div>
                </div>
                <div
                  role="presentation"
                  onClick={() => { logout(); }}
                  style={{
                    color: '#8D9FB4',
                    fontWeight: '400',
                    fontSize: StyleConstants.textSize.subHeader,
                    cursor: 'pointer',
                    letterSpacing: 0.2,
                    marginBottom: 75,
                  }}
                >
                  Log out
                </div>
              </div>
            </div>
          )
          : (
            <ReactModal
              ariaHideApp={false}
              isOpen={isMenuBar}
              contentLabel="onRequestClose Example"
              onRequestClose={() => updateMenuModel()}
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: '#00000080',
                  overflowY: 'scroll',
                  zIndex: 5,
                },
                content: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '75%',
                  right: 'auto',
                  bottom: 'auto',
                  overflowY: 'scroll',
                  WebkitOverflowScrolling: 'scroll',
                  minWidth: '75%',
                  padding: 0,
                },
              }}
            >
              <div style={{ background: StyleConstants.sideBar.headerBackgroundColor, shadow: '#8DFFDF' }}>
                <div style={{ paddingLeft: 30, paddingTop: 25 }}>
                  <div
                    style={{ fontWeight: 'bold', fontSize: 20 }}
                    role="presentation"
                    onClick={() => { window.location.href = `/${TEST_LIST_PAGE}`; }}
                  >
                    <span style={{ color: '#FF6946' }}>Insights </span>
                    <span style={{ color: StyleConstants.color.dark }}>Board</span>
                  </div>
                  <div style={{
                    marginTop: 10,
                    height: 55,
                    width: 55,
                    border: '2px solid #3C11DE',
                    borderRadius: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 21,
                    fontWeight: 'bolder',
                    color: StyleConstants.sideBar.headerLogoColor,
                  }}
                  >
                    <div>{user ? user.name.charAt(0) : ''}</div>

                  </div>
                  <div style={{ fontSize: StyleConstants.textSize.text, marginTop: 10, fontWeight: 'bold' }}>{user.name}</div>
                  <div style={{
                    fontSize: 12, marginTop: 10, paddingBottom: 10, fontWeight: 'regular',
                  }}
                  >
                    {user.email}

                  </div>
                </div>
              </div>
              <div style={{ fontWeight: 'bold', fontSize: StyleConstants.textSize.text }}>
                {
                  menuList && menuList.map(
                    (menu, index) => menu.isMobile && this.showMenu(menu, index),
                  )
                }
              </div>
              <div
                onClick={() => { logout(); }}
                role="presentation"
                style={{
                  color: '#8D9FB4',
                  padding: '15px 34px',
                  bottom: 20,
                  fontWeight: '400',
                  fontSize: StyleConstants.textSize.subHeader,
                  cursor: 'pointer',
                  letterSpacing: 0.2,
                  width: '100%',
                }}
              >
                Log out
              </div>
            </ReactModal>
          )}
      </div>
    );
  }
}

SideBar.propTypes = {
  menuList: PropTypes.object,
  selectedOption: PropTypes.number,
  isOnline: PropTypes.bool,
  isMenuBar: PropTypes.bool,
  updateMenuModel: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  testId: PropTypes.number,
};

SideBar.defaultProps = {
  menuList: {},
  selectedOption: 0,
  isOnline: false,
  isMenuBar: false,
  testId: 0,
};

const mapStateToProps = ({ insight }) => ({
  isMenuBar: insight.isMenuBar,
  isOnline: insight.isOnline,
});

export default connect(mapStateToProps, { logout, updateMenuModel })(SideBar);
