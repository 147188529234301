import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

class Modal extends PureComponent {
  render() {
    const {
      children, showModal, handleCloseModal, header, height,
      width, minwidth, headerStyle, overrideCloseIcon, headerPadding,
    } = this.props;
    return (
      <div style={{ userSelect: 'none' }}>
        <ReactModal
          ariaHideApp={false}
          isOpen={showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={handleCloseModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: '#00000080',
              overflowY: 'scroll',
              zIndex: 5,
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: height || 'auto',
              width: width || 'auto',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              overflowY: 'scroll',
              WebkitOverflowScrolling: 'scroll',
              minWidth: minwidth || '25%',
              padding: 10,
            },
          }}
        >
          {header
            && (
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: '1px solid #efefef', padding: headerPadding || 20, alignItems: 'center', userSelect: 'none',
              }}
            >
              <b style={{
                fontSize: 18, paddingLeft: 10, ...headerStyle, whiteSpace: 'pre-wrap',
              }}
              >
                {' '}
                {header}
                {' '}
              </b>
              {overrideCloseIcon}
              {!overrideCloseIcon && (
              <div
                onClick={() => handleCloseModal()}
                role="presentation"
                style={{ cursor: 'pointer' }}
              >
                &#x2715;
              </div>
              )}
            </div>
            )}
          {children}
        </ReactModal>
      </div>
    );
  }
}

PropTypes.defaultProps = {
  headerStyle: {},
};

export default Modal;
