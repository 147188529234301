import React from 'react';
import { isMobileDevice } from '../../helpers/Utils';
import Text from './text';
import StyleConstants from '../../shared/constants/styleConstants/styles';

const IS_MOBILE = isMobileDevice();
const IMAGE_WIDTH = {
  0: { width: '50%', maxWidth: 310 },
  1: { width: '25%', maxWidth: 160 },
  '2': { width: '50%', maxWidth: 310 },
  3: { width: '70%', maxWidth: 430 },
};

const OPTION_IMAGE_WIDTH = {
  0: { width: '40%', maxWidth: 250 },
  1: { width: '25%', maxWidth: 160 },
  '2': { width: '40%', maxWidth: 250 },
  '3': { width: '50%', maxWidth: 310 },
};


class QuestionView extends React.Component {
  constructor(props) {
    super(props);
    this.renderOption = this.renderOption.bind(this);
    this.renderOptionItems = this.renderOptionItems.bind(this);
  }

  componentWillUnmount() {
  }

  renderOption() {
    const { currentQuestion } = this.props;
    const options_array = [1, 2, 3, 4];
    const inline = !IS_MOBILE && currentQuestion.options_layout === 0;
    const two_inline = !IS_MOBILE && currentQuestion.options_layout === 2;
    if (two_inline) {
      return (
        <div>
          {this.renderOptionItems(options_array.slice(0, 2), inline || two_inline)}
          {this.renderOptionItems(options_array.slice(2, 4), inline || two_inline)}
        </div>
      );
    }
    return (
      <div>
        {this.renderOptionItems(options_array, inline)}
      </div>
    );
  }

  renderOptionItems(options_array, inline) {
    const { currentQuestion } = this.props;
    const styles = inline ? { display: 'flex', marginBottom: 5 } : {};
    return (
      <div>
        <div style={styles}>
          {options_array.map((optionNumber, index) => {
            return (
              <div style={{
 display: 'flex', alignItems: 'center', marginLeft: (index !== 0 && inline) ? 20 : '', marginBottom: inline ? '' : 5 
}}>
                <Text
style={{ marginRight: 5 }}
                  text={`${optionNumber}.`}
                />
                {currentQuestion[`image_option_${optionNumber}`]
                  ? <div style={{ marginLeft: 5 }}><img src={currentQuestion[`image_option_${optionNumber}`]} width={currentQuestion.image_widths[`image_option_width_${optionNumber}`]} height="auto" /></div>
                  :                  <Text
                    text={currentQuestion[`option_${optionNumber}`]}
                  />}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const { currentQuestion } = this.props;
    if (!currentQuestion.id) {
      return <div />;
    }
    return (
      <div>
        <div style={{ margin: '10px 0px 15px' }}>
          <Text
            text={currentQuestion.question_text}
          />
        </div>
        {this.renderOption()}
      </div>
    );
  }
}
export default QuestionView;
