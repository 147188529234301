export const TOAST_MESSAGE_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

export const QUESTION_PAPER_VIEW_TYPE = {
  ONE_COLUMN: 1,
  TWO_COLUMN: 2,
  ALIGN_LEFT: 3,
  ALIGN_JUSTIFY: 4,
};

export const QUESTION_VIEW_CONFIG = {
  MAX_IMAGE_WIDTH: 300,
  LENGTH_OF_CHARACTER_ONE_LINE: 60,
  LENGTH_OF_CHARACTER_TWO_LINE: 100,
  BASE_FONT_SIZE: 10,
  LENGHT_FACTOR: 7,
  BASE_NUMBER_OF_ANSWER_KEY_LINE: 21,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_1: 80,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_2: 160,
  IMAGE_MAX_WIDTH_OPTION_LAYOUT_4: 300,
  MAX_WIDTH_OPTION_LAYOUT_1: 100,
  MAX_WIDTH_OPTION_LAYOUT_2: 200,
  MAX_WIDTH_OPTION_LAYOUT_4: 451,
};

export const MAIN_HEADERS = [
  {
    key: 'test_list',
    name: 'Test List',
    isTrack: false,
    isSubject: false,
    isTestList: false,
    isTestHeader: false,
    isCampus: false,
    isClassroom: false,
    isFilter: false,
    isTestType: false,
  },
];


export const INSTITUTE_GOALS_MENU = [
  {
    key: 'progress',
    name: 'Month-Wise Trends',
    isTrack: false,
    isSubject: false,
    isTestList: false,
    isTestHeader: false,
    isCampus: true,
    isClassroom: true,
    isTestType: true,
    isFilter: true,
    isMobile: true,
    isInstituteGoals: true,
  },
  {
    key: 'institute_goal_settings',
    name: 'Goal Settings',
    isTrack: false,
    isSubject: false,
    isTestList: false,
    isTestHeader: false,
    isCampus: false,
    isClassroom: false,
    isTestType: true,
    isFilter: false,
    isMobile: true,
    isInstituteGoals: true,
  },
  {
    key: 'student_bucket',
    name: 'Student Bucket',
    isTrack: false,
    isSubject: true,
    isTestList: false,
    isTestHeader: false,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: true,
    isInstituteGoals: true,
  },
];
export const SIDE_BAR_ITEMS = {
  HIGHLIGHTS: {
    key: 'highlights',
    name: 'Overview',
    isTrack: false,
    isSubject: false,
    isTestList: true,
    isTestHeader: true,
    isCampus: false,
    isClassroom: false,
    isFilter: false,
    isTestType: false,
    isMobile: true,
    isOnline: true,
  },
  TEST_SCORE_DISTRIBUTION: {
    key: 'test_score_distribution',
    name: 'Score Distribution',
    isTrack: false,
    isSubject: true,
    isTestList: true,
    isTestHeader: true,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: false,
    isMobile: true,
    isOnline: true,
  },
  ERROR_ANALYSIS: {
    key: 'error_analysis',
    name: 'Error Analysis',
    isTrack: false,
    isSubject: true,
    isTestList: true,
    isTestHeader: true,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: false,
    isMobile: true,
    isOnline: true,
  },
  PERFORMANCE_SHIFTS: {
    key: 'performance_shifts',
    name: 'Major Rank Change',
    isTrack: false,
    isSubject: false,
    isTestList: true,
    isTestHeader: true,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: false,
    isMobile: true,
    isOnline: true,

  },
  RANK_LIST: {
    key: 'rank_list',
    name: 'Rank List',
    isTrack: false,
    isSubject: true,
    isTestList: true,
    isTestHeader: true,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: false,
    isMobile: true,
    isOnline: true,

  },
  TRACK_COMPARISION: {
    key: 'track_comparision',
    name: 'Track Comparison',
    isTrack: false,
    isSubject: true,
    isTestList: true,
    isTestHeader: true,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: false,
    isMobile: true,
    isOnline: true,

  },
  TEST_COMPARISION: {
    key: 'test_comparision',
    name: 'Test Comparision',
    isTrack: false,
    isSubject: true,
    isTestList: true,
    isTestHeader: true,
    isTestComparision: true,
    isCampus: true,
    isClassroom: true,
    isTestType: false,
    isFilter: false,
    isMobile: true,
    isOnline: true,
  },
  TEST_ABSENTEES: {
    key: 'test_absentees',
    name: 'Test Absentees',
    isTrack: false,
    isSubject: false,
    isTestList: true,
    isTestHeader: true,
    isCampus: true,
    isClassroom: true,
    isFilter: true,
    isTestType: false,
    isMobile: false,
    isOnline: true,
  },
  DATA_MANAGEMENT: {
    key: 'data_management',
    name: 'Data Management',
    isTrack: false,
    isSubject: false,
    isTestList: false,
    isCampus: false,
    isClassroom: false,
    isFilter: false,
    isMobile: false,
    isOnline: false,
  },
};

export const SIDE_BAR_ITEMS_STUDENT_DETAILS = {
  activity: {
    key: 'student/activity',
    name: 'Activity',
    isInstituteGoals: true,
  },
};


export const SIDE_BAR_ITEMS_STUDENT_INSIGHTS = {
  overview: {
    key: 'student_insights/overview',
    name: 'Dashboard',
    isInstituteGoals: true,
  },
  students: {
    key: 'student_insights/students',
    name: 'Users',
    isInstituteGoals: true,
  },
};

export const SIDE_BAR_ITEMS_MOBILE = [
  {
    key: 'test_score_distribution',
    name: 'Distribution',
    isTrack: false,
    isSubject: true,
    isTestHeader: true,
    isCampus: true,
    isTestList: false,
    isFilter: true,
    isTestType: false,
  },
  {
    key: 'rank_list',
    name: 'Rank List',
    isTrack: false,
    isSubject: true,
    isTestHeader: true,
    isCampus: true,
    isTestList: false,
    isFilter: true,
    isTestType: false,
  },
  {
    key: 'track_comparision',
    name: 'Track Comparison',
    isTrack: true,
    isSubject: true,
    isTestHeader: true,
    isCampus: true,
    isTestList: false,
    isFilter: true,
    isTestType: false,
  },
  {
    key: 'performance_shifts',
    name: 'Performace Shifts',
    isTrack: true,
    isSubject: true,
    isTestHeader: true,
    isCampus: true,
    isTestList: false,
    isFilter: true,
    isTestType: false,
  },
];

export const BOTTOM_BAR_ITEMS = [
  {
    key: 'highlights',
    name: 'Overview',
    isTrack: false,
    isSubject: false,
    isTestHeader: false,
    isCampus: true,
    isTestList: true,
    isFilter: false,
    isTestType: false,
  },
  {
    key: 'error_analysis',
    name: 'Error Analysis',
    isTrack: true,
    isSubject: true,
    isTestHeader: false,
    isCampus: true,
    isTestList: true,
    isFilter: false,
    isTestType: false,
  },
  {
    key: 'test_comparision',
    name: 'Test Comparision',
    isTrack: false,
    isSubject: false,
    isTestHeader: false,
    isCampus: false,
    isTestList: true,
    isFilter: false,
    isTestType: false,
  },
];
