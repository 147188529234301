import { combineReducers } from 'redux';
import GlobalReducer from './globalReducer';
import Dashboard from './dashboard';
import Insight from './insight';
import InstituteProgress from './progress';
import StudentProgress from './studentProgress';
export default combineReducers({
  global: GlobalReducer,
  dashboard: Dashboard,
  insight: Insight,
  instituteProgress: InstituteProgress ,
  studentProgress: StudentProgress,
});
