import Cookies from 'universal-cookie';
import Constants from '../shared/constants/environmentConfig';
import { getAccountsDomain } from '../helpers/Utils';

const LOGOUT = '@@AUTH/LOGOUT';
const cookies = new Cookies();
export const SERVER_ERROR = 'Global/SERVER_ERROR';
export const SERVER_UNDER_MAINTENANCE = 'Global/SERVER_UNDER_MAINTENANCE';
export const HIDE_TOAST = 'Global/HIDE_TOAST_MESSAGE';
export const SHOW_TOAST = 'Global/SHOW_TOAST_MESSAGE';
export const UPDATE_LOADER_STATE = 'Global/updateLoaderState';

export const serverError = () => async (dispatch) => {
  dispatch({ type: SERVER_ERROR });
};

export const serverUnderMaintenance = () => async (dispatch) => {
  dispatch({ type: SERVER_UNDER_MAINTENANCE });
};

export const updateLoaderState = (state) => {
  const payload = { loaderState: state };
  return {
    type: UPDATE_LOADER_STATE,
    payload,
  };
};

export function logout() {
  return (dispatch) => {
    Object.keys(cookies.getAll()).forEach((cookieName) => {
      cookies.remove(cookieName,
        { secure: Constants().cookie.secure, domain: Constants().cookie.domainRegex });
      cookies.remove(cookieName, { path: '/', secure: Constants().cookie.secure });
    });
    dispatch({
      type: LOGOUT,
    });
    const isServer = typeof window === 'undefined';
    if (!isServer) {
      window.location.replace(`${getAccountsDomain()}/logout`);
    }
  };
}

export const showToast = (message) => async (dispatch) => {
  dispatch({ type: SHOW_TOAST, message });
  setTimeout(() => {
    dispatch({ type: HIDE_TOAST });
  }, 4000);
};

export const closeToastMessage = () => async (dispatch) => {
  dispatch({ type: HIDE_TOAST });
};
