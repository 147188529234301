import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import upArrow from '../shared/images/up.svg';
import downArrow from '../shared/images/down.svg';
import upArrowWhite from '../shared/images/up_white.svg';
import downArrowWhite from '../shared/images/down_white.svg';
import { isMobileDevice } from '../helpers/Utils';
import {
  Header, SideBar, Loader, Filter,
} from './common';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import ScoreDistributionGraph from './scoreDistributionGraph';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';
import { getTestOverviewData, enableLoader } from '../actions';

const IS_MOBILE = isMobileDevice();
const styles = {
  cardKey:
  {
    fontSize: 12, margin: 0, fontWeight: StyleConstants.textWeight.semiBold, whiteSpace: 'nowrap', color: StyleConstants.color.webKey,
  },
  cardValue: {
    margin: 0, fontWeight: IS_MOBILE ? StyleConstants.textWeight.semiBold : StyleConstants.textWeight.bold, whiteSpace: 'nowrap', fontSize: IS_MOBILE ? StyleConstants.textSize.secondaryHeader : StyleConstants.textSize.tertiaryHeader, color: IS_MOBILE ? StyleConstants.color.dark : StyleConstants.color.webTitle,
  },
  cardContainer: {
    display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between',
  },
  cardTitle:
  {
    fontSize: 16, fontWeight: 'bold', padding: IS_MOBILE ? '0 20px' : '0 90px', marginTop: 10, color: IS_MOBILE ? StyleConstants.color.dark : StyleConstants.color.webTitle,
  },
  displayCenter: {
    display: 'flex', justifyContent: 'center', alignItems: 'center',
  },
  performanceCard:
  {
    width: '48%', padding: 19, display: 'flex', alignItems: 'center', flexDirection: 'column',
  },
  moreTitleCard:
  {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: StyleConstants.textSize.subHeader, fontWeight: 'bold',
  },
  more:
  {
    color: StyleConstants.color.primary, fontSize: StyleConstants.textSize.text,
  },
};
class Highlights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
    };
    this.updateResults = this.updateResults.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    const { getTestOverviewData } = this.props;
    const payload = {
      id,
    };
    getTestOverviewData(payload);
    window.addEventListener('resize', this.resize);
  }

  updateResults(payload) {
    const { getTestOverviewData, enableLoader } = this.props;
    const { id } = this.state;
    if (payload.id !== id) {
      window.location.href = `/highlights/${payload.id}`;
    } else {
      enableLoader();
      getTestOverviewData(payload);
    }
  }

  renderScores() {
    const { rank, medianData, attendancePercentage } = this.props;
    const distributionData = IS_MOBILE ? Object.keys(rank) : medianData;
    return (
      <div style={{
        background: IS_MOBILE ? StyleConstants.color.primary : '#FFE196', boxShadow: IS_MOBILE ? StyleConstants.testHeader.shadowColor : '0px 1px 0px #FFCC4D', width: '100%', padding: IS_MOBILE ? '16px 20px' : '20px 90px',
      }}
      >
        {!IS_MOBILE && (
          <div style={{ ...styles.cardTitle, padding: 0, marginBottom: 10 }}>
            Median Scores
          </div>
        )}
        <div style={{ display: 'flex', width: '100%', ...styles.cardContainer, justifyContent: 'space-around' }}>
          {
            distributionData && distributionData.map((type) => {
              return (
                <div
                  className="Card-View"
                  style={{
                    width: IS_MOBILE ? `${(100 / distributionData.length + 1) - 2}%` : `${(100 / distributionData.length) - 2}%`,
                    padding: 10,
                    display: (IS_MOBILE && type === 'Lowest Score') && 'none',
                    maxWidth: 300,
                  }}
                >
                  <div style={{ ...styles.cardValue }}>{IS_MOBILE ? rank[type] || 0 : `${type.value}/${type.score}` || 0}</div>
                  <div style={{ ...styles.cardKey }}>{IS_MOBILE ? type : type.label}</div>
                </div>
              );
            })
          }
          {IS_MOBILE
            && (
              <div
                className="Card-View"
                style={{
                  width: IS_MOBILE ? `${(100 / distributionData.length + 1) - 2}%` : `${(100 / distributionData.length + 1) - 11}%`,
                  marginRight: 0,
                  padding: 10,
                }}
              >
                <div style={{ ...styles.cardValue }}>
                  {attendancePercentage || 0}
                  %
                  {' '}
                </div>
                <div style={{ ...styles.cardKey }}>Attendance</div>
              </div>
            )}
        </div>
      </div>
    );
  }

  renderPerformanceShift() {
    const { studentsPerformanceChange } = this.props;
    return (
      <div style={IS_MOBILE ? { textAlign: 'left', padding: '15px 0' } : { textAlign: 'left', padding: '15px 0' }}>
        <div style={{ ...styles.cardTitle, marginTop: 0 }}>Performance Shifts</div>
        <div style={{ ...styles.cardContainer, padding: IS_MOBILE ? '0 20px' : '0 90px' }}>
          <div className="Card-View" style={{ ...styles.performanceCard, background: IS_MOBILE ? '#00C674' : StyleConstants.color.white }}>
            <div style={{
              ...styles.cardValue, ...styles.displayCenter, width: 'fit-content', color: IS_MOBILE ? StyleConstants.color.white : '#00C674', fontFamily: 'Nunito Sans', fontWeight: 600,
            }}
            >
              <img src={IS_MOBILE ? upArrowWhite : upArrow} alt="" width={IS_MOBILE ? 13 : 18} height={IS_MOBILE ? 13 : 18} style={{ marginRight: 4 }} />
              <div style={{ fontSize: IS_MOBILE ? 18 : 28 }}>
                {Object.keys(studentsPerformanceChange.increased || {}).length || 0}
                {' '}
                Students
              </div>
            </div>
            <div style={{ ...styles.cardKey, color: IS_MOBILE ? StyleConstants.color.white : 'black' }}>significantly improved</div>
          </div>
          <div
            className="Card-View"
            style={{
              ...styles.performanceCard,
              background: IS_MOBILE ? StyleConstants.color.error : StyleConstants.color.white,
              marginRight: 0,
            }}
          >
            <div style={{
              ...styles.cardValue, ...styles.displayCenter, width: 'fit-content', color: IS_MOBILE ? StyleConstants.color.white : StyleConstants.color.error, fontFamily: 'Nunito Sans', fontWeight: 600,
            }}
            >
              <img src={IS_MOBILE ? downArrowWhite : downArrow} alt="" width={IS_MOBILE ? 13 : 18} height={IS_MOBILE ? 13 : 18} style={{ marginRight: 4 }} />
              <div style={{ fontSize: IS_MOBILE ? 18 : 28 }}>
                {Object.keys(studentsPerformanceChange.decreased || {}).length || 0}
                {' '}
                Students
              </div>
            </div>
            <div style={{ ...styles.cardKey, color: IS_MOBILE ? StyleConstants.color.white : 'black' }}>marks dropped sharply</div>
          </div>
        </div>
      </div>
    );
  }

  renderRankList() {
    const { rankList } = this.props;
    const { id } = this.state;
    return (
      <div style={{ marginBottom: 15 }}>
        <div style={{ ...styles.moreTitleCard, ...styles.cardTitle }}>
          <div>Rank List</div>
          <div
            style={{ ...styles.more }}
            role="presentation"
            onClick={() => { window.location.href = `/rank_list/${id}`; }}
          >
            {' '}
            More
            {' '}

          </div>
        </div>
        <div>
          {rankList
            .slice(0, 3)
            .map((data, index) => {
              return (
                <div
                  className="Card-View"
                  style={{
                    margin: '0 20px 5px', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',
                  }}
                >
                  <div style={{
                    background: '#EAEAEA', justifyContent: 'center', margin: '6px 15px', height: 33, width: 33, borderRadius: 16, display: 'flex', placeItems: 'center', fontSize: 12,
                  }}
                  >
                    <div>{index + 1}</div>

                  </div>
                  <div style={{ textOverflow: 'ellipsis', fontSize: StyleConstants.textSize.text }}>{data.name}</div>
                  <div style={{ marginLeft: 'auto', marginRight: 15, fontSize: 12 }}>{data.overall_total}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  renderScoreGraph() {
    const { id } = this.state;
    const { scoreDistribution } = this.props;
    return (
      <div>
        {!IS_MOBILE && <div style={{ ...styles.cardTitle, marginTop: 15 }}>Score Distribution</div>}
        <div style={{ padding: IS_MOBILE ? 0 : '0 90px' }}>
          <div className="Card-View" style={{ padding: IS_MOBILE ? '0 0 20px' : 30, margin: 0 }}>
            {IS_MOBILE
              && (
                <div>
                  <div style={{
                    ...styles.moreTitleCard, ...styles.cardTitle, marginTop: 0, padding: '20px 20px 0',
                  }}
                  >
                    <div>Score Distribution</div>
                    <div
                      style={{ ...styles.more }}
                      role="presentation"
                      onClick={() => { window.location.href = `/test_score_distribution/${id}`; }}
                    >
                      {' '}
                      More
                      {' '}
                    </div>
                  </div>
                </div>
              )}
            {
              Object.keys(scoreDistribution.graph_scores).length > 0
              && <ScoreDistributionGraph />
            }
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { id } = this.state;
    const { showLoader } = this.props;
    const {
      studentsPerformanceChange, isFilter, rankList,
    } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.HIGHLIGHTS} />
          <Filter updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.HIGHLIGHTS} testId={id} />
        </div>
      );
    }
    return (
      <div style={{ userSelect: 'none' }}>
        <div className="row" style={{ backgroundColor: '#F5F8FA', marginBottom: 67 }}>
          <div style={{
            width: IS_MOBILE ? '75%' : '20%', padding: 0, zIndex: 1, marginTop: IS_MOBILE ? 60 : StyleConstants.headerHeight.web,
          }}
          >
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="HIGHLIGHTS" testId={id} />
          </div>
          <div style={{
            width: !IS_MOBILE ? '80%' : '100%', paddingLeft: 0, paddingRight: 0, zIndex: 0, marginTop: IS_MOBILE ? 0 : StyleConstants.headerHeight.web,
          }}
          >
            <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.HIGHLIGHTS} />
            <div>
              {this.renderScores()}
              <div>
                {(studentsPerformanceChange.increased && studentsPerformanceChange.decreased
                  && (Object.keys(studentsPerformanceChange.increased).length > 0
                    || Object.keys(studentsPerformanceChange.decreased).length > 0))
                  && this.renderPerformanceShift()}
                {IS_MOBILE && rankList.length > 0 && this.renderRankList()}
              </div>
              {this.renderScoreGraph()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Highlights.propTypes = {
  attendancePercentage: PropTypes.number,
  scoreDistribution: PropTypes.object,
  rankList: PropTypes.array,
  rank: PropTypes.object,
  studentsPerformanceChange: PropTypes.array,
  medianData: PropTypes.object,
  isFilter: PropTypes.bool,
  match: PropTypes.object,
  params: PropTypes.object,
  getTestOverviewData: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  enableLoader: PropTypes.func.isRequired,
};

Highlights.defaultProps = {
  attendancePercentage: 0,
  scoreDistribution: {},
  rankList: [],
  rank: {},
  studentsPerformanceChange: [],
  medianData: {},
  isFilter: false,
  match: {},
  params: {},
  showLoader: false,
};


const mapStateToProps = ({ insight }) => ({
  attendancePercentage: insight.attendancePercentage,
  scoreDistribution: insight.scoreDistribution,
  rankList: insight.rankList,
  rank: insight.rank,
  studentsPerformanceChange: insight.studentsPerformanceChange,
  medianData: insight.medianData,
  isFilter: insight.isFilter,
  showLoader: insight.showLoader,
});

export default connect(mapStateToProps, { getTestOverviewData, enableLoader })(Highlights);
