import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  Loader, Text, Header, SnackBar,
} from './common';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import TestList from './testList';
import StudentBucket from './StudentBucket';
import StudentDetails from './studentDetails';
import Highlights from './highlights';
import TestScoreDistribution from './testDistribution';
import PerformanceShifts from './performanceShifts';
import RankListAnalysis from './rankListAnalysis';
import TestAbsentees from './testAbsentees';
import ErrorAnalysis from './errorAnalysis';
import TrackComparision from './trackComparision';
import TestComparision from './testComparision';
import DataManagement from './dataManagement';
import InsightsLandingPage from './insightsLandingPage';
import Progress from './progress';
import InstituteGoalSettings from './instituteGoalSettings';
import StudentInsights from './studentInsight/index';
import { isMobileDevice, getAccountsDomain } from '../helpers/Utils';
import SERVER_UNDER_MAINTENANCE from '../shared/images/something-went-wrong.svg';
import SERVER_UNDER_MAINTENANCE_MOBILE from '../shared/images/something-went-wrong-mobile.png';
import { getInstituteDetails, closeToastMessage } from '../actions';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';

const IS_MOBILE = isMobileDevice();
const toastBackgroundColor = {};
toastBackgroundColor[TOAST_MESSAGE_TYPES.SUCCESS] = '#4AD991';
toastBackgroundColor[TOAST_MESSAGE_TYPES.WARNING] = '#FF9519';
toastBackgroundColor[TOAST_MESSAGE_TYPES.ERROR] = '#F16F6F';

class RouteNavigation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: '',
    };
    this.resize = this.resize.bind(this);
    this.notifyMaintenance = this.notifyMaintenance.bind(this);
  }

  componentDidMount() {
    const { getInstituteDetails } = this.props;
    this.setState({ screenHeight: global.innerHeight });
    getInstituteDetails();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize() {
    this.forceUpdate();
  }

  notifyMaintenance() {
    const { screenHeight } = this.state;
    if (IS_MOBILE) {
      return (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', padding: '50px 20px', margin: '50px 0px 0px 45px' }}>
            <div style={{ fontSize: 20 }}>
              Server under maintenance
            </div>
            <div style={{ marginTop: 20, fontSize: 16, color: 'gray' }}>
              please try after sometime
            </div>
          </div>
          <div style={{
            height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0, bottom: 100,
          }}
          >
            <img style={{ width: '50%', height: '50%' }} alt="server_under_maintenance_mobile" src={SERVER_UNDER_MAINTENANCE_MOBILE} />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div style={{
          display: 'flex', flexDirection: 'column', position: 'fixed', top: 135, left: '10%', zIndex: 1000,
        }}
        >
          <div style={{ fontSize: 30 }}>
            Server under maintenance
          </div>
          <div style={{ marginTop: 20, fontSize: 20, color: 'gray' }}>
            please try after sometime
          </div>
        </div>
        <div style={{
          height: '100% !important', display: 'flex', justifyContent: 'center', position: 'fixed', right: 0,
        }}
        >
          <img style={{ height: screenHeight - 70 }} alt="server_under_maintenance" src={SERVER_UNDER_MAINTENANCE} />
        </div>
      </div>
    );
  }

  renderToast() {
    const { toastMessage } = this.props;
    return (
      <div style={{
        bottom: 40, position: 'fixed', display: 'flex', justifyContent: 'center', width: window.innerWidth * 0.8, zIndex: 1001, padding: '5px 10px',
      }}
      >
        <Text text={toastMessage} style={{ color: StyleConstants.color.white }} />
      </div>
    );
  }

  render() {
    const { screenHeight } = this.state;
    const { userProfile, showToast, isServerError, isServerUnderMaintenance } = this.props;

    if (isServerUnderMaintenance) {
      return (
        <Router>
          <Header />
          {this.notifyMaintenance()}
        </Router>
      );
    }
    if (isServerError) {
      return (
        <Router>
          <Header />
          <div
            className="App"
            style={{
              minHeight: screenHeight, height: '100% !important', background: '#F5F8FA', display: 'flex', alignItems: 'center', placeContent: 'center',
            }}
          >
            <Text
              text={
                'Sorry something went wrong, please check your internet connectivity or try again after sometime.\nYou may not have privileges to access this page.'
              }
              fontSize={StyleConstants.textSize.subHeader}
            />
          </div>
        </Router>
      );
    }
    return (
      <Router>
        <div className="App" style={{ minHeight: screenHeight, height: '100% !important', background: '#F5F8FA' }}>
          {this.props.loaderState && <Loader />}
          <SnackBar />
          {showToast && this.renderToast()}
          <div className={`App ${this.props.loaderState ? 'hide' : ''}`}>
            <Switch>
              <Route exact path="/login" render={(props) => window.location.href = getAccountsDomain()} />
              <Route exact path="/" render={(props) => <InsightsLandingPage {...props} userProfile={userProfile} />} />
              <Route path="/assignment_list" render={(props) => <TestList {...props} userProfile={userProfile} isTest={false} />} />
              <Route path="/test_list" render={(props) => <TestList {...props} userProfile={userProfile} isTest />} />
              <Route path="/student_insights/:type" render={(props) => <StudentInsights {...props} userProfile={userProfile} />} />
              <Route path="/student/:type/:id" render={(props) => <StudentDetails {...props} userProfile={userProfile} />} />
              <Route path="/student_bucket" render={(props) => <StudentBucket {...props} userProfile={userProfile} />} />
              <Route path="/highlights/:id" render={(props) => <Highlights {...props} userProfile={userProfile} />} />
              <Route path="/performance_shifts/:id" render={(props) => <PerformanceShifts {...props} userProfile={userProfile} />} />
              <Route path="/test_score_distribution/:id" render={(props) => <TestScoreDistribution {...props} userProfile={userProfile} />} />
              <Route path="/error_analysis/:id" render={(props) => <ErrorAnalysis {...props} userProfile={userProfile} />} />
              <Route path="/rank_list/:id" render={(props) => <RankListAnalysis {...props} userProfile={userProfile} />} />
              <Route path="/test_absentees/:id" render={(props) => <TestAbsentees {...props} userProfile={userProfile} />} />
              <Route path="/track_comparision/:id" render={(props) => <TrackComparision {...props} userProfile={userProfile} />} />
              <Route path="/test_comparision/:id" render={(props) => <TestComparision {...props} userProfile={userProfile} />} />
              <Route path="/data_management/:id" render={(props) => <DataManagement {...props} userProfile={userProfile} />} />
              <Route path="/progress" render={(props) => <Progress {...props} userProfile={userProfile} />} />
              <Route path="/institute_goal_settings" render={(props) => <InstituteGoalSettings {...props} userProfile={userProfile} />} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
RouteNavigation.propTypes = {
  closeToastMessage: PropTypes.func.isRequired,
  getInstituteDetails: PropTypes.func.isRequired,
  isServerError: PropTypes.bool,
  loaderState: PropTypes.bool,
  showToast: PropTypes.bool,
  toastMessage: PropTypes.string,
  toastMessageType: PropTypes.string,
  userProfile: PropTypes.object,
  notifications: PropTypes.array,
  isServerUnderMaintenance: PropTypes.bool,
};

RouteNavigation.defaultProps = {
  isServerError: false,
  loaderState: false,
  showToast: false,
  toastMessage: '',
  toastMessageType: '',
  userProfile: {},
  notifications: [],
  isServerUnderMaintenance: false,
};

const mapStateToProps = ({ global }) => ({
  isServerError: global.isServerError,
  loaderState: global.loaderState,
  toastMessage: global.toastMessage,
  showToast: global.showToast,
  toastMessageType: global.toastMessageType,
  isServerUnderMaintenance: global.isServerUnderMaintenance,
});

export default connect(mapStateToProps,
  { closeToastMessage, getInstituteDetails })(RouteNavigation);
