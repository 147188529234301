import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  LineMarkSeries,
  FlexibleWidthXYPlot,
  Crosshair,
} from 'react-vis';
import _ from 'lodash';
import { isMobileDevice } from '../helpers/Utils';
import { updateFilterData, getTrackComparison, enableLoader } from '../actions';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import {
  Filter, TestHeader, CustomAxisLabel, Loader, Header, SideBar, SubjectView,
} from './common';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';

const IS_MOBILE = isMobileDevice();
const styles = {
  barColor:
  {
    height: 20, width: 20, borderRadius: 4, marginLeft: 20, marginRight: 10,
  },
  cardTitle:
  {
    fontSize: 18, fontWeight: 'bold', marginBottom: 5,
  },
  barColorContainer:
  {
    display: 'flex', marginLeft: 58,
  },
};
class TrackComparision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      crosshairValues: [],
      crosshairValuesPerformance: [],
    };
    this.updateResults = this.updateResults.bind(this);
    this.onMouseOverUpdateGraph = this.onMouseOverUpdateGraph.bind(this);
    this.onMouseLeaveUpdateGraph = this.onMouseLeaveUpdateGraph.bind(this);
    this.onMouseOverUpdateGraphPerformance = this.onMouseOverUpdateGraphPerformance.bind(this);
    this.onMouseLeaveUpdateGraphPerformance = this.onMouseLeaveUpdateGraphPerformance.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    const { getTrackComparison } = this.props;
    const payload = { id };
    getTrackComparison(payload);
  }

  onMouseLeaveUpdateGraph() {
    this.setState({ crosshairValues: [] });
  }

  onMouseOverUpdateGraph(value, { index }) {
    const { subjectTrack1Comparison, subjectTrack2Comparison } = this.props;
    const interval = IS_MOBILE ? 30 : 20;
    const trackCount = IS_MOBILE ? 4 : 6;
    const track1Subject = [];
    for (let i = 0; i < trackCount; i += 1) {
      track1Subject[i] = { x: `${i * interval} - ${(i + 1) * interval}`, y: subjectTrack1Comparison[i] ? subjectTrack1Comparison[i].count : 0 };
    }
    const track2Subject = subjectTrack2Comparison.map((subjectTrack) => {
      return {
        x: `${subjectTrack.student_count * interval} - ${(subjectTrack.student_count + 1) * interval}`,
        y: subjectTrack.count,
      };
    });
    if (track1Subject.length > track2Subject.length) {
      for (let i = 0; i < (track1Subject.length - track2Subject.length); i += 1) {
        track2Subject.push({ x: `${(track2Subject.length + i) * interval} - ${(track2Subject.length + i + 1) * interval}`, y: 0 });
      }
    } else {
      for (let i = 0; i < (track2Subject.length - track1Subject.length); i += 1) {
        track1Subject.push({ x: `${(track1Subject.length + i) * interval} - ${(track1Subject.length + i + 1) * interval}`, y: 0 });
      }
    }
    const crosshairValues = [];
    crosshairValues.push(track1Subject[index]);
    crosshairValues.push(track2Subject[index]);
    this.setState({ crosshairValues });
  }

  onMouseOverUpdateGraphPerformance(value, { index }) {
    const {
      subjects, tracks, track1PerformanceComparison, track2PerformanceComparison,
    } = this.props;
    let isTrackAvailable = false;
    if (subjects && Object.keys(subjects).length > 0) {
      isTrackAvailable = Object.keys(subjects).reduce((trackValue, subjectId) => {
        return ((parseInt(tracks[subjectId], 10) === 2) || trackValue);
      }, false);
    }
    if (isTrackAvailable && Object.keys(track1PerformanceComparison).length > 0) {
      const track1PerformanceData = track1PerformanceComparison.map((trackPerformance) => ({
        x: trackPerformance.test_date,
        y: parseInt(trackPerformance.avg, 10),
      }));
      const track2PerformanceData = track2PerformanceComparison.map((trackPerformance) => ({
        x: trackPerformance.test_date,
        y: parseInt(trackPerformance.avg, 10),
      }));
      const track1Performance = _.sortBy(track1PerformanceData, 'x');
      const track2Performance = _.sortBy(track2PerformanceData, 'x');
      const crosshairValuesPerformance = [];
      crosshairValuesPerformance.push(track1Performance[index]);
      crosshairValuesPerformance.push(track2Performance[index]);
      this.setState({ crosshairValuesPerformance });
    }
  }

  onMouseLeaveUpdateGraphPerformance() {
    this.setState({ crosshairValuesPerformance: [] });
  }

  updateFilters(value, type) {
    const { updateFilterData } = this.props;
    updateFilterData(type, value);
    const {
      campusSelected, classroomSelected, subjectSelected,
    } = this.props;
    const { id } = this.state;
    const payload = {
      id,
      campus_id: campusSelected || '',
      classroom_id: classroomSelected || '',
      subject_id: subjectSelected || '',
    };
    this.updateResults(payload);
  }

  updateResults(payload) {
    const { id } = this.state;
    const { getTrackComparison, enableLoader } = this.props;
    if (payload.id && payload.id !== id) {
      window.location.href = `/track_comparision/${payload.id}`;
    }
    enableLoader();
    getTrackComparison(payload);
  }


  renderTrackCharts() {
    const {
      subjects, tracks, subjectSelected, track1PerformanceComparison, track2PerformanceComparison,
    } = this.props;
    let isTrackAvailable = false;
    if (subjects && Object.keys(subjects).length > 0) {
      isTrackAvailable = Object.keys(subjects).reduce((trackValue, subjectId) => {
        return ((parseInt(tracks[subjectId], 10) === 2) || trackValue);
      }, false);
    }
    const isComparisionTrack = Object.keys(track1PerformanceComparison).length > 1
      && Object.keys(track2PerformanceComparison).length > 1
      && (Object.keys(track1PerformanceComparison).length
        === Object.keys(track2PerformanceComparison).length);
    return (
      <div style={{ padding: '20px 90px' }}>
        <div style={{ ...styles.cardTitle }}>
          Track-wise score
          {' '}
          {subjectSelected ? `- ${subjects[subjectSelected].name}` : ''}
        </div>
        {isTrackAvailable
          && (
            <div className="Card-View" style={{ marginBottom: 40, padding: IS_MOBILE ? 20 : 30 }}>
              {this.renderTrackWiseScore()}
              <div style={{ ...styles.barColorContainer }}>
                <div style={{ ...styles.barColor, backgroundColor: '#F2C94C' }} />
                <p style={{ fontSize: 12 }}>Track 1</p>
                <div style={{ ...styles.barColor, backgroundColor: '#535353' }} />
                <p style={{ fontSize: 12 }}>Track 2</p>
              </div>
            </div>
          )}
        <div style={{ ...styles.cardTitle }}>
          Performance Trend
          {subjectSelected ? `- ${subjects[subjectSelected].name}` : ''}
        </div>

        <div className="Card-View" style={{ marginBottom: 40, padding: IS_MOBILE ? 20 : 30 }}>
          {this.renderPerformanceTrend()}
          {isTrackAvailable && isComparisionTrack
            && (
              <div style={{ ...styles.barColorContainer }}>
                <div style={{ ...styles.barColor, backgroundColor: '#F2C94C' }} />
                <p style={{ fontSize: 12 }}>Track 1</p>
                <div style={{ ...styles.barColor, backgroundColor: '#535353' }} />
                <p style={{ fontSize: 12 }}>Track 2</p>
              </div>
            )}

        </div>
      </div>
    );
  }

  renderTrackWiseScore() {
    const { subjectTrack1Comparison, subjectTrack2Comparison } = this.props;
    const { crosshairValues } = this.state;
    const interval = IS_MOBILE ? 30 : 20;
    const trackCount = IS_MOBILE ? 4 : 6;
    const barWidth = IS_MOBILE ? 0.7 : 0.6;
    const track1Subject = [];
    const maxValue = Math.max(
      subjectTrack1Comparison.reduce((max, x) => {
        return (parseInt(x.count, 10) > max)
          ? parseInt(x.count, 10) : max;
      }, 0),
      subjectTrack2Comparison.reduce((max, x) => {
        return (parseInt(x.count, 10) > max)
          ? parseInt(x.count, 10) : max;
      }, 0),
    );
    let roundedValue = maxValue / 5;
    roundedValue = (roundedValue * 10) / 100;
    for (let i = 0; i < trackCount; i += 1) {
      let yvalue = subjectTrack1Comparison[i] ? subjectTrack1Comparison[i].count : 0;
      yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
      yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue
        : roundedValue;
      track1Subject[i] = { x: `${i * interval} - ${(i + 1) * interval}`, y: subjectTrack1Comparison[i] ? Math.ceil(subjectTrack1Comparison[i].count / roundedValue) * roundedValue : 0 };
    }

    const track2Subject = subjectTrack2Comparison.map(
      (subjectTrack) => {
        let yvalue = subjectTrack ? subjectTrack.count : 0;
        yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
        yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue
          : roundedValue;
        return { x: `${subjectTrack.student_count * interval} - ${(subjectTrack.student_count + 1) * interval}`, y: yvalue };
      },
    );
    if (track1Subject.length > track2Subject.length) {
      for (let i = 0; i < (track1Subject.length - track2Subject.length); i += 1) {
        track2Subject.push({ x: `${(track2Subject.length + i) * interval} - ${(track2Subject.length + i + 1) * interval}`, y: 0 });
      }
    } else {
      for (let i = 0; i < (track2Subject.length - track1Subject.length); i += 1) {
        track1Subject.push({ x: `${(track1Subject.length + i) * interval} - ${(track1Subject.length + i + 1) * interval}`, y: 0 });
      }
    }
    return (
      <FlexibleWidthXYPlot height={258} yType="linear" margin={{ left: IS_MOBILE ? 60 : 80, bottom: 60 }} xType="ordinal">
        <VerticalGridLines style={{ stroke: '#e6e6e9' }} />
        <HorizontalGridLines style={{ stroke: '#e6e6e9' }} />
        <XAxis title="" style={{ ticks: { fontSize: IS_MOBILE ? 9 : 14, fontWeight: 'bold' } }} />
        <YAxis title="" style={{ ticks: { fontWeight: 'bold', fontSize: IS_MOBILE ? 9 : 14 } }} />
        <CustomAxisLabel title="No Of Students" style={{ fontSize: 12 }} />
        <CustomAxisLabel innerHeight={210} title="Score Range" xAxis style={{ fontSize: 12 }} />
        <VerticalBarSeries data={track1Subject} color="#F2C94C" barWidth={barWidth} style={{ stroke: '#fff', strokeWidth: 2 }} onNearestX={this.onMouseOverUpdateGraph} />
        <VerticalBarSeries data={track2Subject} color="#535353" barWidth={barWidth} style={{ stroke: '#fff', strokeWidth: 2 }} onNearestX={this.onMouseOverUpdateGraph} />
        <Crosshair
          values={crosshairValues}
          titleFormat={(data) => ({ title: 'Marks', value: data[0].x })}
          itemsFormat={(data) => {
            return [{ title: 'Track 1', value: `${data[0].y}` }, { title: 'Track 2', value: `${data[1].y}` }];
          }}
        />
      </FlexibleWidthXYPlot>
    );
  }

  renderPerformanceTrend() {
    const {
      subjects, tracks, track1PerformanceComparison, track2PerformanceComparison, name,
    } = this.props;
    let isTrackAvailable = false;
    const { crosshairValuesPerformance } = this.state;
    if (subjects && Object.keys(subjects).length > 0) {
      isTrackAvailable = Object.keys(subjects).reduce((trackValue, subjectId) => {
        return ((parseInt(tracks[subjectId], 10) === 2) || trackValue);
      }, false);
    }
    const isComparisionTrack = Object.keys(track1PerformanceComparison).length > 1
      && Object.keys(track2PerformanceComparison).length > 1
      && (Object.keys(track1PerformanceComparison).length
        === Object.keys(track2PerformanceComparison).length);
    if (isTrackAvailable && isComparisionTrack) {
      const track1PerformanceData = track1PerformanceComparison.map((trackPerformance) => ({
        x: trackPerformance.test_date,
        y: parseInt(trackPerformance.avg, 10),
      }));
      const track2PerformanceData = track2PerformanceComparison.map((trackPerformance) => ({
        x: trackPerformance.test_date,
        y: parseInt(trackPerformance.avg, 10),
      }));
      const track1Performance = _.sortBy(track1PerformanceData, 'x');
      const track2Performance = _.sortBy(track2PerformanceData, 'x');
      return (
        <FlexibleWidthXYPlot height={258} xType="ordinal" margin={{ left: IS_MOBILE ? 60 : 80, bottom: 60 }}>
          <HorizontalGridLines style={{ stroke: '#e6e6e9' }} />
          <VerticalGridLines style={{ stroke: '#e6e6e9' }} />
          <XAxis style={{
            text: {
              stroke: 'none',
              fill: '#6b6b76',
              fontWeight: StyleConstants.textWeight.semiBold,
            },
            ticks: { fontSize: IS_MOBILE ? 9 : 14, fontWeight: 'bold' },
          }}
          />
          <YAxis title="" style={{ ticks: { fontWeight: 'bold', fontSize: IS_MOBILE ? 9 : 14 } }} />
          <CustomAxisLabel title="Avg. Score" style={{ fontSize: 12 }} />
          <CustomAxisLabel innerHeight={210} title={name} xAxis style={{ fontSize: 12 }} />
          <LineMarkSeries
            data={track1Performance}
            style={{
              strokeLinejoin: 'round',
              strokeWidth: 3,
              fill: 'none',
            }}
            color={StyleConstants.color.tertiary}
            fill={StyleConstants.color.tertiary}
            size={2}
            curve="curveMonotoneX"
            onNearestX={this.onMouseOverUpdateGraphPerformance}
          />
          <LineMarkSeries
            data={track2Performance}
            style={{
              strokeLinejoin: 'round',
              strokeWidth: 3,
              fill: 'none',
            }}
            color="#43464B"
            fill="#43464B"
            size={2}
            curve="curveMonotoneX"
            onNearestX={this.onMouseOverUpdateGraphPerformance}
          />
          <Crosshair
            values={crosshairValuesPerformance}
            titleFormat={(data) => ({ title: 'Marks', value: data[0].x })}
            itemsFormat={(data) => {
              return [{ title: 'Track 1', value: `${data[0].y}` }, { title: 'Track 2', value: `${data[1].y}` }];
            }}
          />
        </FlexibleWidthXYPlot>
      );
    }
    return (
      <div>
        <div style={{
          textAlign: 'center', fontSize: IS_MOBILE ? 16 : 24, fontWeight: 'semi-bold',
        }}
        >
          You dont have any tracks details in previous test
        </div>
      </div>
    );
  }


  render() {
    const { id } = this.state;
    const {
      subjects, tracks, isFilter, name, date, campuses, campusSelected, subjectSelected,
      track1PerformanceComparison, track2PerformanceComparison, subjectTrack1Comparison,
      subjectTrack2Comparison, classrooms, classroomSelected, showLoader,
    } = this.props;
    let isTrackAvailable = false;
    if (subjects && Object.keys(subjects).length > 0) {
      isTrackAvailable = Object.keys(subjects).reduce((trackValue, subjectId) => {
        return ((parseInt(tracks[subjectId], 10) === 2) || trackValue);
      }, false);
    }
    isTrackAvailable = isTrackAvailable && subjectTrack1Comparison && subjectTrack2Comparison;
    const isComparisionTrack = track1PerformanceComparison
      && Object.keys(track1PerformanceComparison).length > 1
      && Object.keys(track2PerformanceComparison).length > 1
      && (Object.keys(track1PerformanceComparison).length
        === Object.keys(track2PerformanceComparison).length);
    if (showLoader) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TRACK_COMPARISION} />
          <Filter updateResults={this.updateResults} testId={id} menuData={SIDE_BAR_ITEMS.TRACK_COMPARISION} />
        </div>
      );
    }
    if (IS_MOBILE && (isTrackAvailable || isComparisionTrack)) {
      return (
        <div style={{ marginTop: 60, userSelect: 'none' }}>
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TRACK_COMPARISION" testId={id} />
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TRACK_COMPARISION} />
          <TestHeader testName={name} testDate={date} />

          <div>
            <SubjectView testId={id} updateResults={this.updateResults} />
            <div style={{ display: 'flex', marginBottom: 15, marginTop: 15 }}>
              <div style={{
                background: StyleConstants.color.inActive,
                color: StyleConstants.color.white,
                padding: '5px 10px',
                width: 'fit-content',
                marginLeft: 15,
                fontSize: 12,
                borderRadius: 4,
                marginRight: 5,
              }}
              >
                {campusSelected && campuses[campusSelected] ? campuses[campusSelected].name : 'All campuses'}
              </div>
              <div style={{
                background: StyleConstants.color.inActive,
                color: StyleConstants.color.white,
                padding: '5px 10px',
                width: 'fit-content',
                marginLeft: 15,
                fontSize: 12,
                borderRadius: 4,
              }}
              >
                {classroomSelected && classrooms[classroomSelected] ? classrooms[classroomSelected].name : 'All campuses'}
              </div>
            </div>
            <div>
              <div className="Card-View" style={{ marginBottom: 40, padding: 20 }}>
                {this.renderTrackWiseScore()}
              </div>
              <div className="Card-View" style={{ marginBottom: 40, padding: 20 }}>
                {this.renderPerformanceTrend()}
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (isTrackAvailable || isComparisionTrack) {
      return (
        <div className="row" style={{ backgroundColor: '#F5F8FA', userSelect: 'none' }}>
          <div style={{
            width: '20%', padding: 0, zIndex: 1, marginTop: StyleConstants.headerHeight.web,
          }}
          >
            <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TRACK_COMPARISION} />
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TRACK_COMPARISION" testId={id} />
          </div>
          <div style={{
            width: '80%', paddingBottom: 100, padding: 0, marginTop: StyleConstants.headerHeight.web,
          }}
          >
            {this.renderTrackCharts()}
          </div>
        </div>
      );
    }

    return (
      <div className="row" style={{ backgroundColor: '#F5F8FA', userSelect: 'none' }}>
        <div style={{
          width: '20%', padding: 0, zIndex: 1, marginTop: StyleConstants.headerHeight.web,
        }}
        >
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TRACK_COMPARISION} />
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TRACK_COMPARISION" testId={id} />
        </div>
        <div style={{
          width: '80%', paddingBottom: 100, padding: 0, marginTop: StyleConstants.headerHeight.web,
        }}
        >
          <div style={{
            textAlign: 'center', fontSize: IS_MOBILE ? 16 : 24, fontWeight: 'semi-bold', marginTop: '20%',
          }}
          >
            You dont have any tracks in this test or subject
          </div>
        </div>
      </div>
    );
  }
}

TrackComparision.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  subjects: PropTypes.object,
  classrooms: PropTypes.object,
  campuses: PropTypes.object,
  classroomSelected: PropTypes.number,
  campusSelected: PropTypes.number,
  subjectSelected: PropTypes.number,
  track1PerformanceComparison: PropTypes.array,
  track2PerformanceComparison: PropTypes.array,
  subjectTrack2Comparison: PropTypes.array,
  subjectTrack1Comparison: PropTypes.array,
  isFilter: PropTypes.bool,
  showLoader: PropTypes.bool,
  tracks: PropTypes.object,
  getTrackComparison: PropTypes.func.isRequired,
  updateFilterData: PropTypes.func.isRequired,
  enableLoader: PropTypes.func.isRequired,
  match: PropTypes.object,
};

TrackComparision.defaultProps = {
  name: '',
  date: '',
  subjects: {},
  campuses: {},
  classrooms: {},
  classroomSelected: '',
  campusSelected: '',
  subjectSelected: '',
  track1PerformanceComparison: [],
  subjectTrack1Comparison: [],
  subjectTrack2Comparison: [],
  track2PerformanceComparison: [],
  isFilter: false,
  showLoader: false,
  tracks: {},
  match: {},
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  date: insight.date,
  subjects: insight.subjects,
  tracks: insight.tracks,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  classroomSelected: insight.classroomSelected,
  campusSelected: insight.campusSelected,
  subjectSelected: insight.subjectSelected,
  subjectTrack1Comparison: insight.subjectTrack1Comparison,
  subjectTrack2Comparison: insight.subjectTrack2Comparison,
  track1PerformanceComparison: insight.track1PerformanceComparison,
  track2PerformanceComparison: insight.track2PerformanceComparison,
  isFilter: insight.isFilter,
  showLoader: insight.showLoader,
});

export default connect(mapStateToProps,
  {getTrackComparison, updateFilterData, enableLoader })(TrackComparision);
