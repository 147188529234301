import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Header, SideBar, TestHeader, Loader, Filter, BackToTop, SubjectView, ActivityIndicator,
} from './common';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';
import {
  getRankListData, updateFilterData, redirectToParentPortal, enableLoader, updateResponseData, showToast,
} from '../actions';
import { FILE_TYPE } from '../shared/constants/textConstants';
import { isMobileDevice } from '../helpers/Utils';

const textBreak = 30;
const dataLimit = 500;
const IS_MOBILE = isMobileDevice();

class RankListAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadType: FILE_TYPE.EXCEL,
      activePage: 1,
      itemsCountPerPage: 20,
      displayScroll: false,
      stop: 0,
      fetchMoreData: false,
      id: props.match.params.id,
      runAnalysis: false,
    };
    this.updateResults = this.updateResults.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.displaSetUp = this.displaSetUp.bind(this);
    this.trackScroll = this.trackScroll.bind(this);
    this.rankList = React.createRef();
  }

  componentDidMount() {
    const { id } = this.state;
    const payload = { id, limit: dataLimit };
    this.props.dispatch(getRankListData(payload)).then((response) => {
      if (response.fetch_more_data) {
        this.setState({ fetchMoreData: true });
        this.props.dispatch(getRankListData({ id })).then(() => {
          this.setState({ fetchMoreData: false });
        });
      }
    });
    window.onscroll = this.trackScroll;
  }

  setDownloadType(downloadType) {
    this.setState({ downloadType }, () => {
      this.rankList.current.save();
    });
  }

  getPayloadData() {
    const {
      campusSelected, classroomSelected, subjectSelected,
    } = this.props;
    const { id } = this.state;
    const payload = {
      id,
      campus_id: campusSelected ? campusSelected.value : '',
      classroom_id: classroomSelected || '',
      subject_id: subjectSelected || '',
    };
    return payload;
  }


  runAnalysis() {
    const { id } = this.state;
    this.setState({ runAnalysis: true });
    this.props.dispatch(updateResponseData(id)).then((result) => {
      if (result.success) {
        this.props.dispatch(showToast('Your data is being updated Check after few minutes'));
      }
    });
  }

  trackScroll() {
    const { stop } = this.state;
    const wrappedElement = document.getElementById('data-60');
    if (window.pageYOffset === 0 && stop === 1) {
      this.displaSetUp(false, 0);
    } else if (wrappedElement && window.pageYOffset > 3000 && stop === 0) {
      this.displaSetUp(true, 1);
    }
  }

  displaSetUp(x, y) {
    this.setState({ displayScroll: x, stop: y });
  }

  exportFile(downloadType) {
    this.setDownloadType(downloadType);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }


  truncate(text) {
    const { length } = text;
    let slicedWord = '';
    if (length > textBreak * 2) {
      slicedWord = `${text.slice(0, textBreak)}\n${text.slice(textBreak, textBreak * 2)}....`;
    } else if (length > textBreak) {
      slicedWord = `${text.slice(0, textBreak)}\n${text.slice(textBreak, textBreak * 2)}`;
    } else {
      slicedWord = text;
    }
    return slicedWord;
  }

  handleRedirectToParentPortal(id, studentId) {
    const { sampleTestId } = this.props;
    this.props.dispatch(redirectToParentPortal(studentId, id, sampleTestId));
  }

  updateFilters(value, type) {
    this.props.dispatch(updateFilterData(type, value)).then(() => {
      const payload = this.getPayloadData();
      this.updateResults(payload);
    });
  }


  updateResults(payload) {
    const { id } = this.state;
    if (payload.id && payload.id !== id) {
      window.location.href = `/rank_list/${payload.id}`;
    }
    this.props.dispatch(enableLoader());
    const limitedPayload = payload;
    limitedPayload.limit = dataLimit;
    this.props.dispatch(getRankListData(limitedPayload)).then((response) => {
      if (response.fetch_more_data) {
        this.props.dispatch(getRankListData(payload));
      }
    });
  }

  createExcelHeader(subjects) {
    const { campusSelected, classroomSelected } = this.props;
    const fields = ['Rank', 'Name', 'ID'];
    const fieldsIds = ['overall_rank', 'name', 'badge_id'];
    if (classroomSelected === '') {
      fields.push('Class');
      fieldsIds.push('classroom_name');
    }
    if (campusSelected === '') {
      fields.push('Campus');
      fieldsIds.push('campus_name');
    }
    fields.push('Score');
    fieldsIds.push('overall_total');
    Object.values(subjects).forEach((subject) => {
      fields.push(`${subject.name} Score`);
      fields.push(`${subject.name} Rank`);
      fieldsIds.push(`${subject.name.toLowerCase().replace(/ /g, '_')}_total`);
      fieldsIds.push(`${subject.name.toLowerCase().replace(/ /g, '_')}_rank`);
    });
    return {
      fields,
      fieldsIds,
    };
  }

  fetchMoreData() {
    const { itemsCountPerPage } = this.state;
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        itemsCountPerPage: itemsCountPerPage + 20,
      });
    }, 500);
  }

  renderStudentList(data, index, minCount) {
    const { subjectSelected, tracks, subjects } = this.props;
    const { id } = this.state;
    let subjectList = subjectSelected ? {} : subjects;
    subjectList = subjectSelected && tracks[subjectSelected.value] === '2' ? { 1: { id: 1, name: 'Track 1' }, 2: { id: 2, name: 'Track 2' } } : subjectList;
    const topperRank = [1, 2, 3];
    let textStyle = { color: 'black', fontSize: StyleConstants.textSize.text, fontFamily: 'Nunito' };
    if (topperRank.includes(parseInt(data.overall_rank, 10))) {
      textStyle = { fontSize: StyleConstants.textSize.text, fontFamily: 'Nunito', fontWeight: StyleConstants.textWeight.semiBold };
    }
    return (
      <tr id={`data-${parseInt(index, 10) + parseInt(minCount, 10)}`} className={index === 2 ? 'border_bottom text_color' : 'text_color'} key={`Student_${data.badge_id}}`} style={{ ...textStyle, color: StyleConstants.textLineColors.dark }} borderColor="red">
        <td style={{ padding: '14px 0' }}>{data.overall_rank}</td>
        <td
          role="presentation"
          onClick={() => this.handleRedirectToParentPortal(id, data.student_id)}
          style={{
            padding: '14px 5px', cursor: 'pointer', whiteSpace: (data.name && data.name.length > textBreak) ? 'pre-wrap' : 'nowrap', textAlign: 'left',
          }}
        >
          {data.name ? this.truncate(data.name) : ''}

        </td>
        <td style={{ padding: '14px 5px' }}>{data.badge_id}</td>
        <td style={{ padding: '14px 5px', whiteSpace: (data.classroom_name && data.classroom_name.length > textBreak) ? 'pre-wrap' : 'nowrap' }}>{data.classroom_name ? this.truncate(data.classroom_name) : ''}</td>
        <td style={{ padding: '14px 5px', whiteSpace: (data.campus_name && data.campus_name.length > textBreak) ? 'pre-wrap' : 'nowrap', textAlign: 'left' }}>
          {data.campus_name ? data.campus_name : ''}
        </td>
        <td style={{ padding: '14px 0' }}>{data.overall_total}</td>
        {Object.values(subjectList).map((subject) => {
          return (
            <React.Fragment key={`${subject.name}_values`}>
              <td style={{ padding: '14px 0' }}>
                {data[`${subject.name.toLowerCase().replace(/ /g, '_')}_total`]}
                {' '}
              </td>
              <td style={{ ...textStyle, padding: '14px 0' }}>
                {' '}
                {data[`${subject.name.toLowerCase().replace(/ /g, '_')}_rank`]}
                {' '}
              </td>
            </React.Fragment>
          );
        })}
        <style jsx>
          {`
            tr.border_bottom td {
              border-bottom:1px solid black;
            }            
          `}
        </style>
      </tr>
    );
  }

  render() {
    const {
      activePage, itemsCountPerPage, downloadType, displayScroll, id, fetchMoreData, runAnalysis,
    } = this.state;
    const {
      isFilter, rankList, subjectSelected, tracks, name,
      date, subjects, institute, campusSelected,
      classroomSelected, campuses, classrooms, showLoader, isOnline,
    } = this.props;
    let subject = subjectSelected ? {} : subjects;
    subject = subjectSelected && tracks[subjectSelected.value] === '2' ? { 1: { id: 1, name: 'Track 1' }, 2: { id: 2, name: 'Track 2' } } : subject;
    if (showLoader) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.RANK_LIST} />
          <Filter updateResults={this.updateResults} testId={id} menuData={SIDE_BAR_ITEMS.RANK_LIST} />
        </div>
      );
    }
    const metaData = this.createExcelHeader(subject);
    const { fields, fieldsIds } = metaData;
    const minCount = activePage >= 2 ? (activePage - 1) * itemsCountPerPage : 0;
    const maxCount = (activePage) * itemsCountPerPage;
    if (IS_MOBILE) {
      return (
        <div style={{ marginTop: 60, fontSize: StyleConstants.textSize.text, userSelect: 'none' }}>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.RANK_LIST} />
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="RANK_LIST" testId={id} />
          <TestHeader testName={name} testDate={date} />
          <SubjectView testId={id} updateResults={this.updateResults} />
          {rankList.length > 0
            ? (
              <InfiniteScroll
                dataLength={itemsCountPerPage}
                next={this.fetchMoreData}
                hasMore
              >
                {rankList.slice(minCount, maxCount).map((data, index) => {
                  return (
                    <div
                      className="Card-View"
                      style={{
                        margin: index === 0 ? '5px 20px 5px' : '0 20px 5px', justifyContent: 'flex-start', alignItems: 'center', marginRight: 10, flexDirection: 'row',
                      }}
                    >
                      <div style={{
                        background: StyleConstants.textLineColors.extraLight, justifyContent: 'center', margin: '6px 15px', height: 33, width: 33, borderRadius: 16, display: 'flex', placeItems: 'center', fontSize: 12,
                      }}
                      >
                        <div>{index + 1}</div>
                      </div>
                      <div
                        onClick={() => this.handleRedirectToParentPortal(id, data.student_id)}
                        role="presentation"
                        style={{ textOverflow: 'ellipsis', fontSize: StyleConstants.textSize.text, textAlign: 'left' }}
                      >
                        {data.name}
                      </div>
                      <div style={{ marginLeft: 'auto', marginRight: 20, fontSize: 12 }}>{subjectSelected && data.summary && data.summary[subjectSelected] ? data.summary[subjectSelected].total : data.overall_total}</div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            )
            : <div style={{ textAlign: 'center', marginTop: '20%' }}>No Rank Details Available For This Test</div>}
        </div>
      );
    }
    return (
      <div style={{ userSelect: 'none' }}>
        <style jsx>
          {`
            .printable {
              display: none
            }
                body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
              }
              table.print-friendly tr td, table.print-friendly tr th {
                page-break-inside: avoid;
              }
              .page {
                  width: 210mm;
                  min-height: 297mm;
                  padding: 20mm;
                  margin: 10mm auto;
                  border: 1px #D3D3D3 solid;
                  border-radius: 5px;
                  background: white;
                  display: none;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
              }

              @page {
                  size: A4;
                  margin: 0;
                  margin-top: 20px;
              }
              @media print {
                  html, body {
                      width: 210mm;
                      height: 297mm;
                  }
                  .printable {
                    display: block;
                  }
                  tr.border_bottom td {
                    border-bottom: 0px;
                    color: #454545 !important;
                  }
                  td{
                    width: fit-content !important;
                  }
                  .text_color > *{
                    color: #454545 
                  }
                  .page {
                      margin: 0;
                      margin-top: 20;
                      border: initial;
                      border-radius: initial;
                      width: initial;
                      min-height: initial;
                      box-shadow: initial;
                      background: initial;
                      page-break-after: always;
                      display: table;
                  }  
                  .printable-test-paper-background {
                    position: fixed;
                    margin-top: 10%;
                    font-size: 14px;
                    font-family: sans-serif;
                  }                 
                }
            `}
        </style>
        <div className="row" style={{ backgroundColor: '#F5F8FA' }}>
          <div
            className="hide-in-print"
            style={{
              width: '20%', padding: 0, zIndex: 1, marginTop: StyleConstants.headerHeight.web,
            }}
          >
            <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.RANK_LIST} />
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="RANK_LIST" testId={id} />
          </div>
          <div style={{ width: '80%', paddingBottom: 100, padding: 0 }}>
            <div className="hide-in-print" style={{ marginTop: StyleConstants.headerHeight.web }} />
            <div className="container-fluid p-0 App-Contet" style={{ marginTop: 0 }}>
              <div className="row no-gutter">
                {!isFilter
                  && (
                    <div className="rankList" style={{ width: '100%' }}>
                      <div style={{ position: 'absolute', left: '-1500px', top: 0 }}>
                        {downloadType === FILE_TYPE.EXCEL
                          && (
                            <ExcelExport
                              data={rankList}
                              fileName={`${name}.xlsx`}
                              ref={this.rankList}
                            >
                              {fields.map((field, index) => {
                                return (
                                  <ExcelExportColumn
                                    key={index}
                                    field={fieldsIds[index]}
                                    title={field}
                                  />
                                );
                              })}
                            </ExcelExport>
                          )}
                      </div>
                      {rankList.length > 0
                        ? (
                          <div className="container" style={{ marginTop: 0, padding: 30, background: StyleConstants.color.white }}>
                            <div
                              className="hide-in-print App-Box-Title"
                              style={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0 4px', marginBottom: 10, fontSize: 24,
                              }}
                            >
                              <div style={{ marginBottom: 0, display: 'flex' }}>
                                <div style={{ marginRight: 10 }}>Rank list</div>
                                {isOnline && (
                                <div
                                  role="presentation"
                                  className="hide-in-print"
                                  onClick={() => !runAnalysis && this.runAnalysis()}
                                  style={{
                                    cursor: runAnalysis ? 'not-allowed' : 'pointer', fontSize: StyleConstants.textSize.text, fontWeight: 'bold', color: StyleConstants.color.primary, padding: 6, borderRadius: 6, border: '1px solid #6C5CE7', margin: 0, display: 'flex', textAlign: 'center', opacity: runAnalysis && '0.5',
                                  }}
                                >
                                  <div style={{ marginRight: 5 }}>Re-Run Analysis</div>
                                  {runAnalysis && <ActivityIndicator />}
                                </div>
                                ) }
                              </div>
                              <div>
                                <div style={{ marginBottom: 0, display: 'flex' }}>
                                  <div
                                    onClick={() => window.print()}
                                    role="presentation"
                                    style={{
                                      cursor: 'pointer', fontSize: StyleConstants.textSize.text, fontWeight: 'bold', color: StyleConstants.color.primary, padding: 6, borderRadius: 6, border: '1px solid #6C5CE7', margin: 0, marginRight: 25,
                                    }}
                                  >
                                    Download PDF
                                  </div>
                                  <div
                                    role="presentation"
                                    onClick={() => (fetchMoreData ? '' : this.exportFile(FILE_TYPE.EXCEL))}
                                    style={{
                                      cursor: fetchMoreData ? 'not-allowed' : 'pointer', fontSize: StyleConstants.textSize.text, fontWeight: 'bold', color: StyleConstants.color.primary, padding: 6, borderRadius: 6, border: '1px solid #6C5CE7', margin: 0, display: 'flex', textAlign: 'center', opacity: fetchMoreData && '0.5',
                                    }}
                                  >
                                    <div style={{ marginRight: 5 }}>Download Excel</div>
                                    {fetchMoreData && <ActivityIndicator />}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="printable" style={{ width: '100%', alignItems: 'center' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {institute.instituteLogo && (
                                <img
                                  src={institute.instituteLogo}
                                  alt={institute.instituteName}
                                  style={{
                                    display: 'block', width: 'auto', height: 'auto', objectFit: 'contain', maxWidth: '100%', maxHeight: 120, marginLeft: 30,
                                  }}
                                />
                                )}
                                <div style={{ marginRight: 30 }}>
                                  {campusSelected && (
                                    <div style={{ display: 'flex' }}>
                                      <div style={{ fontSize: 22, fontWeight: 'bold' }}>Campus:&nbsp;</div>
                                      <div style={{ fontSize: 20 }}>
                                        {' '}
                                        {campuses[campusSelected].name}
                                      </div>
                                    </div>
                                  )}
                                  {classroomSelected && (
                                    <div style={{ display: 'flex' }}>
                                      <div style={{ fontSize: 22, fontWeight: 'bold' }}>Classroom:&nbsp;</div>
                                      <div
                                        style={{ fontSize: 20 }}
                                      >
                                        {classrooms[classroomSelected].name}
                                      </div>
                                    </div>
                                  )}
                                  {subjectSelected && (
                                    <div style={{ display: 'flex' }}>
                                      <div style={{ fontSize: 22, fontWeight: 'bold' }}>Subject:&nbsp;</div>
                                      <div
                                        style={{ fontSize: 20 }}
                                      >
                                        {subjects[subjectSelected].name}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div style={{
                                textAlign: 'center', fontWeight: 'bold', fontSize: 34, width: '100%', marginBottom: 15,
                              }}
                              >
                                <div style={{ fontSize: 34 }}>{institute.instituteName}</div>
                                <div
                                  style={{ fontSize: StyleConstants.textSize.primaryHeader }}
                                >
                                  {name}
                                </div>
                                <div style={{ fontSize: 22 }}>{date}</div>
                              </div>
                            </div>
                            <InfiniteScroll
                              dataLength={itemsCountPerPage}
                              next={this.fetchMoreData}
                              hasMore
                            >
                              {displayScroll
                                && <BackToTop />}
                              <table className="table table-striped" style={{ textAlign: 'center', marginBottom: 0 }}>
                                <thead>
                                  <tr style={{ fontSize: StyleConstants.textSize.subHeader, fontFamily: 'Nunito', fontWeight: '400' }}>
                                    <th rowSpan="2" style={{ verticalAlign: 'middle' }}> RANK</th>
                                    <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'left' }}> NAME </th>
                                    <th rowSpan="2" style={{ verticalAlign: 'middle' }}> ID </th>
                                    <th rowSpan="2" style={{ verticalAlign: 'middle' }}> CLASS </th>
                                    <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'left' }}> CAMPUS </th>
                                    <th rowSpan="2" style={{ verticalAlign: 'middle' }}> SCORE </th>
                                    {!subjectSelected && Object.values(subjects).map((subjectDetail) => (<th rowSpan="1" colSpan="2" key={`${subjectDetail.name}_header`}>{subjectDetail.name}</th>))}
                                  </tr>
                                  {!subjectSelected && (
                                    <tr style={{ fontSize: StyleConstants.textSize.subHeader, fontFamily: 'Nunito', fontWeight: '400' }}>
                                      {Object.values(subjects).map((subjectDetail) => (
                                        <React.Fragment key={`${subjectDetail.name}_fragment`}>
                                          <th key={`${subjectDetail.name}_R`}>S</th>
                                          <th key={`${subjectDetail.name}_S`}>R</th>
                                        </React.Fragment>
                                      ))}
                                    </tr>
                                  )}
                                </thead>

                                <tbody>
                                  {rankList.slice(minCount, maxCount).map((data, index) => (
                                    this.renderStudentList(data, index, minCount)))}
                                </tbody>
                              </table>
                            </InfiniteScroll>
                          </div>
                        )
                        : (
                          <div style={{
                            fontSize: 24, textAlign: 'center', fontWeight: 'semi-bold', marginTop: '20%',
                          }}
                          >
                            No Rank Details Available For This Test
                          </div>
                        )}
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RankListAnalysis.propTypes = {
  institute: PropTypes.object,
  subjects: PropTypes.object,
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  tracks: PropTypes.object,
  subjectSelected: PropTypes.number,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  sampleTestId: PropTypes.string,
  showLoader: PropTypes.bool,
  rankList: PropTypes.array,
  isFilter: PropTypes.bool,
  match: PropTypes.object,
  isOnline: PropTypes.bool,
};

RankListAnalysis.defaultProps = {
  institute: {},
  subjects: {},
  campuses: {},
  classrooms: {},
  tracks: {},
  subjectSelected: '',
  campusSelected: '',
  classroomSelected: '',
  name: '',
  date: '',
  sampleTestId: '',
  showLoader: false,
  rankList: [],
  isFilter: false,
  match: {},
  isOnline: false,
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  date: insight.date,
  rankList: insight.rankList,
  subjects: insight.subjects,
  tracks: insight.tracks,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  subjectSelected: insight.subjectSelected,
  campusSelected: insight.campusSelected,
  classroomSelected: insight.classroomSelected,
  institute: insight.institute,
  isFilter: insight.isFilter,
  sampleTestId: insight.sampleTestId,
  showLoader: insight.showLoader,
  isOnline: insight.isOnline,
});

export default connect(mapStateToProps)(RankListAnalysis);
