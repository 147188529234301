import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import {
  Header, SideBar, TestHeader, Loader, Filter, BackToTop, SubjectView,
} from './common';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';
import {
  getTestAbsentData, updateFilterData, redirectToParentPortal, enableLoader,
} from '../actions';
import { FILE_TYPE } from '../shared/constants/textConstants';
import { isMobileDevice, textTruncate } from '../helpers/Utils';
import '../shared/constants/styleConstants/absentees.css';

const textBreak = 30;
const dataLimit = 500;
const IS_MOBILE = isMobileDevice();

class TestAbsentees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsCountPerPage: 20,
      displayScroll: false,
      stop: 0,
      fetchMoreData: false,
      id: props.match.params.id,
    };
    this.updateResults = this.updateResults.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.displaSetUp = this.displaSetUp.bind(this);
    this.trackScroll = this.trackScroll.bind(this);
    this.testAbsentees = React.createRef();
  }

  componentDidMount() {
    const { id } = this.state;
    const payload = { id, limit: dataLimit };
    this.props.dispatch(getTestAbsentData(payload)).then((response) => {
      if (response.fetch_more_data) {
        this.setState({ fetchMoreData: true });
        this.props.dispatch(getTestAbsentData({ id })).then(() => {
          this.setState({ fetchMoreData: false });
        });
      }
    });
    window.onscroll = this.trackScroll;
  }

  getPayloadData() {
    const {
      campusSelected, classroomSelected,
    } = this.props;
    const { id } = this.state;
    const payload = {
      id,
      campus_id: campusSelected ? campusSelected.value : '',
      classroom_id: classroomSelected || '',
    };
    return payload;
  }

  trackScroll() {
    const { stop } = this.state;
    const wrappedElement = document.getElementById('data-60');
    if (window.pageYOffset === 0 && stop === 1) {
      this.displaSetUp(false, 0);
    } else if (wrappedElement && window.pageYOffset > 3000 && stop === 0) {
      this.displaSetUp(true, 1);
    }
  }

  displaSetUp(x, y) {
    this.setState({ displayScroll: x, stop: y });
  }

  handleRedirectToParentPortal(id, studentId) {
    const { sampleTestId } = this.props;
    this.props.dispatch(redirectToParentPortal(studentId, id, sampleTestId));
  }

  updateFilters(value, type) {
    this.props.dispatch(updateFilterData(type, value)).then(() => {
      const payload = this.getPayloadData();
      this.updateResults(payload);
    });
  }

  updateResults(payload) {
    const { id } = this.state;
    if (payload.id && payload.id !== id) {
      window.location.href = `/test_absentees/${payload.id}`;
    }
    this.props.dispatch(enableLoader());
    const limitedPayload = payload;
    limitedPayload.limit = dataLimit;
    this.props.dispatch(getTestAbsentData(limitedPayload)).then((response) => {
      if (response.fetch_more_data) {
        this.props.dispatch(getTestAbsentData(payload));
      }
    });
  }

  fetchMoreData() {
    const { itemsCountPerPage } = this.state;
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        itemsCountPerPage: itemsCountPerPage + 20,
      });
    }, 500);
  }

  showFilter(id) {
    return (
      <div>
        <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_ABSENTEES} />
        <Filter updateResults={this.updateResults} testId={id} menuData={SIDE_BAR_ITEMS.TEST_ABSENTEES} />
      </div>
    );
  }

  renderMobileView(id, name, date, testAbsentees, itemsCountPerPage, minCount, maxCount) {
    return (
      <div style={{ marginTop: 60, fontSize: StyleConstants.textSize.text, userSelect: 'none' }}>
        <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_ABSENTEES} />
        <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_ABSENTEES" testId={id} />
        <TestHeader testName={name} testDate={date} />
        <SubjectView testId={id} updateResults={this.updateResults} />
        {testAbsentees.length > 0

          ? (
            <InfiniteScroll
              dataLength={itemsCountPerPage}
              next={this.fetchMoreData}
              hasMore
            >
              {testAbsentees.slice(minCount, maxCount).map((data, index) => {
                return (
                  <div
                    className="Card-View"
                    style={{
                      margin: index === 0 ? '5px 20px 5px' : '0 20px 5px', justifyContent: 'flex-start', alignItems: 'center', marginRight: 10, flexDirection: 'row',
                    }}
                  >
                    <div style={{
                      background: StyleConstants.textLineColors.extraLight, justifyContent: 'center', margin: '6px 15px', height: 33, width: 33, borderRadius: 16, display: 'flex', placeItems: 'center', fontSize: 12,
                    }}
                    >
                      <div>{index + 1}</div>
                    </div>
                    <div
                      onClick={() => this.handleRedirectToParentPortal(id, data.student_id)}
                      role="presentation"
                      style={{ textOverflow: 'ellipsis', fontSize: StyleConstants.textSize.text, textAlign: 'left' }}
                    >
                      {data.name}
                    </div>
                    {/* <div style={{ marginLeft: 'auto', marginRight: 20, fontSize: 12 }}>{subjectSelected && data.summary && data.summary[subjectSelected] ? data.summary[subjectSelected].total : data.overall_total}</div> */}
                  </div>
                );
              })}
            </InfiniteScroll>
          )
          : <div style={{ textAlign: 'center', marginTop: '20%' }}>No Absentees Available For This Test</div>}
      </div>
    );
  }

  renderTableWithoutFilter(testAbsentees, institute, campusSelected, campuses, classroomSelected, classrooms, name, date, itemsCountPerPage, displayScroll, minCount, maxCount) {
    const fields = ['Name', 'ID', 'Class', 'Campus'];
    const fieldsIds = ['student_name', 'badge_id', 'classroom_name', 'campus_name'];
    return (
      <div className="testAbsentees" style={{ width: '100%' }}>
        {testAbsentees.length > 0
          ? (
            <div className="container" style={{ marginTop: 0, padding: 30, background: StyleConstants.color.white }}>
              <div
                className="hide-in-print App-Box-Title"
                style={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0 4px', marginBottom: 10, fontSize: 24,
                }}
              >
                <div style={{ marginRight: 10 }}>Test Absentees</div>
                <div style={{ display: 'flex' }}>
                  <div
                    onClick={() => window.print()}
                    role="presentation"
                    style={{
                      cursor: 'pointer', fontSize: StyleConstants.textSize.text, fontWeight: 'bold', color: StyleConstants.color.primary, padding: 6, borderRadius: 6, border: '1px solid #6C5CE7', margin: 0, marginRight: 25,
                    }}
                  >
                    Download PDF
                  </div>
                  <div
                    onClick={() => this.testAbsentees.current.save()}
                    role="presentation"
                    style={{
                      cursor: 'pointer', fontSize: StyleConstants.textSize.text, fontWeight: 'bold', color: StyleConstants.color.primary, padding: 6, borderRadius: 6, border: '1px solid #6C5CE7', margin: 0, marginRight: 25,
                    }}
                  >
                    Download Excel
                  </div>
                  <div style={{ display: 'none' }}>
                    <ExcelExport
                      data={testAbsentees}
                      fileName={`${name}.xlsx`}
                      ref={this.testAbsentees}
                    >
                      {fields.map((field, index) => {
                        return (
                          <ExcelExportColumn
                            field={fieldsIds[index]}
                            title={field}
                          />
                        );
                      })}
                    </ExcelExport>
                  </div>
                </div>
              </div>
              <div className="printable" style={{ width: '100%', alignItems: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {institute.instituteLogo && (
                    <img
                      src={institute.instituteLogo}
                      alt={institute.instituteName}
                      style={{
                        display: 'block', width: 'auto', height: 'auto', objectFit: 'contain', maxWidth: '100%', maxHeight: 120, marginLeft: 30,
                      }}
                    />
                  )}
                  <div style={{ marginRight: 30 }}>
                    {campusSelected && (
                      <div style={{ display: 'flex' }}>
                        <div style={{ fontSize: 22, fontWeight: 'bold' }}>Campus:&nbsp;</div>
                        <div style={{ fontSize: 20 }}>
                          {' '}
                          {campuses[campusSelected].name}
                        </div>
                      </div>
                    )}
                    {classroomSelected && (
                      <div style={{ display: 'flex' }}>
                        <div style={{ fontSize: 22, fontWeight: 'bold' }}>Classroom:&nbsp;</div>
                        <div
                          style={{ fontSize: 20 }}
                        >
                          {classrooms[classroomSelected].name}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{
                  textAlign: 'center', fontWeight: 'bold', fontSize: 34, width: '100%', marginBottom: 15,
                }}
                >
                  <div style={{ fontSize: 34 }}>{institute.instituteName}</div>
                  <div
                    style={{ fontSize: StyleConstants.textSize.primaryHeader }}
                  >
                    {name}
                  </div>
                  <div style={{ fontSize: 22 }}>{date}</div>
                </div>
              </div>
              <InfiniteScroll
                dataLength={itemsCountPerPage}
                next={this.fetchMoreData}
                hasMore
              >
                {displayScroll && <BackToTop />}
                <table className="table table-striped " style={{ textAlign: 'center', marginBottom: 0 }}>
                  <thead>
                    <tr style={{ fontSize: StyleConstants.textSize.subHeader, fontFamily: 'Nunito', fontWeight: '400' }}>
                      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'left' }}> Name </th>
                      <th rowSpan="2" style={{ verticalAlign: 'middle' }}> ID </th>
                      <th rowSpan="2" style={{ verticalAlign: 'middle' }}> Class </th>
                      <th rowSpan="2" style={{ verticalAlign: 'middle', textAlign: 'left' }}> Campus </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testAbsentees.slice(minCount, maxCount).map((data, index) => (
                      this.renderStudentList(data, index, minCount)))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          )
          : (
            <div style={{
              fontSize: 24, textAlign: 'center', fontWeight: 'semi-bold', marginTop: '20%',
            }}
            >
              No Absentees For This Test
            </div>
          )}
      </div>
    );
  }

  renderAbsenteesTable(isFilter, testAbsentees, institute, campusSelected, campuses, classroomSelected, classrooms, name, date, itemsCountPerPage, displayScroll, minCount, maxCount) {
    return (
      <div style={{ width: '80%', paddingBottom: 100, padding: 0 }}>
        <div className="hide-in-print" style={{ marginTop: StyleConstants.headerHeight.web }} />
        <div className="container-fluid p-0 App-Contet" style={{ marginTop: 0 }}>
          <div className="row no-gutter">
            {!isFilter && this.renderTableWithoutFilter(testAbsentees, institute, campusSelected, campuses, classroomSelected, classrooms, name, date, itemsCountPerPage, displayScroll, minCount, maxCount)}
          </div>
        </div>
      </div>
    );
  }

  renderAbsentees(id, isFilter, testAbsentees, institute, campusSelected, campuses, classroomSelected, classrooms, name, date, itemsCountPerPage, displayScroll, minCount, maxCount) {
    return (
      <div style={{ userSelect: 'none' }}>
        <div className="row" style={{ backgroundColor: '#F5F8FA' }}>
          <div
            className="hide-in-print"
            style={{
              width: '20%', padding: 0, zIndex: 1, marginTop: StyleConstants.headerHeight.web,
            }}
          >
            <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_ABSENTEES} />
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_ABSENTEES" testId={id} />
          </div>
          { this.renderAbsenteesTable(isFilter, testAbsentees, institute, campusSelected, campuses, classroomSelected, classrooms, name, date, itemsCountPerPage, displayScroll, minCount, maxCount) }
        </div>
      </div>
    );
  }

  renderStudentList(data, index, minCount) {
    const { id } = this.state;
    const textStyle = { color: 'black', fontSize: StyleConstants.textSize.text, fontFamily: 'Nunito' };
    return (
      <tr id={`data-${parseInt(index, 10) + parseInt(minCount, 10)}`} className="text_color" key={`Student_${data.badge_id}}`} style={{ ...textStyle, color: StyleConstants.textLineColors.dark }} borderColor="red">
        <td
          role="presentation"
          onClick={() => this.handleRedirectToParentPortal(id, data.student_id)}
          style={{
            padding: '14px 5px', cursor: 'pointer', whiteSpace: (data.name && data.name.length > textBreak) ? 'pre-wrap' : 'nowrap', textAlign: 'left',
          }}
        >
          {data.name ? textTruncate(data.student_name, textBreak) : ''}

        </td>
        <td style={{ padding: '14px 5px' }}>{data.badge_id}</td>
        <td style={{ padding: '14px 5px', whiteSpace: (data.classroom_name && data.classroom_name.length > textBreak) ? 'pre-wrap' : 'nowrap' }}>{data.classroom_name ? textTruncate(data.classroom_name, textBreak) : ''}</td>
        <td style={{ padding: '14px 5px', whiteSpace: (data.campus_name && data.campus_name.length > textBreak) ? 'pre-wrap' : 'nowrap', textAlign: 'left' }}>
          {data.campus_name ? data.campus_name : ''}
        </td>
        <style jsx>
          {`
            tr.border_bottom td {
              border-bottom:1px solid black;
            }            
          `}
        </style>
      </tr>
    );
  }

  render() {
    const {
      activePage, itemsCountPerPage, displayScroll, id,
    } = this.state;
    const {
      isFilter, testAbsentees, name, date, institute,
      campusSelected, classroomSelected, campuses, classrooms, showLoader,
    } = this.props;
    const minCount = activePage >= 2 ? (activePage - 1) * itemsCountPerPage : 0;
    const maxCount = (activePage) * itemsCountPerPage;

    if (showLoader) {
      return <Loader />;
    }

    if (isFilter) {
      this.showFilter(id);
    }

    if (IS_MOBILE) {
      this.renderMobileView(id, name, date, testAbsentees, itemsCountPerPage, minCount, maxCount);
    }

    return this.renderAbsentees(id, isFilter, testAbsentees, institute, campusSelected, campuses, classroomSelected, classrooms, name, date, itemsCountPerPage, displayScroll, minCount, maxCount);
  }
}

TestAbsentees.propTypes = {
  institute: PropTypes.object,
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  sampleTestId: PropTypes.string,
  showLoader: PropTypes.bool,
  testAbsentees: PropTypes.array,
  isFilter: PropTypes.bool,
  match: PropTypes.object,
};

TestAbsentees.defaultProps = {
  institute: {},
  campuses: {},
  classrooms: {},
  campusSelected: '',
  classroomSelected: '',
  name: '',
  date: '',
  sampleTestId: '',
  showLoader: false,
  testAbsentees: [],
  isFilter: false,
  match: {},
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  date: insight.date,
  testAbsentees: insight.testAbsentees,
  subjects: insight.subjects,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  campusSelected: insight.campusSelected,
  classroomSelected: insight.classroomSelected,
  institute: insight.institute,
  isFilter: insight.isFilter,
  sampleTestId: insight.sampleTestId,
  showLoader: insight.showLoader,
  isOnline: insight.isOnline,
});

export default connect(mapStateToProps)(TestAbsentees);
