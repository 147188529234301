import React, { PureComponent } from 'react';

class ActivityIndicator extends PureComponent {
  render() {
    return (
      <div>
        <i className="fa fa-spinner fa-spin" />
      </div>
    );
  }
}
export default ActivityIndicator;
