import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import Select from 'react-select';
import HighchartsReact from 'highcharts-react-official';
import * as AnnotationsModule from 'highcharts/modules/annotations';
import { showToast } from '../../actions/global';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import { getStudentActivity } from '../../actions/studentProgress';
import { secondsToHm } from '../../helpers/Utils';
import { getDropDownStyle } from '../../shared/constants/textConstants';
import {
  Loader, DateRange, Modal, DateStatus, LocaleDate,
} from '../common';

AnnotationsModule(Highcharts);
const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
class StudentInsights extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      showModal: false,
      selectedRange: { startDate: oneWeekAgo, endDate: new Date() },
      startDate: new Date(),
      selectedObjectMode: { value: 'All', label: 'All' },
      selectedTestMode: { value: 'All', label: 'All' },
    };
    this.renderDateRangePicker = this.renderDateRangePicker.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  componentDidMount() {
    this.renderData();
  }

  setOptions(graphData, type, chartType = 'column') {
    return ({
      title: { text: '' },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        backgroundColor: 'white',
        style: {
          width: 'inherit',
          height: 450,
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
      xAxis: {
        title: { text: 'Time Period' },
        categories: Object.keys(graphData).map((data) => {
          return data.split(',')[0];
        }),
      },
      yAxis: {
        title: { text: 'Time Spent' },
        labels: {
          formatter() {
            return this.value;
          },
        },
      },
      tooltip: {
        formatter() {
          if (type === 'duration') {
            return `<div><b>${this.x}</b><br/><b>${secondsToHm(this.y * 60)}</b></div>`;
          }

          return `<div><b>${this.x}</b><br/><b>${this.y} ${this.y === 1 ? 'Question' : 'Questions'}</b></div>`;
        },
      },
      type: chartType,
      series: [{
        type: chartType,
        color: '#FFAF5F',
        data: Object.values(graphData).map((data) => {
          return data[type];
        }),
      }],
    });
  }

  getDropDownData(dropdownData) {
    const items = [{ value: 'All', label: 'All' }];
    dropdownData.map((data) => {
      items.push({ value: data, label: data });
    });
    return items;
  }

  handleSelect(date) {
    const diffTime = Math.abs(new Date(date.range1.endDate)
    - new Date(date.range1.startDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays >= 6) {
      this.setState({ selectedRange: { ...date.range1 } });
    } else {
      this.props.showToast('Date Range should be greater than a week');
    }
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  renderData() {
    const { id } = this.props;
    this.setState({ showLoader: true });
    const { selectedRange, selectedObjectMode, selectedTestMode } = this.state;
    const objectValue = selectedObjectMode.value !== 'All' ? selectedObjectMode.value || '' : '';
    const testValue = selectedTestMode.value !== 'All' ? selectedTestMode.value || '' : '';
    this.props.getStudentActivity(id, selectedRange.startDate,
      selectedRange.endDate, objectValue, testValue).then(() => {
      const { startDate } = this.props;
      this.setState({
        startDate: new Date(startDate),
        showLoader: false,
      });
    });
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, isClearable = false) {
    const length = selectedItem.value ? (selectedItem.value.length * 7) + 120 : 150;
    const selectBoxStyle = getDropDownStyle(length);
    const boxWidth = { width: 'fit-content', marginRight: 20 };

    return (
      <div style={boxWidth}>
        <Select
          value={selectedItem.value && selectedItem}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          styles={selectBoxStyle}
          isClearable={isClearable}
          isSearchable
        />
      </div>
    );
  }

  renderDateRangePicker() {
    const { selectedRange, showModal } = this.state;
    return (
      <Modal showModal={showModal} handleCloseModal={this.handleCloseModal} top="25%">
        <div style={{
          display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <DateRange
            showSelectionPreview={false}
            selectedRange={{ ...selectedRange }}
            color="#1c3e6b"
            maxDate={new Date()}
            showPreview={false}
            handleSelect={this.handleSelect}
          />
          <div
            className="primary-button"
            style={{
              background: '#2B4A73', margin: 0, userSelect: 'none', width: 150, marginTop: 30, marginBottom: 20,
            }}
            role="presentation"
            onClick={() => this.setState({ showModal: false }, () => {
              this.renderData();
            })}
          >
            Set Date Range
          </div>
        </div>
      </Modal>
    );
  }

  renderActivityChart(data) {
    return (
      <div
        className="Simple-Card-View Header-content"
        style={{
          display: 'flex', flexDirection: 'column', padding: 0, width: '100%', backgroundColor: StyleConstants.color.white, marginTop: 5, marginBottom: 30, justifyContent: 'center', boxShadow: '0px 0px 12px rgba(65, 61, 74, 0.1)',
        }}
      >
        <div style={{ padding: '20px 0px 0px 20px', display: 'flex', flexDirection: 'column' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={data}
            style={{ zIndex: 0 }}
          />
        </div>
      </div>
    );
  }

  renderSelectedDate() {
    return (
      <div
        style={{
          display: 'flex', justifyContent: 'flex-start', marginBottom: 30, paddingBottom: 5, borderBottom: '1px solid #454545', width: 'fit-content',
        }}
      >
        {this.renderDateStatus()}
      </div>
    );
  }

  renderDateStatus() {
    const { selectedRange } = this.state;
    return <DateStatus selectedRange={selectedRange} />;
  }

  render() {
    const { showLoader, selectedObjectMode, selectedTestMode } = this.state;
    const {
      activityData, questionCount, testModes, objectTypes, student, institute, subscriptionType,
    } = this.props;
    const durationData = this.setOptions(activityData, 'duration');
    const countData = this.setOptions(activityData, 'question_count');
    const testModesData = this.getDropDownData(testModes || []);
    const objectTypesData = this.getDropDownData(objectTypes || []);
    if (showLoader) {
      return <Loader />;
    }
    return (
      <div style={{
        width: '80%', padding: 80, marginLeft: '20%',
      }}
      >
        <table
          className="table table-bordered"
          style={{ textAlign: 'center', marginBottom: 30 }}
        >
          <thead>
            <tr style={{ fontWeight: 'bold' }}>
              <td>ID</td>
              <td>NAME</td>
              <td>PHONE</td>
              <td>EMAIL</td>
              <td>INSTITUTE</td>
              <td>DATE JOINED</td>
              <td>SUBSCRIPTION TYPE</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{student.id}</td>
              <td>{student.first_name || '-'}</td>
              <td>{student.phone || '-'}</td>
              <td>{student.email || '-'}</td>
              <td>{institute.name || '-'}</td>
              <td><LocaleDate isDay date={new Date(student.created_at)} /></td>
              <td>{subscriptionType.replace('Subscription', '') || '-'}</td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            margin: 0, marginLeft: 'auto', userSelect: 'none', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
          }}
        >
          <div>
            {this.renderDropDown(objectTypesData, selectedObjectMode,
              (selectedItem) => this.setState({ selectedObjectMode: selectedItem || {} },
                () => this.renderData()),
              'Select Type')}
          </div>
          <div style={{ opacity: selectedObjectMode.label === 'TEST' ? 1 : 0 }}>
            {this.renderDropDown(testModesData, selectedTestMode,
              (selectedItem) => this.setState({ selectedTestMode: selectedItem || {} },
                () => this.renderData()),
              'Select Type')}
          </div>
          <div
            role="presentation"
            className="primary-button"
            style={{
              background: '#2B4A73', margin: 0, width: 200,
            }}
            onClick={() => this.setState({ showModal: true })}
          >
            Date Range
          </div>
        </div>
        {this.renderSelectedDate()}
        <div style={{ fontSize: 22 }}>{this.renderDateRangePicker()}</div>
        {questionCount
          ? (
            <div>
              <div style={{ fontSize: 20, fontWeight: 'bold' }}>Time Spent</div>
              {this.renderActivityChart(durationData)}
              <div style={{ fontSize: 20, fontWeight: 'bold' }}>Questions Attempted</div>
              {this.renderActivityChart(countData)}
            </div>
          )
          : (
            <div style={{
              width: '100%', height: '100%', display: 'flex', fontSize: 22, marginTop: 150, justifyContent: 'center',
            }}
            >
              <div style={{ marginRight: 5 }}>Student is not Active</div>
              <div>{this.renderDateStatus()}</div>
            </div>
          )}
      </div>
    );
  }
}

StudentInsights.propTypes = {
  activityData: PropTypes.object,
  startDate: PropTypes.string,
  id: PropTypes.number,
  questionCount: PropTypes.number,
  student: PropTypes.object,
  institute: PropTypes.object,
  objectTypes: PropTypes.array,
  testModes: PropTypes.array,
  subscriptionType: PropTypes.string,
};

StudentInsights.defaultProps = {
  activityData: {},
  startDate: {},
  questionCount: 0,
  objectTypes: [],
  student: {},
  testModes: [],
  subscriptionType: '',
  institute: {},
  id: 0,
};

const mapStateToProps = ({ studentProgress }) => ({
  activityData: studentProgress.activityData,
  startDate: studentProgress.startDate,
  questionCount: studentProgress.questionCount,
  testModes: studentProgress.testModes,
  student: studentProgress.student,
  objectTypes: studentProgress.objectTypes,
  institute: studentProgress.institute,
  subscriptionType: studentProgress.subscriptionType,
});


export default connect(mapStateToProps, {
  getStudentActivity, showToast,
})(StudentInsights);
