import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { secondsToHm, sortByKey } from '../../helpers/Utils';
import {
  Loader, LocaleDate, DateRange, Modal, DateStatus,
} from '../common';
import { getDropDownStyle } from '../../shared/constants/textConstants';
import { showToast } from '../../actions/global';
import { getStudentInsightsData } from '../../actions/studentProgress';
import search from '../../shared/images/search.svg';
import closeMark from '../../shared/images/close-mark.svg';

const endingDate = new Date();
endingDate.setMonth(new Date().getMonth() - 1);

const SELECTED_RANGE = {
  startDate: endingDate,
  endDate: new Date(),
  key: 'selection',
};
const ORDER_BY = {
  UP: 'desc',
  DOWN: 'asc',
};
const itemsCountPerPage = 10;
class StudentInsights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscription: { value: 'All', label: 'All' },
      showLoader: true,
      showModal: false,
      order: { created_at: 'desc' },
      selectedRange: SELECTED_RANGE,
      page: 1,
      searchString: '',
      students: [],
      selectedInstitute: {},
    };
    this.renderPagination = this.renderPagination.bind(this);
    this.orderByValue = this.orderByValue.bind(this);
    this.renderSearch = this.renderSearch.bind(this);
    this.getData = this.getData.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
    this.searchStringChange = this.searchStringChange.bind(this);
    this.renderDateRangePicker = this.renderDateRangePicker.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const {
      selectedInstitute, selectedRange,
    } = this.state;
    this.setState({ showLoader: true, page: 1 }, () => {
      this.props.getStudentInsightsData(
        selectedInstitute, selectedRange,
      ).then(() => {
        const { defaultSelectedInstitute, students } = this.props;
        this.setState({ selectedInstitute: defaultSelectedInstitute, students }, () => {
          this.setState({ showLoader: false });
        });
      });
    });
  }

  setTypeData(students, type, data) {
    const order = {};
    order[type] = data;
    const studentData = sortByKey(students, type, data);
    this.setState({ order, students: studentData, page: 1 });
  }

  orderByValue(filteredData, type) {
    const { order } = this.state;
    return (
      <div style={{
        marginLeft: 5, fontSize: 8, cursor: 'pointer', marginTop: 5,
      }}
      >
        <div
          role="presentation"
          style={{ color: order[type] === ORDER_BY.UP ? '#112F56' : '#8E8787' }}
          onClick={order[type] === ORDER_BY.UP ? () => {
            this.setTypeData(filteredData, type, '');
          } : () => {
            this.setTypeData(filteredData, type, ORDER_BY.UP);
          }}
        >
          &#x25B2;
        </div>
        <div
          role="presentation"
          style={{ color: order[type] === ORDER_BY.DOWN ? '#112F56' : '#8E8787' }}
          onClick={order[type] === ORDER_BY.DOWN ? () => {
            this.setTypeData(filteredData, type, '');
          } : () => {
            this.setTypeData(filteredData, type, ORDER_BY.DOWN);
          }}
        >
          &#x25BC;
        </div>
      </div>
    );
  }

  searchStringChange(e) {
    this.setState({ searchString: e.target.value ? e.target.value.toLowerCase() : '', page: 1 });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleSelect(date) {
    const diffTime = Math.abs(new Date(date.selection.endDate)
    - new Date(date.selection.startDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 62) {
      this.props.showToast('Date Range should be within 2 months');
      this.setState({ selectedRange: SELECTED_RANGE });
    } else {
      this.setState({ selectedRange: { ...date.selection } });
    }
  }

  renderDateStatus() {
    const { selectedRange } = this.state;
    return <DateStatus selectedRange={selectedRange} />;
  }

  renderDropDown(filterItems, selectedItem, selectedFunction, item, isClearable = false) {
    const name = selectedItem.name ? selectedItem.name : item;
    const length = (name.length * 8);
    const selectBoxStyle = getDropDownStyle(length);
    const boxWidth = { width: 'fit-content', marginLeft: 20 };
    return (
      <div style={boxWidth}>
        <Select
          value={selectedItem.value && selectedItem}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          styles={selectBoxStyle}
          isClearable={isClearable}
          isSearchable
        />
      </div>
    );
  }

  renderSearch() {
    const { searchString, selectedSubscription, selectedInstitute } = this.state;
    const { subscriptionTypes, instituteData } = this.props;
    const subscriptionTypeData = [];
    Object.keys(subscriptionTypes).map((type) => {
      subscriptionTypeData.push({ value: type, label: subscriptionTypes[type] });
    });
    const searchInProgress = searchString.length > 0;
    return (
      <div style={{
        display: 'flex', fontSize: 16, marginBottom: 30, justifyContent: 'space-between',
      }}
      >
        <div style={{
          display: 'flex', justifyContent: 'space-between', width: 400, border: '1px solid #9EA0A5', borderRadius: 4, height: 40,
        }}
        >
          <div style={{
            padding: 15, display: 'flex', alignItems: 'center',
          }}
          >
            <img alt="" src={search} width="16" height="16" />
          </div>
          <div style={{ width: 350, display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <input
                className="input-field"
                id="Search"
                onChange={this.searchStringChange}
                type="text"
                placeholder="Search students by name or phone number"
                value={searchString}
                style={{
                  width: '100%', border: 'none', marginBottom: 0, height: 35, textAlignLast: 'left', paddingLeft: 20,
                }}
                autoFocus
              />
            </div>
            <div
              role="presentation"
              style={{
                width: 25, display: (searchInProgress) ? 'flex' : 'none', alignItems: 'center', cursor: 'pointer', padding: 15, placeContent: 'center',
              }}
              onClick={() => this.setState({ searchString: '', page: 1 })}
            >
              <img alt="" src={closeMark} width="14" height="14" />
            </div>
          </div>
        </div>
        {this.renderDateRangePicker()}
        <div style={{ display: 'flex' }}>
          {this.renderDropDown(instituteData, selectedInstitute, (selectedItem) => {
            this.setState({ selectedInstitute: selectedItem, order: { created_at: 'desc' } }, () => {
              this.getData();
            });
          }, 'Select Institute')}
          {this.renderDropDown(subscriptionTypeData, selectedSubscription, (selectedItem) => { this.setState({ selectedSubscription: selectedItem || {}, page: 1 }); }, 'Subscription Status')}
          <div
            className="primary-button"
            style={{
              background: '#2B4A73', margin: 0, marginLeft: 20, userSelect: 'none',
            }}
            role="presentation"
            onClick={() => this.setState({ showModal: true })}
          >
            Select Date Range
          </div>
        </div>
      </div>
    );
  }

  renderDateRangePicker() {
    const { selectedRange, showModal } = this.state;
    return (
      <Modal showModal={showModal} handleCloseModal={this.handleCloseModal} top="25%">
        <div style={{
          display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center',
        }}
        >
          <DateRange
            showSelectionPreview={false}
            selectedRange={{ ...selectedRange }}
            color="#1c3e6b"
            maxDate={new Date()}
            showPreview={false}
            handleSelect={this.handleSelect}
          />
          <div
            className="primary-button"
            style={{
              background: '#2B4A73', margin: 0, userSelect: 'none', width: 150, marginTop: 30, marginBottom: 20,
            }}
            role="presentation"
            onClick={() => this.setState({ showModal: false }, () => {
              this.getData();
            })}
          >
            Set Date Range
          </div>
        </div>
      </Modal>
    );
  }

  renderPagination(students) {
    const { page } = this.state;
    const studentsCount = students ? students.length : 0;
    return (
      <div
        style={{
          width: '100%', fontSize: 16, display: 'flex', placeContent: 'center', marginTop: 30,
        }}
      >
        <Pagination
          activePage={parseInt(page, 10)}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={studentsCount}
          pageRangeDisplayed={5}
          firstPageText=""
          lastPageText=""
          onChange={(pageNum) => this.setState({ page: pageNum })}
        />
      </div>
    );
  }

  render() {
    const {
      showLoader, page, selectedSubscription, searchString, students,
    } = this.state;
    const filteredData = students.filter((student) => {
      return (!selectedSubscription.label || selectedSubscription.label === 'All'
        || selectedSubscription.label === student.subscription_type)
        && (!searchString || !searchString.length
          || ((student.first_name ? student.first_name.toLowerCase() : '').search(searchString) !== -1
          || (student.phone || '').search(searchString) !== -1));
    });
    const studentsCount = filteredData ? filteredData.length : 0;
    const minCount = page >= 2 ? (page - 1) * itemsCountPerPage : 0;
    const maxCount = (page) * itemsCountPerPage;
    const tableStyle = {
      verticalAlign: 'top', paddingTop: 20, paddingLeft: 20, textAlignLast: 'left', borderRight: '1px solid #DFE3EA', fontSize: 20,
    };
    const dataStyle = { textAlignLast: 'left', paddingLeft: 20 };
    if (showLoader) {
      return <Loader />;
    }
    if (studentsCount === 0) {
      return (
        <div
          style={{
            width: '80%', textAlignLast: 'center', fontSize: 20, marginLeft: '20%', padding: '30px 80px',
          }}
        >
          {this.renderSearch()}
          <div
            style={{
              display: 'flex', justifyContent: 'flex-start', marginBottom: 30, paddingBottom: 5, borderBottom: '1px solid #454545', width: 'fit-content',
            }}
          >
            {this.renderDateStatus()}
          </div>
          <div style={{ paddingTop: 100 }}>No Students For this Institute</div>
        </div>
      );
    }
    return (
      <div style={{ width: '100%' }}>
        <div
          style={{
            width: '80%', textAlignLast: 'center', marginTop: 60, fontSize: 18, marginLeft: '20%', padding: '40px 80px',
          }}
        >
          {this.renderSearch()}
          <div style={{
            display: 'flex', justifyContent: 'flex-start', marginBottom: 30, paddingBottom: 5, borderBottom: '1px solid #454545', width: 'fit-content',
          }}
          >
            {this.renderDateStatus()}
          </div>
          <table
            className="table table-hover"
            style={{
              boxShadow: '1px 2px 6px #8B9DAF33', borderRadius: 4, backgroundColor: '#FFFFFF', border: '1px solid #CDCFD6',
            }}
          >
            <thead style={{ background: '#F6F8FA', color: '#23394C' }}>
              <tr>
                <th width="5%" style={{ ...tableStyle }}>ID</th>
                <th width="25%" style={{ ...tableStyle }}>Name</th>
                <th width="20%" style={{ ...tableStyle }}>Phone</th>
                <th width="15%" style={{ ...tableStyle }}>
                  <div
                    style={{
                      display: 'flex', justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ textAlignLast: 'left' }}>Date Joined</div>
                    {this.orderByValue(filteredData, 'created_at')}
                  </div>
                </th>
                <th width="5%" style={{ ...tableStyle }}>
                  <div
                    style={{
                      display: 'flex', justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ textAlignLast: 'left' }}>Score</div>
                    {this.orderByValue(filteredData, 'engagement_score')}
                  </div>
                </th>
                <th width="5%" style={{ ...tableStyle }}>
                  <div
                    style={{
                      display: 'flex', justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ textAlignLast: 'left' }}>Question Count</div>
                    {this.orderByValue(filteredData, 'question_count')}
                  </div>
                </th>
                <th width="20%" style={{ ...tableStyle }}>
                  <div
                    style={{
                      display: 'flex', justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ textAlignLast: 'left' }}>Time Spent</div>
                    {this.orderByValue(filteredData, 'time_spent')}
                  </div>
                </th>
                <th width="15%" style={{ ...tableStyle }}>Subscription</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 16 }}>
              {filteredData.slice(minCount, maxCount).map((student) => {
                return (
                  <tr>
                    <td width="5%" style={{ ...dataStyle }}>{student.id || '-'}</td>
                    <td
                      role="presentation"
                      width="25%"
                      style={{ ...dataStyle, cursor: 'pointer' }}
                      onClick={() => { window.open(`/student/activity/${student.id}`); }}
                    >
                      {student.first_name || '-'}
                    </td>
                    <td width="20%" style={{ ...dataStyle }}>{student.phone ? student.phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '-'}</td>
                    <td width="15%" style={{ ...dataStyle }}><LocaleDate date={new Date(student.created_at)} /></td>
                    <td width="5%" style={{ ...dataStyle }}>{student.engagement_score ? parseInt(student.engagement_score, 10) : 0}</td>
                    <td width="5%" style={{ ...dataStyle }}>{student.question_count || 0}</td>
                    <td width="20%" style={{ ...dataStyle }}>{student.time_spent > 0 ? secondsToHm(student.time_spent) || '-' : '-'}</td>
                    <td width="10%" style={{ ...dataStyle }}>{student.subscription_type.replace('Subscription', '') || '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {studentsCount > itemsCountPerPage && this.renderPagination(filteredData)}
        </div>
      </div>
    );
  }
}

StudentInsights.propTypes = {
  students: PropTypes.array,
  subscriptionTypes: PropTypes.object,
  defaultSelectedInstitute: PropTypes.object,
  instituteData: PropTypes.array,
};

StudentInsights.defaultProps = {
  students: [],
  subscriptionTypes: {},
  defaultSelectedInstitute: {},
  instituteData: [],
};

const mapStateToProps = ({ studentProgress }) => ({
  students: studentProgress.students,
  subscriptionTypes: studentProgress.subscriptionTypes,
  instituteData: studentProgress.instituteData,
  defaultSelectedInstitute: studentProgress.selectedInstitute,
});

export default connect(mapStateToProps, {
  getStudentInsightsData, showToast,
})(StudentInsights);
