import {
  UPDATE_LOADER_STATE, SERVER_ERROR, HIDE_TOAST, SHOW_TOAST, SERVER_UNDER_MAINTENANCE,
} from '../actions';
import { TOAST_MESSAGE_TYPES } from '../shared/constants/fieldTypes';

const INITIAL_STATE = {
  isServerError: false,
  isServerUnderMaintenance: false,
  loaderState: false,
  toastMessage: '',
  showToast: false,
  toastMessageType: TOAST_MESSAGE_TYPES.SUCCESS,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOADER_STATE:
      return { ...state, ...action.payload };
    case SERVER_UNDER_MAINTENANCE:
      return { ...state, isServerUnderMaintenance: true };
    case SERVER_ERROR:
      return { ...state, isServerError: true };
    case SHOW_TOAST:
      return { showToast: true, toastMessageType: action.message };
    case HIDE_TOAST:
      return { showToast: false, toastMessageType: '' };

    default:
      return state;
  }
};
