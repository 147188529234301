import Api from '../helpers/Api';
import { getParentPortalDomain } from '../helpers/Utils';
import { UPDATE_STATUS, TEST_LIST, SAMPLE_ID } from '../shared/constants/textConstants';

export const TEST_OVERVIEW = 'Insight/TEST_OVERVIEW';
export const INSTITUTE_DETAILS = 'Insight/INSTITUTE_DETAILS';
export const USER_DETAILS = 'Insight/USER_DETAILS';
export const TEST_LISTS = 'Insight/TEST_LIST';
export const UPDATE_QUESTION_LIST = 'Insight/UPDATE_QUESTION_LIST';
export const UPDATE_FILTERS_MODEL = 'Insight/UPDATE_FILTERS_MODEL';
export const UPDATE_FILTERS = 'Insight/UPDATE_FILTERS';
export const UPDATE_MENU_MODEL = 'Insight/UPDATE_MENU_MODEL';
export const UPDATE_DATA_MANAGEMENT = 'Insight/UPDATE_DATA_MANAGEMENT';
export const STUDENT_RESULT_UPLOAD_STATUS_DELETE = 'Insight/STUDENT_RESULT_UPLOAD_STATUS_DELETE';
export const UPDATE_DATA_MANAGEMENT_MODAL = 'Insight/UPDATE_DATA_MANAGEMENT_MODAL';
export const SHOW_LOADER = 'Insight/SHOW_LOADER';

export function getTestOverviewData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_test_dashboard',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        name: response.name,
        date: response.date,
        attendance: response.attendance,
        attendancePercentage: response.attendance_percentage,
        rankList: response.rank_list,
        rank: response.rank,
        studentsPerformanceChange: response.students_performance_change,
        scoreDistribution: response.score_distribution,
        campuses: response.campuses,
        isOnline: response.is_online,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        otherTests: response.other_tests,
        medianData: response.median_data,
        completedCountForTest: response.completed_count_for_test,
        sampleTestId: response.sample_test_id,
        campusSelected: response.campus_id,
        campusSelectedLabel: response.campuses[response.campus_id] ? {
          label: response.campuses[response.campus_id].name,
          value: response.campusSelected,
        } : '',
        showLoader: false,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function updateResponseData(id) {
  return () => Api({
    method: 'post',
    url: `/online_tests/${id}/rerun_analysis_for_tests`,
  });
}

export function updateDataManagementModal(isPresent) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({ type: UPDATE_DATA_MANAGEMENT_MODAL, payload: isPresent });
    resolve();
  });
}

export function getTestData(payload) {
  return (dispatch) => Api({
    method: 'get',
    url: `/dashboard/${payload.id}/get_test_data`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: TEST_OVERVIEW,
        payload: {
          id: payload.id,
          name: response.name,
          isOnline: response.is_online,
          testResponses: response.test_responses,
          otherTests: response.other_tests,
          status: response.status,
          showLoader: false,
        },
      });
    }
    return response;
  });
}

export function deleteStudentResultsDataManagemnet(testId, label, index) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DATA_MANAGEMENT,
      payload: {
        id: testId,
        status: UPDATE_STATUS.PROCESS,
      },
    });
    Api({
      method: 'post',
      url: '/tests/delete_offline_test_file',
      data: { id: testId, file_name: label, index },
    }).then((response) => {
      if (response.success) {
        dispatch({
          type: STUDENT_RESULT_UPLOAD_STATUS_DELETE,
          status: response.status,
        });
      }
    });
  };
}

export function uploadStudentResultsDataManagement(file, testId) {
  return (dispatch) => Api({
    method: 'post',
    url: '/tests/reupload_student_result_file',
    attachment: file,
    type: 'spreadsheet',
    data: { test_id: testId },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: UPDATE_DATA_MANAGEMENT,
        payload: {
          id: testId,
          status: UPDATE_STATUS.PROCESS,
        },
      });
      dispatch({
        type: UPDATE_DATA_MANAGEMENT_MODAL,
        payload: false,
      });
    }
  });
}


export function getScoreDistributionData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_score_distribution',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        name: response.name,
        date: response.date,
        scoreDistribution: response.score_distribution,
        otherTests: response.other_tests,
        isOnline: response.is_online,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        campusSelected: response.campus_id,
        campusSelectedLabel: response.campuses[response.campus_id] ? {
          label: response.campuses[response.campus_id].name,
          value: response.campusSelected,
        } : '',
        showLoader: false,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getRankListData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_rank_list_data',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        name: response.name,
        isOnline: response.is_online,
        date: response.date,
        rankList: response.rank_list,
        sampleTestId: response.sample_test_id,
        campuses: response.campuses,
        classrooms: response.classrooms,
        otherTests: response.other_tests,
        subjects: response.subjects,
        tracks: response.tracks,
        campusSelected: response.campus_id,
        campusSelectedLabel: response.campuses[response.campus_id] ? {
          label: response.campuses[response.campus_id].name,
          value: response.campusSelected,
        } : '',
        showLoader: false,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getTestAbsentData(filters) {
  const backendUrl = '/dashboard/get_test_absentees_data';
  const queryParams = Object.keys(filters).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`).join('&');
  return (dispatch) => Api({
    method: 'get',
    url: `${backendUrl}?${queryParams}`,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        name: response.name,
        isOnline: response.is_online,
        date: response.date,
        testAbsentees: response.absentee_list,
        sampleTestId: response.sample_test_id,
        campuses: response.campuses,
        classrooms: response.classrooms,
        otherTests: response.other_tests,
        showLoader: false,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export function getInstituteDetails() {
  return (dispatch) => Api({
    method: 'get',
    url: '/institutes/institute_details',
  }).then((response) => {
    if (response.success) {
      let { picture } = response;
      picture = picture && picture.url ? picture.url : '';
      const payload = {
        instituteName: response.name,
        instituteLogo: picture,
        phone: response.phone,
        address: response.address,
        watermarkText: response.watermark_text,
      };
      dispatch({
        type: INSTITUTE_DETAILS,
        payload,
      });
    }
    return response;
  });
}

export function getQuestionAnalysisData(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_question_analysis_data',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        name: response.name,
        date: response.date,
        majorErrorQuestions: response.major_error_question,
        majorErrorCheckpoints: response.major_error_checkpoint,
        renderMoreQuestions: response.render_more_questions,
        isOnline: response.is_online,
        campuses: response.campuses,
        otherTests: response.other_tests,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        userType: response.user_type,
        campusSelected: response.campus_id,
        campusSelectedLabel: response.campuses[response.campus_id] ? {
          label: response.campuses[response.campus_id].name,
          value: response.campusSelected,
        } : '',
        showLoader: false,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
    return response;
  });
}


export function loadMoreQuestionAnalysisQuestions(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/fetch_question_for_question_analysis',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        majorErrorQuestions: response.major_error_question,
        renderMoreQuestions: response.render_more_questions,
      };
      dispatch({
        type: UPDATE_QUESTION_LIST,
        payload,
      });
    }
    return response;
  });
}

export function getTestComparison(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_tests_comparison',
    data: filters,
  }).then((response) => {
    if (response.success) {
      const payload = {
        id: filters.id,
        testStudentDistribution: response.test_student_distribution,
        campuses: response.campuses,
        classrooms: response.classrooms,
        subjects: response.subjects,
        tracks: response.tracks,
        name: response.name,
        date: response.date,
        isOnline: response.is_online,
        graphRangeValue: response.graph_range_value,
        subjectsMedian: response.subjects_median,
        scoreInterval: response.score_interval,
        otherTests: response.other_tests,
        maxScore: response.max_score,
        previousTests: response.previous_tests,
        previousTestName: response.previous_test_name,
        currentTestPerformance: response.current_test_performance,
        previousTestPerformance: response.previous_test_performance,
        testMinScore: response.min_test_score,
        campusSelected: response.campus_id,
        campusSelectedLabel: response.campuses[response.campus_id] ? {
          label: response.campuses[response.campus_id].name,
          value: response.campusSelected,
        } : '',
        showLoader: false,
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    } else if (!response.success) {
      dispatch({
        type: TEST_OVERVIEW,
        payload: {
          pageError: true,
          showLoader: false,
          campuses: response.campuses,
          classrooms: response.classrooms,
          subjects: response.subjects,
          tracks: response.tracks,
          name: response.name,
        },
      });
    }
  });
}

export function getTrackComparison(filters) {
  return (dispatch) => Api({
    method: 'post',
    url: '/dashboard/get_track_comparison',
    data: filters,
  }).then((response) => {
    if (response) {
      const payload = {
        id: filters.id,
        subjectTrack1Comparison: response.subject_track1_comparison,
        subjectTrack2Comparison: response.subject_track2_comparison,
        track1PerformanceComparison: response.track1_performance_comparison,
        track2PerformanceComparison: response.track2_performance_comparison,
        trackSetter: response.track_setter,
        campuses: response.campuses,
        classrooms: response.classrooms,
        isOnline: response.is_online,
        subjects: response.subjects,
        subjectSelected: response.subject_id,
        tracks: response.tracks,
        otherTests: response.other_tests,
        name: response.name,
        date: response.date,
        showLoader: false,
        subjectSelectedLabel: response.subjects[response.subject_id] ? {
          label: response.subjects[response.subject_id].name,
          value: response.subjectSelected,
        } : '',
        campusSelected: response.campus_id,
        campusSelectedLabel: response.campuses[response.campus_id] ? {
          label: response.campuses[response.campus_id].name,
          value: response.campusSelected,
        } : '',
      };
      dispatch({
        type: TEST_OVERVIEW,
        payload,
      });
    }
    return response;
  });
}

export const updateFilterModel = (state, type) => async (dispatch, getState) => {
  if (type === TEST_LIST) {
    const { insight: { id } } = getState();
    dispatch({ type: SHOW_LOADER });
    Api({
      method: 'post',
      url: '/dashboard/get_tests',
      data: { id },
    }).then((response) => {
      if (response.success) {
        const payload = {
          id,
          testList: response.test_list,
          state,
          type,
          showLoader: false,
        };
        dispatch({
          type: TEST_LISTS,
          payload,
        });
      }
      return response;
    });
  }
  dispatch({ type: UPDATE_FILTERS_MODEL, payload: { state, type } });
};

export const updateFilterData = (type, value) => async (dispatch) => {
  const payload = { type, value };
  await dispatch({ type: UPDATE_FILTERS, payload });
};

export const enableLoader = () => async (dispatch) => {
  await dispatch({ type: SHOW_LOADER });
};

export const updateMenuModel = () => async (dispatch) => {
  await dispatch({ type: UPDATE_MENU_MODEL });
};
