import React from 'react';
import { connect } from 'react-redux';
import '../../node_modules/react-vis/dist/style.css';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Header, SideBar, TestHeader, Loader, QuestionView, Filter,
} from './common';
import { isMobileDevice } from '../helpers/Utils';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import {
  SIDE_BAR_ITEMS,
} from '../shared/constants/fieldTypes';
import {
  getQuestionAnalysisData, enableLoader,
} from '../actions';
import {
  DIFFICULTY_VALUE_MAPPING,
  COMPLEXITY_COLOR,
} from '../shared/constants/textConstants';

const ERROR_MAPPING = ['QUESTIONS', 'TOPICS'];
const IS_MOBILE = isMobileDevice();
const pageStyle = {
  height: global.innerHeight,
  overflow: 'auto',
  overflowX: 'hidden',
  marginTop: StyleConstants.headerHeight.web,
};
class ErrorAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      itemsCountPerPage: 20,
      displayScroll: false,
      stop: 0,
      id: props.match.params.id,
      display: ERROR_MAPPING[0],
    };
    this.updateResults = this.updateResults.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.renderTopics = this.renderTopics.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.displaSetUp = this.displaSetUp.bind(this);
    this.trackScroll = this.trackScroll.bind(this);
    window.onscroll = this.trackScroll;
  }

  componentDidMount() {
    this.getQuestions();
  }

  getQuestions() {
    const {
      campusSelected, classroomSelected,
      subjectSelected, getQuestionAnalysisData, enableLoader,
    } = this.props;
    const { id, itemsCountPerPage, activePage } = this.state;
    const payload = {
      id,
      campus_id: campusSelected ? campusSelected.value : '',
      classroom_id: classroomSelected ? classroomSelected.value : '',
      subject_id: subjectSelected || '',
      page: activePage,
      per_page: itemsCountPerPage,
    };
    enableLoader();
    getQuestionAnalysisData(payload);
  }

  trackScroll() {
    const { stop } = this.state;
    const wrappedElement = document.getElementById('data-30');
    if (window.pageYOffset === 0 && stop === 1) {
      this.displaSetUp(false, 0);
    } else if (wrappedElement && window.pageYOffset > 60 && stop === 0) {
      this.displaSetUp(true, 1);
    }
  }

  displaSetUp(x, y) {
    this.setState({ displayScroll: x, stop: y });
  }


  fetchMoreData() {
    const { itemsCountPerPage } = this.state;
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        itemsCountPerPage: itemsCountPerPage + 20,
      });
    }, 100);
  }


  updateResults(payload) {
    const { id } = this.state;
    const { getQuestionAnalysisData, enableLoader } = this.props;
    if (payload.id && payload.id !== id) {
      window.location.href = `/error_analysis/${payload.id}`;
    }
    enableLoader();
    getQuestionAnalysisData(payload);
  }

  renderMarthjax() {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }


  renderQuestionDifficulty(difficulty) {
    if (!difficulty) {
      return '';
    }
    return DIFFICULTY_VALUE_MAPPING[parseInt(difficulty, 10)];
  }

  renderQuestions(data, index, minCount) {
    const { majorErrorCheckpoints } = this.props;
    return (
      <tr id={`data-${parseInt(index, 10) + parseInt(minCount, 10)}`}>
        <td style={{ width: '15%', textAlign: 'center', borderRightColor: StyleConstants.color.white }}>
          <p
            className="error-color"
            style={{
              whiteSpace: 'nowrap', fontWeight: 'bold', fontSize: StyleConstants.textSize.subHeader,
            }}
          >
            <span>
              {data.responses - data.correct_count}
              {' '}
            </span>
            <span style={{ marginBottom: 5 }}>
              (
              {' '}
              {parseInt((((data.responses - data.correct_count) / data.responses) * 100), 10)}
              % )
            </span>
          </p>
        </td>
        {Object.keys(majorErrorCheckpoints).length > 0 && (
        <td style={{ width: '10%', textAlign: 'center', borderRightColor: StyleConstants.color.white }}>
          <span style={{ fontSize: StyleConstants.textSize.text }}>
            {this.renderQuestionDifficulty(data.complexity ? data.complexity : '')}
            {' '}
          </span>
        </td>
        )}
        <td style={{ padding: 0, borderRightColor: StyleConstants.color.white }}>
          <div>
            <QuestionView currentQuestion={data} fontSize={14} showAnswer showSolution printPage={false} currentIndex="" mobileView={IS_MOBILE} width="60%" />
          </div>
        </td>
        {Object.keys(majorErrorCheckpoints).length > 0
        && (
        <td>
          <div style={{ marginTop: 10 }}>
            {' '}
            {data.chapter_name}
          </div>
          <div style={{ marginTop: 10 }}>
            (
            {' '}
            {data.checkpoint_name}
            {' '}
            )
          </div>
        </td>
        )}
      </tr>
    );
  }

  renderTopics(data, index) {
    return (
      <tr key={`${data.id}_${index}`}>
        <td style={{
          padding: 15, width: '15%', textAlign: 'center', textAlignLast: 'left',
        }}
        >
          <div
            className="error-color"
            style={{
              whiteSpace: 'nowrap', marginLeft: 40, fontWeight: 'bold',
            }}
          >
            <span style={{ marginBottom: 5, fontSize: StyleConstants.textSize.subHeader }}>
              {parseInt(
                (((data.responses - data.correct_count) / data.responses) * 100), 10,
              )}
              %
              {' '}
            </span>
          </div>
        </td>
        <td style={{ padding: 15, whiteSpace: 'nowrap' }}>
          <div>{data.question_count}</div>
        </td>
        <td style={{ padding: 15 }}>
          <div>{data.name}</div>
        </td>
        <td style={{ padding: 15 }}>
          <div>{data.chapter_name}</div>
        </td>
      </tr>
    );
  }

  renderErrorAnalysisTableForQuestions(majorErrorQuestions) {
    const { itemsCountPerPage, activePage } = this.state;
    const { majorErrorCheckpoints } = this.props;
    const minCount = activePage >= 2 ? (activePage - 1) * itemsCountPerPage : 0;
    const maxCount = (activePage) * itemsCountPerPage;
    return (
      <InfiniteScroll
        dataLength={itemsCountPerPage}
        next={this.fetchMoreData}
        hasMore
      >
        <table className="table table-striped ">
          <thead>
            <tr>
              <th style={{
                width: '15%', textAlign: 'center', fontSize: 15, borderRightColor: '#fff',
              }}
              >
                ERROR COUNT
              </th>
              {Object.keys(majorErrorCheckpoints).length > 0 && (
              <th style={{
                width: '10%', textAlign: 'center', fontSize: 15, borderRightColor: '#fff',
              }}
              >
                DIFFICULTY
              </th>
              )}
              <th style={{ borderRightColor: '#fff' }}> QUESTION </th>
              { Object.keys(majorErrorCheckpoints).length > 0 && <th>TOPIC</th>}
            </tr>
          </thead>
          <tbody>
            {majorErrorQuestions.slice(minCount, maxCount).map((data, index) => (
              this.renderQuestions(data, index, minCount)))}
          </tbody>
        </table>
      </InfiniteScroll>
    );
  }

  renderErrorAnalysisTableForTopics(majorErrorTopics) {
    if (majorErrorTopics.length > 0) {
      return (
        <table className="table table-striped ">
          <thead>
            <tr>
              <th style={{ paddingLeft: 50, whiteSpace: 'nowrap' }}> ERROR COUNT</th>
              <th style={{ whiteSpace: 'nowrap' }}> QUESTION COUNT </th>
              <th> TOPIC </th>
              <th> CHAPTER </th>
            </tr>
          </thead>
          <tbody>
            {majorErrorTopics.map((data, index) => (this.renderTopics(data, index)))}
          </tbody>
        </table>
      );
    }
    return <div />;
  }

  render() {
    const {
      isFilter, name, date, majorErrorQuestions, majorErrorCheckpoints,
      campusSelected, campuses, institute, classrooms, subjects,
      classroomSelected, subjectSelected, showLoader,
    } = this.props;
    const {
      display,
    } = this.state;
    const { id } = this.state;
    const errorsPresent = Object.keys(majorErrorQuestions).length > 0;
    if (showLoader) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.ERROR_ANALYSIS} />
          <Filter updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.ERROR_ANALYSIS} testId={id} />
        </div>
      );
    }
    if (errorsPresent) {
      this.renderMarthjax();
    }
    if (IS_MOBILE) {
      this.renderMarthjax();
      return (
        <div style={{ userSelect: 'none', padding: '60px 0', overflow: 'hidden' }}>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.ERROR_ANALYSIS} />
          <TestHeader testName={name} testDate={date} />
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="ERROR_ANALYSIS" testId={id} />
          {errorsPresent
            ? (
              <div style={{ padding: '0px 20px 50px' }}>
                <div style={{ display: 'flex', marginBottom: 15, marginTop: 15 }}>
                  <div style={{
                    background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '5px 10px', width: 'fit-content', fontSize: 12, borderRadius: 4, marginRight: 5,
                  }}
                  >
                    {campusSelected && campuses[campusSelected] ? campuses[campusSelected].name : 'All campuses'}
                  </div>
                  <div style={{
                    background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '5px 10px', width: 'fit-content', fontSize: 12, borderRadius: 4,
                  }}
                  >
                    {classroomSelected && classrooms[classroomSelected] ? classrooms[classroomSelected].name : 'All classrooms'}
                  </div>
                </div>
                {majorErrorQuestions.map((data, index) => (
                  <div className="Card-View" style={{ marginTop: 2, paddingTop: 1, textAlign: 'unset' }}>
                    <div style={{
                      whiteSpace: 'nowrap', color: StyleConstants.color.error, marginLeft: 25, fontSize: StyleConstants.textSize.text, display: 'flex', alignItems: 'center', marginTop: 15,
                    }}
                    >
                      <span>
                        {data.responses - data.correct_count}
                        {' '}
                      </span>
                      <span>
                        (
                        {' '}
                        {parseInt(
                          (((data.responses - data.correct_count) / data.responses) * 100), 10,
                        )}
                        % )
                      </span>
                      <div style={{
                        marginLeft: 5, height: 18, width: 18, background: COMPLEXITY_COLOR[data.complexity], borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: 10, color: StyleConstants.color.white,
                      }}
                      >
                        {this.renderQuestionDifficulty(data.complexity)[0]}

                      </div>
                    </div>
                    <div style={{
                      display: 'flex', fontWeight: 'regular', fontFamily: 'nunito', fontSize: StyleConstants.textSize.text, padding: 5,
                    }}
                    >
                      <div style={{
                        marginTop: 10, marginLeft: 8, marginRight: 10, width: 25, textAlignLast: 'center', padding: 0, borderRightColor: '#fff',
                      }}
                      >
                        {index + 1}
                        .
                      </div>
                      <QuestionView currentQuestion={data} fontSize={14} showAnswer showSolution printPage={false} currentIndex="" mobileView={IS_MOBILE} width="60%" />
                    </div>
                  </div>
                ))}
              </div>
            )
            : <div style={{ textAlign: 'center', marginTop: '20%' }}>No Errors Found On This Test</div>}
        </div>
      );
    }
    return (
      <div style={{ userSelect: 'none' }}>
        <div className="hide-in-print" style={pageStyle}>
          <div>
            <div className="row" style={{ backgroundColor: '#F5F8FA' }}>
              <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
                <Header
                  testId={id}
                  updateResults={this.updateResults}
                  menuData={SIDE_BAR_ITEMS.ERROR_ANALYSIS}
                />
                <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="ERROR_ANALYSIS" testId={id} />
              </div>
              <div style={{ width: '80%', paddingBottom: 100, padding: '0 0 100px 0' }}>
                {errorsPresent
                  ? (
                    <div style={{ padding: '0px 62px', margin: '0 15px 10px 0' }}>
                      <div style={{
                        width: 'fit-content', padding: 30, marginTop: 40, backgroundColor: StyleConstants.color.white, boxShadow: '0px 3px 6px #00000014',
                      }}
                      >
                        <div className="d-flex justify-content-between" style={{ alignItems: 'center' }}>
                          <div style={{
                            display: 'flex', fontSize: StyleConstants.textSize.text, boxShadow: '0px 3px 6px #00000014', border: '1px solid #40A7FF', borderRadius: 4,
                          }}
                          >
                            {Object.keys(majorErrorCheckpoints).length > 0 && ERROR_MAPPING.map((type) => (
                              <div
                                style={{
                                  color: type === display ? StyleConstants.color.white : '#40A7FF', backgroundColor: type === display ? '#40A7FF' : 'transparent', padding: 10, width: 120, textAlignLast: 'center', cursor: 'pointer',
                                }}
                                role="presentation"
                                onClick={() => this.setState({ display: type }, () => {
                                  this.renderMarthjax();
                                })}
                              >
                                {type.charAt(0) + type.slice(1).toLowerCase()}
                              </div>
                            ))}
                          </div>
                          <div className="row">
                            <div style={{ marginBottom: 0 }}>
                              <span
                                onClick={() => window.print()}
                                role="presentation"
                                style={{
                                  cursor: 'pointer', fontSize: StyleConstants.textSize.text, fontWeight: 'bold', color: '#40A7FF', padding: 6, borderRadius: 6, border: '1px solid #40A7FF', margin: 0,
                                }}
                              >
                                Download PDF

                              </span>
                            </div>
                          </div>
                        </div>
                        <div style={{
                          margin: '20px 0px 0px 0px', elevation: 10, borderRadius: 5, border: '1px solid #F0F0F0', height: '100%',
                        }}
                        >
                          {display === ERROR_MAPPING[0]
                            ? this.renderErrorAnalysisTableForQuestions(majorErrorQuestions)
                            : this.renderErrorAnalysisTableForTopics(majorErrorCheckpoints)}
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div style={{
                      whiteSpace: 'nowrap', fontSize: StyleConstants.textSize.primaryHeader, textAlign: 'center', marginTop: '20%',
                    }}
                    >
                      No Errors For This Filter

                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="printable page"
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <div style={{ width: '100%', alignItems: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {institute.instituteLogo
              && (
              <div style={{ background: '#EDEDED' }}>
                <img
                  src={institute.instituteLogo}
                  alt={institute.instituteName}
                  style={{
                    display: 'block', width: 'auto', height: 'auto', objectFit: 'contain', maxWidth: '100%', maxHeight: 120, marginLeft: 30,
                  }}
                />
              </div>
              )}
              <div>
                {campusSelected && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: 22, fontWeight: 'bold' }}>Campus:&nbsp;</div>
                    <div style={{ fontSize: 20 }}>
                      {' '}
                      {campuses[campusSelected].name}
                    </div>
                  </div>
                )}
                {classroomSelected && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: 22, fontWeight: 'bold' }}>Classroom:&nbsp;</div>
                    <div style={{ fontSize: 20 }}>{classrooms[classroomSelected].name}</div>
                  </div>
                )}
                {subjectSelected && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ fontSize: 22, fontWeight: 'bold' }}>Subject:&nbsp;</div>
                    <div style={{ fontSize: 20 }}>{subjects[subjectSelected].name}</div>
                  </div>
                )}
              </div>
            </div>
            <div style={{
              textAlign: 'center', fontWeight: 'bold', fontSize: 34, width: '100%', marginBottom: 15,
            }}
            >
              <div style={{ fontSize: 34 }}>{institute.instituteName}</div>
              <div style={{ fontSize: StyleConstants.textSize.primaryHeader }}>{name}</div>
              <div style={{ fontSize: 22 }}>{date}</div>
            </div>
          </div>
          {display === ERROR_MAPPING[0]
            ? this.renderErrorAnalysisTableForQuestions(majorErrorQuestions)
            : this.renderErrorAnalysisTableForTopics(majorErrorCheckpoints)}
          <style jsx>
            {`
             .printable {
              display: none;
           }
           .error-color {
             color: #FF5F5F;
           }
                body {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
              }
              table.print-friendly tr td, table.print-friendly tr th {
                page-break-inside: avoid;
              }
              .page {
                  width: 210mm;
                  min-height: 297mm;
                  padding: 20mm;
                  margin: 10mm auto;
                  border: 1px #D3D3D3 solid;
                  border-radius: 5px;
                  background: white;
                  display: none;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
              }
              @page {
                  size: A4;
                  margin: 0;
                  margin-top: 20px;
              }
              @media print {
                  html, body {
                      width: 210mm;
                      height: 297mm;
                  }
                  .error-color {
                    color: #454545
                  }
                  .page {
                      margin: 0;
                      margin-top: 20;
                      border: initial;
                      border-radius: initial;
                      width: initial;
                      min-height: initial;
                      box-shadow: initial;
                      background: initial;
                      page-break-after: always;
                      display: table;
                  }
                  .printable-test-paper-background {
                    position: fixed;
                    margin-top: 10%;
                    font-size: 14px;
                    font-family: sans-serif;
                  }
                  .printable {
                    display: '';
                 }
              }
            `}
          </style>
        </div>
      </div>
    );
  }
}

ErrorAnalysis.propTypes = {
  match: PropTypes.object,
  getQuestionAnalysisData: PropTypes.func.isRequired,
  name: PropTypes.string,
  date: PropTypes.string,
  subjects: PropTypes.object,
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  institute: PropTypes.object,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  subjectSelected: PropTypes.number,
  isFilter: PropTypes.bool,
  majorErrorQuestions: PropTypes.array,
  majorErrorCheckpoints: PropTypes.array,
  showLoader: PropTypes.bool,
  enableLoader: PropTypes.func.isRequired,
};

ErrorAnalysis.defaultProps = {
  match: {},
  name: '',
  date: '',
  subjects: {},
  campuses: {},
  classrooms: {},
  institute: {},
  campusSelected: '',
  classroomSelected: '',
  subjectSelected: '',
  isFilter: false,
  majorErrorCheckpoints: [],
  majorErrorQuestions: [],
  showLoader: false,
};

const mapStateToProps = ({ insight }) => ({
  majorErrorQuestions: insight.majorErrorQuestions,
  majorErrorCheckpoints: insight.majorErrorCheckpoints,
  renderMoreQuestions: insight.renderMoreQuestions,
  subjects: insight.subjects,
  subjectSelected: insight.subjectSelected,
  campusSelected: insight.campusSelected,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  classroomSelected: insight.classroomSelected,
  name: insight.name,
  date: insight.date,
  institute: insight.institute,
  isFilter: insight.isFilter,
  showLoader: insight.showLoader,
});

export default connect(mapStateToProps,
  { getQuestionAnalysisData, enableLoader })(ErrorAnalysis);
