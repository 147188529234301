import Api from '../helpers/Api';
import { SET_STUDENT_DATA, SET_SUBSCRIPTION_DATA, SET_ACTIVITY_DATA } from '../reducers/studentProgress';


export function getSubscriptionData(instituteId = '') {
  return (dispatch) => Api({
    method: 'post',
    url: '/student_insights/get_subscription_data',
    data: {
      institute_id: instituteId,
    },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: SET_SUBSCRIPTION_DATA,
        payload: {
          subscriptionData: response.subscription_data,
          registrationData: response.registration_data,
          instituteData: response.institute_data,
          selectedInstitute: response.selected_institute
            ? { value: response.selected_institute.id, label: response.selected_institute.name }
            : {},
        },
      });
      return response;
    }
  });
}

export function getStudentActivity(id, startDate = '', endDate = '', selectedObject = '', testMode = '') {
  return (dispatch) => Api({
    method: 'get',
    url: `/student_insights/${id}/get_students_activity?start_date=${startDate}&end_date=${endDate}&object_type=${selectedObject}&test_mode=${testMode}`,
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: SET_ACTIVITY_DATA,
        payload: {
          activityData: response.activity_data,
          startDate: response.start_date,
          questionCount: response.question_count,
          testModes: response.test_modes,
          objectTypes: response.object_types,
          student: response.student,
          institute: response.institute,
          subscriptionType: response.subscription_type,
        },
      });
      return response;
    }
  });
}


export function getStudentInsightsData(selectedInstitute, date) {
  return (dispatch) => Api({
    method: 'post',
    url: '/student_insights/get_student_insights_data',
    data: {
      institute_id: selectedInstitute.value || '',
      start_date: date.startDate,
      end_date: date.endDate,
    },
  }).then((response) => {
    if (response.success) {
      dispatch({
        type: SET_STUDENT_DATA,
        payload: {
          students: response.students,
          subscriptionTypes: response.subscription_types,
          instituteData: response.institute_data,
          selectedInstitute: response.selected_institute
            ? { value: response.selected_institute.id, label: response.selected_institute.name }
            : {},
        },
      });
      return response;
    }
  });
}
