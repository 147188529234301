import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LocaleDate from './localeDate';

const yesterday = new Date();
yesterday.setDate((yesterday).getDate() - 1);

class DateStatus extends PureComponent {
  renderDate(date) {
    if (date.toDateString() === new Date().toDateString()) {
      return (
        <div>today&nbsp;</div>
      );
    }
    if (date.toDateString() === yesterday.toDateString()) {
      return (
        <div>yesterday&nbsp;</div>
      );
    }
    return (
      <div>
        <LocaleDate isDay date={date} />
        &nbsp;
      </div>
    );
  }

  render() {
    const { selectedRange } = this.props;
    const { startDate } = selectedRange;
    const { endDate } = selectedRange;
    const thisMonthStart = new Date();
    thisMonthStart.setDate(1);
    const lastMonthStart = new Date();
    lastMonthStart.setDate(1);
    lastMonthStart.setMonth(lastMonthStart.getMonth() - 1);
    const thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    const lastMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    const thisWeekStart = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()));
    const thisWeekEnd = new Date(new Date().setDate(new Date().getDate()
    - new Date().getDay() + 6));
    const lastWeek = new Date(new Date().getFullYear(), new Date().getMonth(),
      new Date().getDate() - 7);
    const lastWeekStart = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay()));
    const lastWeekEnd = new Date(lastWeek.setDate(lastWeek.getDate() - lastWeek.getDay() + 6));
    if (startDate.toDateString() === endDate.toDateString()) {
      return (
        <div style={{ display: 'flex' }}>
          On&nbsp;
          {this.renderDate(startDate)}
        </div>
      );
    }
    if (startDate.toDateString() === thisMonthStart.toDateString()
    && endDate.toDateString() === thisMonthEnd.toDateString()) {
      return (
        <div>
          on this month
        </div>
      );
    }
    if (startDate.toDateString() === lastMonthStart.toDateString()
    && endDate.toDateString() === lastMonthEnd.toDateString()) {
      return (
        <div>
          on last month
        </div>
      );
    }
    if (startDate.toDateString() === thisWeekStart.toDateString()
    && endDate.toDateString() === thisWeekEnd.toDateString()) {
      return (
        <div>
          on this week
        </div>
      );
    }
    if (startDate.toDateString() === lastWeekStart.toDateString()
    && endDate.toDateString() === lastWeekEnd.toDateString()) {
      return (
        <div>
          on last week
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {startDate && (
        <div style={{ display: 'flex' }}>
          From&nbsp;
          {this.renderDate(startDate)}
        </div>
        )}
        {endDate && (
        <div style={{ display: 'flex' }}>
          to&nbsp;
          {this.renderDate(endDate)}
        </div>
        )}
      </div>
    );
  }
}

DateStatus.propTypes = {
  selectedRange: PropTypes.object,
};

DateStatus.defaultProps = {
  selectedRange: {},
};

export default DateStatus;
