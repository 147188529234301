module.exports = {
  api: {
    url: `https://api.${process.env.REACT_APP_DOMAIN}`,
    ws_url: `wss://api.${process.env.REACT_APP_DOMAIN}/cable`,
    responseType: 'json',
    withCredentials: true,
    timeout: 40000,
  },
  cookie: {
    secure: true,
    subDomain: `insights.${process.env.REACT_APP_DOMAIN}`,
    domainRegex: `.${process.env.REACT_APP_DOMAIN}`,
    accountsDomain: `accounts.${process.env.REACT_APP_DOMAIN}`,
    homeDomain: `www.${process.env.REACT_APP_DOMAIN}`,
    qbank: `qbank.${process.env.REACT_APP_DOMAIN}`,
    parentPortalDomain: `parent.${process.env.REACT_APP_DOMAIN}`,
  },
  insightsAppVersionCode: '1.0.0',
  contact: '080-471-87111',
  email: 'support@ideaboard.xyz',
  companyUrl: 'www.ideaboard.xyz',
  toastMessages: {
    networkError: 'Network Error,please try again later!',
    thankYouResponse: 'Thank you for your interest, we will get back to you soon!',
  },
};
