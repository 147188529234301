export const SET_STUDENT_DATA = 'InstituteProgress/SET_STUDENT_DATA';
export const SET_SUBSCRIPTION_DATA = 'InstituteProgress/SET_SUBSCRIPTION_DATA';
export const SET_ACTIVITY_DATA = 'InstituteProgress/SET_ACTIVITY_DATA';


const initialState = {
  students: [],
  subscriptionTypes: {},
  instituteData: {},
  selectedInstitute: {},
  subscriptionData: {},
  startDate: '',
  testModes: [],
  objectTypes: [],
  registrationData: {},
  activityData: {},
  questionCount: 0,
};

export default function (state = initialState, action) {
  const newState = { ...state };
  switch (action.type) {
    case SET_STUDENT_DATA: {
      newState.students = [...(action.payload.students || [])];
      newState.subscriptionTypes = { ...action.payload.subscriptionTypes };
      newState.instituteData = [...(action.payload.instituteData || [])];
      newState.selectedInstitute = { ...action.payload.selectedInstitute };
      return { ...newState };
    }
    case SET_ACTIVITY_DATA: {
      return { ...state, ...action.payload };
    }
    case SET_SUBSCRIPTION_DATA: {
      newState.subscriptionData = action.payload.subscriptionData;
      newState.registrationData = action.payload.registrationData;
      newState.instituteData = [...(action.payload.instituteData || [])];
      newState.selectedInstitute = { ...action.payload.selectedInstitute };
      return { ...newState };
    }
    default:
      return state;
  }
}
