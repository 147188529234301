import {
  GET_ASSIGNMENT_LIST, UPDATE_TEST_SUMMARY, GET_SMS_STATUS,
} from '../actions/dashboard';
import { UPDATE_STATUS } from '../shared/constants/textConstants';

const initialState = {
  testsList: [],
  smsStatus: {},
  liveTestIds: [],
  testTaken: [],
  showSentSms: false,
  smsBalance: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSIGNMENT_LIST: {
      return { ...state, ...action.payload };
    }
    case UPDATE_TEST_SUMMARY: {
      const newState = { ...state };
      const index = newState.testsList.map((obj) => {
        return obj.id;
      }).indexOf(parseInt(action.id, 10));
      newState.testsList[index].summary.status = action.status;
      if (action.status === UPDATE_STATUS.COMPLETE) {
        const testTakenIndex = newState.testTaken.map((obj) => {
          return obj.id;
        }).indexOf(parseInt(action.id, 10));
        newState.testTaken[testTakenIndex].test_taken = true;
      }
      return newState;
    }
    case GET_SMS_STATUS: {
      const newState = { ...state };
      newState.smsBalance = action.payload.smsBalance;
      newState.smsStatus[action.payload.testId] = action.payload.smsStatus;
      return newState;
    }
    default: {
      return state;
    }
  }
}
