import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries,
  LineMarkSeries,
  FlexibleWidthXYPlot,
  Crosshair,
  LineSeries,
} from 'react-vis';
import Filter from './common/filter';
import Header from './common/header';
import { INSTITUTE_GOALS_MENU } from '../shared/constants/fieldTypes';
import { getInstituteProgress } from '../actions/progress';
import { ZONES, ZONE_COLORS, ZONE_SHADOW } from '../reducers/progress';
import Loader from './common/loader';
import CustomAxisLabel from './common/customAxisLabel';
import SideBar from './common/SideBar';
import { toTitleCase, isMobileDevice } from '../helpers/Utils';
import StudentZoneDetails from './studentZoneDetails';
import { getTestOverviewData } from '../actions/insight';

const IS_MOBILE = isMobileDevice();
class Progress extends Component {
  static async getInitialProps({ query, reduxStore }) {
    this.setState({ isLoading: true });
    await reduxStore.dispatch(getInstituteProgress({}));
  }

  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
      filters: {},
      isStudentZoneDetailsPage: false,
      isLoading: true,
      isProgressPage: true,
      isZoneSummaryPage: false,
      selectedZone: 'ALL',
    };
    this.updateResults = this.updateResults.bind(this);
    this.onMouseOverUpdateGraph = this.onMouseOverUpdateGraph.bind(this);
    this.onMouseLeaveUpdateGraph = this.onMouseLeaveUpdateGraph.bind(this);
    this.goBack = this.goBack.bind(this);
    this.goToZonePage = this.goToZonePage.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.getInstituteProgress({}).then((response) => {
      if (response.success) {
        this.setState({ isLoading: false });
      }
    });
  }

  goBack() {
    this.setState({ isProgressPage: true, selectedZone: 'ALL' });
  }

  onMouseOverUpdateGraph(value, { index }) {
    const { isProgessPage, zoneTrend } = this.props;
    const data = Object.keys(zoneTrend).map((zone) => {
      return { ...zoneTrend[zone][index], zone: toTitleCase(zone) };
    });
    this.setState({ crosshairValues: data });
  }

  onMouseLeaveUpdateGraph() {
    this.setState({ crosshairValues: [] });
  }

  updateResults(filters) {
    const activeFilters = {};
    this.setState({ isLoading: true });
    this.props.getInstituteProgress(filters).then((response) => {
      if (response.success) {
        this.setState({ isLoading: false });
      }
    });
    Object.keys(filters).map((type) => {
      if (filters[type]) {
        activeFilters[type] = filters[type];
      }
    });
    this.setState({ filters: activeFilters });
  }

  goToZonePage() {
    this.setState({ isProgressPage: false, isStudentZoneDetailsPage: false, isZoneSummaryPage: true });
  }

  renderMonthYear() {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date();
    const month = date.getMonth();
    return (
      <>
        -
        {' '}
        {monthNames[month]}
        {' '}
        {date.getFullYear()}
      </>
    );
  }

  renderZoneOverallStats(zoneOverall, zones) {
    const { isProgressPage } = this.state;
    const { isSampleData, testTypeName } = this.props;
    return (
      <div className="col-md-9" style={{ margin: 'auto', paddingTop: IS_MOBILE ? 10 : 20, marginBottom: IS_MOBILE ? 20 : 85 }}>
        <div style={{ fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, color: '#11426C' }}>
          Current Status
          {this.renderMonthYear()}
        </div>
        <div style={{
          height: '100px', width: IS_MOBILE ? '100%' : '122%', backgroundColor: '#F5F8FA', borderRadius: 4, padding: '7px 0px 0px 0px',
        }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* {isSampleData && <div style={{ fontWeight: 600, fontSize: 18, color: '#D88E73', marginBottom: 20 }}>Sample {testTypeName} Data</div>} */}
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {Object.keys(ZONES).map((zone) => {
              return (
                <div
                  style={{
                    background: '#189F00 0% 0% no-repeat ', height: IS_MOBILE ? 95 : 160, width: '32%', backgroundColor: ZONE_COLORS[zone], padding: 0, textAlign: 'center', color: '#FAFAFA', boxShadow: `0px 3px 6px ${ZONE_SHADOW[zone]}`, borderRadius: 4,
                  }}
                  key={zone}
                >
                  <div style={{ marginTop: IS_MOBILE ? 2 : 10 }}>
                    <div style={{ fontWeight: 600, fontSize: IS_MOBILE ? 18 : 32 }}>{zoneOverall[ZONES[zone]] || 0}</div>
                    <div style={{ fontSize: IS_MOBILE ? 12 : 20, fontWeight: 100 }}>
                      {IS_MOBILE && (
                      <>
                        <span>Students in</span>
                        <br />
                        <span>
                          {toTitleCase(ZONES[zone]) || ''}
                          {' '}
                          Zone
                        </span>
                      </>
                      )}
                      {!IS_MOBILE && (
                      <>
                        Students in&nbsp;
                        {toTitleCase(ZONES[zone]) || ''}
                        {' '}
                        Zone
                      </>
                      )}
                    </div>
                  </div>
                  <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: IS_MOBILE ? 0 : 10, height: '30px', width: '100%',
                  }}
                  >
                    <div
                      onClick={() => this.setState({
                        isStudentZoneDetailsPage: false, isZoneSummaryPage: true, isProgressPage: false, selectedZone: zone,
                      })}
                      style={{
                        userSelect: 'none', display: 'flex', cursor: 'pointer', color: ZONE_COLORS[zone], alignItems: 'center', justifyContent: 'center', height: IS_MOBILE ? '60%' : '100%', padding: IS_MOBILE ? '' : '10', width: '50%', background: '#FFFFFF no-repeat padding-box', borderRadius: '4px', boxShadow: '0px 3px 6px #20D0001A', fontSize: IS_MOBILE ? 12 : 16,
                      }}
                    >
                      Details
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderZoneTrend(trend) {
    const { isSampleData, testTypeName } = this.props;
    return (
      <div className="col-md-9" style={{ margin: 'auto', marginTop: IS_MOBILE ? 20 : 35 }}>
        <div
          style={{
            fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, color: '#11426C', marginBottom: 5,
          }}
        >
          Month-wise Trends
        </div>
        <div style={{
          margin: 'auto', width: IS_MOBILE ? '100%' : '122%', padding: IS_MOBILE ? 10 : 20, backgroundColor: '#FFFFFF', boxShadow: '0px 3px 6px #00000014', borderRadius: 4,
        }}
        >
          <div style={{
            display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20,
          }}
          >
            <div style={{ fontWeight: 600, fontSize: IS_MOBILE ? 13 : 20, color: '#11426C' }}>{IS_MOBILE ? 'Academic Year Trends' : 'Total Strength Trends - Academic Year' }</div>
            <div style={{
              fontWeight: 600, display: 'flex', justifyContent: 'flex-end', alignIems: 'center',
            }}
            >
              {Object.keys(ZONE_COLORS).map((zone) => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', padding: IS_MOBILE ? '0px 5px' : '0px 10px' }}>
                    <div style={{
                      marginRight: IS_MOBILE ? 2 : 5, width: IS_MOBILE ? 12 : 20, height: IS_MOBILE ? 12 : 20, backgroundColor: ZONE_COLORS[zone], borderRadius: IS_MOBILE ? '' : 2,
                    }}
                    />
                    <span style={{ fontSize: IS_MOBILE ? 12 : 16 }}>
                      {toTitleCase(ZONES[zone])}
                      {' '}
                      {!IS_MOBILE && 'Zone'}
                    </span>
                  </div>
                );
              })}
              {!IS_MOBILE && <div style={{ display: 'flex', alignItems: 'center', padding: '0px 10px' }} />}
            </div>
          </div>
          {this.renderPerformanceTrend(trend, this.state.selectedZone)}
        </div>
      </div>
    );
  }

  renderPerformanceTrend(zoneTrend, selectedZone) {
    let index = 0;
    if (selectedZone === 'SAFE') {
      index = 0;
    } else if (selectedZone === 'UPGRADE') {
      index = 1;
    } else {
      index = 2;
    }
    const { maxStudent } = this.props;
    let maxLimit = (this.props.maxStudent ? Math.max(...maxStudent) : 20);
    const formatter = this.props.yIndex === 'Percentage of students' ? '%' : '';
    if (selectedZone === 'ALL') {
      return (
        <FlexibleWidthXYPlot onMouseLeave={this.onMouseLeaveUpdateGraph} yDomain={[0, maxLimit]} height={305} xType="ordinal" margin={{ left: IS_MOBILE ? 70 : 80, bottom: IS_MOBILE ? 30 : 60 }}>
          <HorizontalGridLines tickTotal={10} style={{ stroke: '#e6e6e9' }} />
          <VerticalGridLines style={{ stroke: '#e6e6e9' }} />
          <XAxis style={{ text: { stroke: 'none', fill: '#CBCBCB', fontSize: 12 } }} />
          <YAxis style={{ text: { stroke: 'none', fill: '#CBCBCB', fontSize: 12 } }} />
          <CustomAxisLabel style={{ fill: '#CBCBCB' }} title={this.props.yIndex} />
          <CustomAxisLabel title="" xAxis />
          <Crosshair
            values={this.state.crosshairValues}
            titleFormat={(d) => ({ title: 'Month', value: d[0].x })}
            itemsFormat={(d) => {
              return [{ title: d[0].zone, value: `${d[0].y}${formatter}` }, { title: d[1].zone, value: `${d[1].y}${formatter}` }, { title: d[2].zone, value: `${d[2].y}${formatter}` }];
            }}
          />
          <LineSeries
            data={this.props.thresholdPercentage}
            style={{ strokeDashoffset: 10, strokeWidth: 2, strokeDasharray: 6 }}
            color="rgb(18, 147, 154)"
          />
          {Object.keys(ZONES).map((zone) => {
            return (
              <LineMarkSeries
                onNearestX={this.onMouseOverUpdateGraph}
                data={zoneTrend[ZONES[zone]]}
                style={{
                  strokeLinejoin: 'round',
                  strokeWidth: 2,
                  fill: 'none',
                }}
                color={ZONE_COLORS[zone]}
                fill={ZONE_COLORS[zone]}
                size={2}
              />
            );
          })}
        </FlexibleWidthXYPlot>
      );
    }

    maxLimit = maxStudent[index];
    return (
      <FlexibleWidthXYPlot onMouseLeave={this.onMouseLeaveUpdateGraph} yDomain={[0, maxLimit]} height={305} xType="ordinal" margin={{ left: 80, bottom: 60 }}>
        <HorizontalGridLines tickTotal={10} style={{ stroke: '#e6e6e9' }} />
        <VerticalGridLines style={{ stroke: '#e6e6e9' }} />
        <XAxis style={{ text: { stroke: 'none', fill: '#CBCBCB', fontSize: 12 } }} />
        <YAxis style={{ text: { stroke: 'none', fill: '#CBCBCB', fontSize: 12 } }} />
        <CustomAxisLabel style={{ fill: '#CBCBCB' }} title={this.props.yIndex} />
        <CustomAxisLabel title="" xAxis />
        <Crosshair
          values={this.state.crosshairValues}
          titleFormat={(d) => ({ title: 'Month', value: d[0].x })}
          itemsFormat={(d) => {
            return [{ title: toTitleCase(selectedZone), value: `${d[index].y}${formatter}` }];
          }}
        />
        <LineSeries
          data={this.props.thresholdPercentage}
          style={{ strokeDashoffset: 10, strokeWidth: 2, strokeDasharray: 6 }}
          color="rgb(18, 147, 154)"
        />
        <LineMarkSeries
          onNearestX={this.onMouseOverUpdateGraph}
          data={zoneTrend[ZONES[selectedZone]]}
          style={{
            strokeLinejoin: 'round',
            strokeWidth: 2,
            fill: 'none',
          }}
          color={ZONE_COLORS[selectedZone]}
          fill={ZONE_COLORS[selectedZone]}
          size={2}
        />
      </FlexibleWidthXYPlot>
    );
  }

  renderSelectedZoneHeader() {
    const { selectedZone } = this.state;
    const { zoneOverall, zoneTrend } = this.props;
    let difference = zoneOverall && zoneTrend && zoneTrend[ZONES[selectedZone]] && zoneTrend[ZONES[selectedZone]][(zoneTrend[ZONES[selectedZone]].length - 2)] ? (zoneOverall[ZONES[selectedZone]] - zoneTrend[ZONES[selectedZone]][(zoneTrend[ZONES[selectedZone]].length - 2)].y) : 0;
    difference = ['', '+'][+(difference > 0)] + difference;
    return (
      <div className="col-md-9" style={{ margin: 'auto' }}>
        <div style={{ fontWeight: 600, fontSize: IS_MOBILE ? 16 : 24, color: '#11426C' }}>
          {toTitleCase(selectedZone)}
          {' '}
          Zone
          {this.renderMonthYear()}
        </div>
        <div style={{
          margin: 5, marginTop: 12, fontWeight: 600, fontSize: IS_MOBILE ? 20 : 32, color: ZONE_COLORS[selectedZone], marginBottom: 10,
        }}
        >
          {this.props.zoneOverall[ZONES[selectedZone]]}
          (
          {difference}
          )
        </div>
        <button
          onClick={() => this.setState({ isProgressPage: false, isStudentZoneDetailsPage: true })}
          variant="primary"
          style={{
            borderRadius: IS_MOBILE ? '' : 4, marginTop: IS_MOBILE ? '' : 12, backgroundColor: '#40A7FF', color: '#FFFFFF', height: IS_MOBILE ? 30 : 34, width: IS_MOBILE ? 70 : 100,
          }}
        >
          View
        </button>
      </div>
    );
  }

  render() {
    const { isStudentZoneDetailsPage, isProgressPage, selectedZone } = this.state;
    const { isFilter } = this.props;
    let availableScreenHeight = 0
    if (typeof window !== 'undefined') {
      availableScreenHeight = window.innerHeight;
    }
    if (this.state.isLoading) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header updateResults={this.updateResults} menuData={INSTITUTE_GOALS_MENU[0]} />
          <Filter updateResults={this.updateResults} menuData={INSTITUTE_GOALS_MENU[0]} />
        </div>
      );
    }
    if (isProgressPage) {
      return (
        <div style={{ height: availableScreenHeight}}>
          <Header
            menuData={INSTITUTE_GOALS_MENU[0]}
            updateResults={this.updateResults}
          />
          <SideBar menuList={INSTITUTE_GOALS_MENU} selectedOption={0} />
          <div style={{ backgroundColor: '#F5F8FA', marginLeft: IS_MOBILE ? '0%' : '7%', marginTop: 60 }}>
            <div className="col-md-10" style={{ margin: 'auto', paddingTop: IS_MOBILE ? 5 : 30, fontFamily: 'Nunito' }}>
              {this.renderZoneOverallStats(this.props.zoneOverall)}
              {this.renderZoneTrend(this.props.zoneTrend)}
            </div>
          </div>
        </div>
      );
    }
    if (isStudentZoneDetailsPage) {
      return (
        <StudentZoneDetails
          zone={selectedZone.toLowerCase()}
          goToZonePage={this.goToZonePage}
          filters={this.state.filters}
        />
      );
    }
    return (
      <div style={{ height: availableScreenHeight}}>
        <Header
          menuData={INSTITUTE_GOALS_MENU[0]}
          updateResults={this.updateResults}
          backButton="ZonePage"
          goBack={this.goBack}
        />
        <SideBar menuList={INSTITUTE_GOALS_MENU} selectedOption={0} />
        <div style={{ backgroundColor: '#F5F8FA', marginLeft: IS_MOBILE ? '0%' : '7%', marginTop: 60 }}>
          <div className="col-md-10" style={{ margin: 'auto', paddingTop: 30, fontFamily: 'Nunito' }}>
            {this.renderSelectedZoneHeader(this.props)}
            {this.renderZoneTrend(this.props.zoneTrend)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ instituteProgress, insight }) => ({
  zoneOverall: instituteProgress.overall,
  zoneTrend: instituteProgress.trend,
  maxStudent: instituteProgress.maxStudent,
  yIndex: instituteProgress.yIndex,
  thresholdPercentage: instituteProgress.thresholdPercentage,
  isSampleData: instituteProgress.isSampleData,
  testTypeName: instituteProgress.testTypeName,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  isFilter: insight.isFilter,
});

export default connect(mapStateToProps, { getInstituteProgress, getTestOverviewData })(Progress);
