import React from 'react';
import PropTypes from 'prop-types';
import SearchWeb from '../../shared/images/search-web.svg';
import SearchMobile from '../../shared/images/search-mobile.svg';

class Search extends React.PureComponent {
  render() {
    const {
      height, backgroundColor, searchBar, borderRadius, border, width, fontSize,
    } = this.props;
    return (
      <div style={{
        display: 'flex', alignItems: 'center', borderRadius, border,
      }}
      >
        {searchBar
        && (
        <div style={{
          height, backgroundColor, display: 'flex', alignItems: 'center', padding: '0 15px',
        }}
        >
          <img src={SearchWeb} height={17} width={17} alt={SearchWeb} />
        </div>
        )}
        <input
          placeholder="Search student name/id"
          style={{
            outline: 'none',
            fontSize,
            height,
            width,
            border: 'none',
            borderRadius: 0,
            backgroundColor,
          }}
          onChange={(event) => this.props.getSearchData(event.target.value)}
        />
      </div>
    );
  }
}

Search.propTypes = {
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.number,
  searchBar: PropTypes.bool,
  border: PropTypes.string,
  width: PropTypes.number,
  fontSize: PropTypes.number,
};

Search.defaultProps = {
  height: 35,
  backgroundColor: '#F2F2F2',
  borderRadius: 4,
  searchBar: false,
  border: '1px solid #E2E2E2',
  width: 275,
  fontSize: 14,
};

export default Search;
