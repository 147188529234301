import React from 'react';
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';

class BackToTop extends React.PureComponent {
  render() {
    const {
      backgroundColor, size, color, fontSize, bottom,
    } = this.props;
    return (
      <div style={{
        width: '100%', display: 'flex', justifyContent: 'flex-end', cursor: 'pointer',
      }}
      >
        <div
          className="shake"
          style={{
            backgroundColor,
            color,
            fontSize,
            display: 'flex',
            alignItems: 'center',
            placeContent: 'center',
            borderRadius: '50%',
            width: size,
            height: size,
            fontWeight: StyleConstants.textWeight.bold,
            position: 'fixed',
            bottom,
          }}
          role="presentation"
          onClick={() => window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })}
        >
          &#8593;
        </div>
      </div>
    );
  }
}

BackToTop.propTypes = {
  size: PropTypes.number,
  fontSize: PropTypes.number,
  bottom: PropTypes.number,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
};

BackToTop.defaultProps = {
  size: 45,
  fontSize: 25,
  bottom: 50,
  backgroundColor: StyleConstants.color.primary,
  color: StyleConstants.color.white,
};

export default BackToTop;
