import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import '../../shared/constants/styleConstants/staticPages/snackBar.css';
import PropTypes from 'prop-types';

class SnackBar extends PureComponent {
  render() {
    const { showToast, toastMessageType } = this.props;
    return (
      <div className={`snackbar ${showToast ? 'show' : 'hide'}`}>
        {toastMessageType}
      </div>
    );
  }
}

SnackBar.propTypes = {
  showToast: PropTypes.bool,
  toastMessageType: PropTypes.string,

};

SnackBar.defaultProps = {
  showToast: false,
  toastMessageType: '',

};

const mapStateToProps = ({ global }) => ({
  showToast: global.showToast,
  toastMessageType: global.toastMessageType,
});

export default connect(mapStateToProps)(SnackBar);
