import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Header, SideBar,
} from '../common';
import { SIDE_BAR_ITEMS_STUDENT_INSIGHTS } from '../../shared/constants/fieldTypes';
import { getStudentInsightsData } from '../../actions/studentProgress';
import Users from './users';
import Registrations from './registrations';

class StudentInsights extends PureComponent {
  render() {
    const { type } = this.props.match.params;
    const isRegistrations = type === 'overview';
    const isStudentDetails = type === 'students';
    return (
      <div style={{ width: '100%' }}>
        <Header
          backgroundColor="#F5F8FA"
          replaceLocation=""
          menuData={SIDE_BAR_ITEMS_STUDENT_INSIGHTS[type]}
        />
        <div style={{ marginTop: 60 }}>
          <SideBar
            menuList={SIDE_BAR_ITEMS_STUDENT_INSIGHTS}
            selectedOption={type}
          />
        </div>
        {isRegistrations && <Registrations />}
        {isStudentDetails && <Users />}
      </div>
    );
  }
}

export default connect(null, {
  getStudentInsightsData,
})(StudentInsights);
