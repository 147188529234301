import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { INSTITUTE_GOALS_MENU } from '../shared/constants/fieldTypes';
import Header from './common/header';
import Loader from './common/loader';
import SideBar from './common/SideBar';
import { getStudentDetails, redirectToParentPortal } from '../actions/progress';
import { toTitleCase, isMobileDevice } from '../helpers/Utils';

const itemsCountPerPage = 10;
const IS_MOBILE = isMobileDevice();
class StudentZoneDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      showLoader: true,
      filters: {},
    };
    this.updateResults = this.updateResults.bind(this);
    this.getSearchData = this.getSearchData.bind(this);
    this.goToZonePage = this.goToZonePage.bind(this);
  }

  static async getInitialProps({ query, reduxStore }) {
    await reduxStore.dispatch(getStudentDetails({}));
  }

  componentDidMount() {
    const { zone, filters } = this.props;

    if (Object.keys(this.props.subjects).length === 0 || this.state.filters != filters) {
      this.props.getStudentDetails(filters, zone).then(() => {
        this.setState({ showLoader: false });
      });
    } else {
      this.setState({ showLoader: false });
    }
  }

  getSearchData(text) {
    const { zone } = this.props;
    this.props.getStudentDetails({ search: text }, zone);
  }

  updateResults(filters) {
    const activeFilters = {};
    const { zone } = this.props;
    this.setState({ isLoading: true });
    this.props.getStudentDetails(filters, zone).then((response) => {
      if (response.success) {
        this.setState({ isLoading: false });
      }
    });
    Object.keys(filters).map((type) => {
      if (filters[type]) {
        activeFilters[type] = filters[type];
      }
    });
    this.setState({ filters: activeFilters });
  }


  handleRedirectToParentPortal(studentId) {
    this.props.redirectToParentPortal(studentId);
  }

  goToZonePage() {
    this.props.goToZonePage();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  renderStudentsZoneList() {
    const {
      studentsData, subjects,
    } = this.props;
    const { zone } = this.props;
    const { activePage, filters } = this.state;
    return (
      <div>
        <div />

        <div>
          {studentsData && studentsData[zone]
            && (
              <div style={{
                fontSize: IS_MOBILE ? 12 : 22, margin: 'auto', fontFamily: 'Nunito', fontWeight: 'bold',
              }}
              >
                {' '}
                {toTitleCase(zone)}
                {' '}
            Zone :
                {' '}
                {Object.keys(studentsData[zone]).length}
              </div>
            )}
          <table className="table table-striped">
            <thead style={{
              height: 70, textAlign: 'left', fontColor: '#11426C', fontSize: IS_MOBILE ? 12 : 20, fontWeight: 'bold', background: '#F9F9F9',
            }}
            >
              <tr style={{ height: 70 }}>
                <th style={{ width: '4.54%' }}>No</th>
                <th style={{ width: '41%' }}>Name</th>
                <th style={{ width: '27.2%' }}>ID</th>
                <th style={{ width: '9%' }}>Classroom</th>
                <th style={{ width: '4.54%' }}>Physics</th>
                <th style={{ width: '4.54%' }}>Chemistry</th>
                <th style={{ width: '4.54%' }}>{filters && filters.test_type_id === 2 ? 'Maths  ' : 'Biology'}</th>
                <th style={{ width: '4.54%' }}>Total</th>
              </tr>
            </thead>
            <tbody style={{fontSize: IS_MOBILE ? 12: 16}}>
              {}
              {studentsData && studentsData[zone]
                && (studentsData[zone].slice(
                  (activePage - 1) * itemsCountPerPage, ((activePage - 1)
                    * itemsCountPerPage) + itemsCountPerPage,
                )
                ).map((student, index) => {
                  return (
                    <tr style={{ textAlign: 'left' }}>
                      <td>{(activePage - 1) * itemsCountPerPage + index + 1}</td>
                      <td>
                        <span onClick={() => this.handleRedirectToParentPortal(student.id)} style={{ cursor: 'pointer', textDecoration: 'none', color: '#6C5CE7' }}>
                          {student.first_name ? toTitleCase(student.first_name) : ''}
                          {student.last_name}
                        </span>
                      </td>
                      <td>{student.badge_id}</td>
                      <td>{student.classroom_name}</td>
                      {subjects && subjects.map((subject) => {
                        return (
                          <td>{Math.round(student[`${subject}`])}</td>
                        );
                      })}
                      <td>{Math.round(student.total)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div style={{
            display: 'flex', marginTop: 20, justifyContent: 'center', width: '100%',
          }}
          >
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={!!studentsData[zone] && studentsData[zone].length}
              pageRangeDisplayed={5}
              prevPageText="Prev"
              nextPageText="Next"
              onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    let availableScreenHeight = 0
    if (typeof window !== 'undefined') {
      availableScreenHeight = window.innerHeight;
    }
    const { showLoader } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    return (
      <div style={{ height: availableScreenHeight}}>
        <Header
          menuData={INSTITUTE_GOALS_MENU[0]}
          isSearchable
          updateResults={this.updateResults}
          backButton="StudentZoneDetails"
          getSearchData={this.getSearchData}
          goToZonePage={this.goToZonePage}
        />
        <SideBar menuList={INSTITUTE_GOALS_MENU} selectedOption={0} />
        <div style={{
          display: 'flex', backgroundColor: '#F5F8FA', marginTop: 60, justifyContent: 'space-between',
        }}
        >
          <div className="col-md-8" style={{ marginLeft: IS_MOBILE ? '2%' : '25%', paddingTop: 30, fontFamily: 'Nunito' }}>
            {this.renderStudentsZoneList()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ instituteProgress, insight }) => ({
  studentsData: instituteProgress.studentsData,
  hasAccessToParentPortal: instituteProgress.hasAccessToParentPortal,
  isFilter: insight.isFilter,
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  subjects: instituteProgress.subjects,
});

export default connect(mapStateToProps, {
  getStudentDetails, redirectToParentPortal,
})(StudentZoneDetails);
