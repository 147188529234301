import devConfig from './development.json';
import prodConfig from './production';
import stagingConfig from './staging';
import baseConfig from './base';


export default () => {
  if (process.env.REACT_APP_ENV === 'production') {
    return { ...baseConfig, ...prodConfig };
  } if (process.env.REACT_APP_ENV === 'staging') {
    return { ...baseConfig, ...stagingConfig };
  }
  return { ...baseConfig, ...devConfig };
};
