import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import ChevronDownBlack from '../../shared/images/chevron-down-black.svg';
import { updateFilterModel } from '../../actions';

const styles = {
  container: {
    backgroundColor: StyleConstants.testHeader.backgroundColor,
    boxShadow: `0px 1px 0px ${StyleConstants.testHeader.shadowColor}`,
  },
};

class TestHeader extends PureComponent {
  render() {
    const {
      testDate, testName, name, date, updateFilterModel,
    } = this.props;
    return (
      <div className="container-fluid p-0" style={styles.container}>
        <div className="row no-gutter">
          <div className="col p-3" style={{ textAlignLast: 'center' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
              role="presentation"
              onClick={() => updateFilterModel(true, 'Test List')}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
                <div style={{
                  color: StyleConstants.textLineColors.dark, fontSize: 18, fontWeight: 'bolder', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
                }}
                >
                  {testName || name}

                </div>
                <div style={{ color: StyleConstants.textLineColors.mediumDark, fontSize: StyleConstants.textSize.subHeader, fontWeight: 'regular' }}>{testDate || date}</div>
              </div>
              <img
                src={ChevronDownBlack}
                alt=""
                height={28}
                width={28}
                style={{ marginLeft: 10, alignSelf: 'flex-start' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestHeader.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  testDate: PropTypes.string,
  testName: PropTypes.string,
  updateFilterModel: PropTypes.func.isRequired,
};

TestHeader.defaultProps = {
  date: '',
  name: '',
  testDate: '',
  testName: '',
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  date: insight.date,
});
export default connect(mapStateToProps, { updateFilterModel })(TestHeader);
