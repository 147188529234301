import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Header, SideBar, Modal, Loader, UploadResultProgress, UploadResultError,
} from './common';
import { isMobileDevice, UUID } from '../helpers/Utils';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import Constants from '../shared/constants/environmentConfig';
import Api from '../helpers/Api';
import {
  getTestData, deleteStudentResultsDataManagemnet, uploadStudentResultsDataManagement, 
  updateDataManagementModal, showToast,
} from '../actions';
import { UPDATE_STATUS, TOAST } from '../shared/constants/textConstants';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';

const RESULT_UPDATE_STATUS_CHANNEL = 'ResultUpdateStatusChannel';
const IS_MOBILE = isMobileDevice();

class DataManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: -1,
      cable: Object.create(null),
      subscription: Object.create(null),
      testID: 0,
      id: props.match.params.id,
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.uploadStudentResult = this.uploadStudentResult.bind(this);
  }

  componentDidMount() {
    const { id } = this.state;
    const { getTestData } = this.props;
    const payload = { id };
    getTestData(payload);
    if (typeof window !== 'undefined') {
      const actionCable = require('actioncable');
      const cable = actionCable.createConsumer(Constants().api.ws_url);
      this.setState({ cable });
      const subscription = cable.subscriptions.create({ channel: RESULT_UPDATE_STATUS_CHANNEL }, {
        received: (data) => {
          // window.location.reload();
        },
      });
      this.setState({ subscription });
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const { cable, subscription } = this.state;
      if (cable) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  getStudentResultFile() {
    document.getElementById('uploadStudentResultFile').click();
  }

  deleteResult(index, fileName) {
    const { id } = this.state;
    const { deleteStudentResultsDataManagemnet } = this.props;
    deleteStudentResultsDataManagemnet(id, fileName, index);
  }

  sendSMS() {
    const { id } = this.state;
    const { showToast } = this.props;
    Api({
      method: 'post',
      url: '/dashboard/send_sms_to_parents',
      data: { id },
    }).then((response) => {
      if (response.success) {
        showToast(TOAST.SMS.TEXT, TOAST.SMS.DURATION);
      } else {
        showToast(TOAST.DENIED.TEXT, TOAST.DENIED.DURATION);
      }
    });
  }

  uploadStudentResult(event) {
    const { testID } = this.state;
    const { uploadStudentResultsDataManagement } = this.props;
    const fileList = event.target.files;
    const files = Object.values(fileList);
    const sendFiles = files.map((file) => {
      return new File([file], `${UUID()}.xlsx`, { type: file.type });
    });
    uploadStudentResultsDataManagement(sendFiles, testID);
  }

  handleCloseModal() {
    const { updateDataManagementModal } = this.props;
    updateDataManagementModal(false);
  }

  uploadResult(status) {
    const { updateDataManagementModal } = this.props;
    const { id } = this.state;
    this.setState({ testID: id, status });
    updateDataManagementModal(true);
  }

  renderTestModal() {
    const { status, id } = this.state;
    const { showLoader } = this.props;
    if (showLoader) {
      return <Loader />;
    }
    if (status === UPDATE_STATUS.PROCESS) {
      return (
        <UploadResultProgress id={id} />
      );
    } if (status === UPDATE_STATUS.ERROR) {
      return (
        <UploadResultError id={id} />
      );
    }
    return (
      <div className="d-flex flex-column justify-content-center" style={{ marginRight: global.innerWidth * 0.1, marginLeft: global.innerWidth * 0.1 }}>
        <div
          style={{ textAlign: 'center' }}
          role="presentation"
          onClick={this.getStudentResultFile}
        >
          <img src="/static/images/upload.svg" alt="" width="50px" />
          <p> Supported .xlsx </p>
          <p style={{ fontSize: '12px', color: '#cdcdce' }}>Drag or browse the Excel file to upload.</p>
        </div>
        <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
          <input id="uploadStudentResultFile" type="file" name="result" accept=".xlsx" multiple="true" onChange={this.uploadStudentResult} />
        </div>
        <div>
          <a download="student_result.xlsx" target="_blank" href="/static/images/student_result.xlsx" style={{ width: '100%' }}>
            <div className="btn btn-outline-primary btn-block" style={{ fontSize: '14px', padding: 10 }}>
              <img alt="" src="/static/images/download.svg" style={{ width: 20, marginRight: 20, marginTop: -3 }} />
              Download
            </div>
          </a>
        </div>
      </div>
    );
  }


  render() {
    const { status, id } = this.state;
    const {
      testResponses, showModal, isOnline, uploadStatus,
    } = this.props;
    let header = 'Upload Results';
    if (status === UPDATE_STATUS.PROCESS) {
      header = 'Processing Result';
    } else if (status === UPDATE_STATUS.ERROR) {
      header = 'Error';
    }
    return (
      <div>
        <Modal showModal={showModal} handleCloseModal={this.handleCloseModal} header={header} top="25%">
          {this.renderTestModal()}
        </Modal>
        <div className="row" style={{ backgroundColor: 'rgb(243,248,250)', marginTop: IS_MOBILE ? 50 : StyleConstants.headerHeight.web }}>
          <div style={{ width: '20%', padding: 0, zIndex: 1 }}>
            <Header testId={id} menuData={SIDE_BAR_ITEMS.DATA_MANAGEMENT} />
            <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="DATA_MANAGEMENT" testId={id} />
          </div>
          <div style={{ width: '80%', padding: '40px 62px 0 62px', minHeight: '100vh' }}>
            <p style={{
              marginBottom: 10, fontWeight: 'bold', fontSize: 18, marginTop: 25,
            }}
            >
              Data Management

            </p>
            {testResponses.map((response, index) => (
              <div
                className="d-flex flex-row"
                key={`spreadsheet${index}`}
                style={{
                  justifyContent: 'space-between', alignItems: 'center', border: '1px solid #BFBFBF', padding: 20, marginBottom: 5, borderRadius: 4,
                }}
              >
                <p style={{
                  marginBottom: 10, fontWeight: 'bold', fontSize: 18, marginTop: 25,
                }}
                >
                  {response.fileName}

                </p>
                <p style={{
                  marginBottom: 10, fontWeight: 'bold', fontSize: 18, marginTop: 25,
                }}
                >
                  No. of Student:
                  {' '}
                  {response.rowCount - 1}
                </p>
                <div
                  onClick={() => this.deleteResult(index, response.label)}
                  role="presentation"
                  style={{
                    border: '1px solid #FF5F5F', borderRadius: 4, padding: '8px 64px 8px 64px', fontSize: StyleConstants.textSize.text, color: StyleConstants.color.error, cursor: 'pointer',
                  }}
                >
                  Remove
                </div>
              </div>
            ))}
            <div className="d-flex flex-row" style={{ fontSize: 15, marginTop: 5, color: '#aaa' }}>
              {(uploadStatus === UPDATE_STATUS.PROCESS) && (
              <div
                className="ml-5 "
                role="presentation"
                onClick={() => this.uploadResult(UPDATE_STATUS.PROCESS)}
              >
                <p style={{ color: 'green' }}>Processing Result</p>
              </div>
              )}
              {(uploadStatus === UPDATE_STATUS.ERROR) && (
              <div
                className="ml-5"
                role="presentation"
                onClick={() => this.uploadResult(UPDATE_STATUS.ERROR)}
              >
                <p style={{ color: 'red' }}>Errors</p>
              </div>
              )}
            </div>
            <div className="d-flex flex-row mt-5">
              {!isOnline && (
              <div>
                <div
                  style={{
                    backgroundColor: StyleConstants.color.primary, cursor: 'pointer', padding: '6px 16px', borderRadius: 4, color: StyleConstants.color.white,
                  }}
                  role="presentation"
                  onClick={() => this.uploadResult(UPDATE_STATUS.UNUPLOAD)}
                >
                  {' '}
                  Add Result

                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DataManagement.propTypes = {
  uploadStudentResultsDataManagement: PropTypes.func.isRequired,
  getTestData: PropTypes.func.isRequired,
  deleteStudentResultsDataManagemnet: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  testResponses: PropTypes.array,
  uploadStatus: PropTypes.number,
  match: PropTypes.object,
  isOnline: PropTypes.bool,
  showModal: PropTypes.bool,
};

DataManagement.defaultProps = {
  showLoader: false,
  testResponses: [],
  uploadStatus: 0,
  match: {},
  isOnline: false,
  showModal: false,
};

const mapStateToProps = ({ insight }) => ({
  showLoader: insight.showLoader,
  uploadStatus: insight.status,
  errors: insight.errors,
  isOnline: insight.isOnline,
  testResponses: insight.testResponses,
  showModal: insight.showModal,
});

export default connect(mapStateToProps,
  {
    uploadStudentResultsDataManagement,
    getTestData,
    deleteStudentResultsDataManagemnet,
    showToast,
    updateDataManagementModal,
  })(DataManagement);
