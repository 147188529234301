import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from './text';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import '../../shared/constants/styleConstants/loader.css';

class Loader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: 0,
    };
  }

  componentDidMount() {
    this.setState({ screenHeight: global.innerHeight });
  }

  render() {
    const { height } = this.props;
    const { screenHeight } = this.state;
    return (
      <div
        className="loader"
        style={{
          height: height || screenHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          userSelect: 'none',
        }}
      >
        <div style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <div />
          <span
            style={{ position: height && 'unset' }}
          />
          <span
            style={{ position: height && 'unset' }}
          />
          <span
            style={{ position: height && 'unset' }}
          />
        </div>
        <div
          className={!height && 'pt-3'}
        />
        <Text
          fontWeight={StyleConstants.textWeight.semiBold}
          text="LOADING..."
        />
      </div>
    );
  }
}

Loader.propTypes = {
  height: PropTypes.number,
};

Loader.defaultProps = {
  height: 0,
};

export default Loader;
