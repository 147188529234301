import React from 'react'
import PropTypes from 'prop-types';
import StyleConstants from '../../shared/constants/styleConstants/styles'

import constants from '../../shared/constants/environmentConfig';
const Constants = constants();

const RESULT_UPDATE_CHANNEL = "ResultUpdateChannel"

class UploadResultProgress extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      cable: Object.create(null),
      subscription: Object.create(null),
      progress: 0
    };
  }

  static propTypes = {
    id: PropTypes.number
  };

  static defaultProps = {
    id: 0
  };

  componentDidMount() {
    const {id} = this.props;
    if (typeof window !== "undefined") {
      const actionCable = require('actioncable');
      const cable = actionCable.createConsumer(Constants.api.ws_url);
      this.setState({cable});
      const subscription = cable.subscriptions.create({ channel: RESULT_UPDATE_CHANNEL, id: id }, {
        received: (data) => {
          this.setState({ status: data.status, progress: data.progress});
        }
      });
      this.setState({subscription});
    }
  }

  componentWillUnmount() {
    if(typeof window !== "undefined") {
      const {cable, subscription} = this.state;
      if (cable) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  render() {
    const {progress} = this.state;
      return(
        <div className="d-flex justify-content-center" style={{margin: '10%', userSelect: 'none'}}>
          <p style={{fontWeight: "bold", fontSize: StyleConstants.textSize.subHeader, color: "green"}}>Processing - {progress}%</p>
        </div>
      );
  }

}

export default UploadResultProgress;
