
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../node_modules/react-vis/dist/style.css';
import {
  UploadResultError, UploadResultProgress, Header, Loader, Modal, Text,
} from './common';
import { isMobileDevice, UUID } from '../helpers/Utils';
import { MAIN_HEADERS } from '../shared/constants/fieldTypes';
import StyleConstants from '../shared/constants/styleConstants/styles';
import {
  uploadStudentResults, updateAssignmentModal, getAssignmentList,
  updateTestAttribute, sendSms, showToast,
} from '../actions';
import {
  IN_PROGRESS, ERRORS, RESET, UPLOAD, VIEW_RESULT, SEND_SMS, UPDATE_STATUS,
} from '../shared/constants/textConstants';
import { ZONE_COLORS } from '../reducers/progress';
import constants from '../shared/constants/environmentConfig';

const Constants = constants();
const RESULT_UPDATE_STATUS_CHANNEL = 'ResultUpdateStatusChannel';
const styles = {
  container: {
    height: '100%',
    minHeight: '100vh',
    userSelect: 'none',
  },
  mainBlock: {
    backgroundColor: '#F7F8FC',
  },
};
const IS_MOBILE = isMobileDevice();


class TestList extends React.Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      showLoader: false,
      searchAssignmentString: '',
      showSmsModal: 0,
      status: -1,
      showTestStatusId: '',
      cable: Object.create(null),
      subscription: Object.create(null),
      testID: 0,
    };
    this.renderListView = this.renderListView.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleSmsModal = this.handleSmsModal.bind(this);
    this.uploadStudentResult = this.uploadStudentResult.bind(this);
    this.reOrderList = this.reOrderList.bind(this);
    this.renderListGroup = this.renderListGroup.bind(this);
    this.viewResult = this.viewResult.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const actionCable = require('actioncable');
      const cable = actionCable.createConsumer(Constants.api.ws_url);
      this.setState({ cable });
      const subscription = cable.subscriptions.create({ channel: RESULT_UPDATE_STATUS_CHANNEL }, {
        received: (data) => {
          this.props.dispatch(
            updateTestAttribute(data.test_id, data.status),
          ).then(() => this.forceUpdate());
        },
      });
      this.setState({ subscription });
    }
    this.setState({ showLoader: true });
    this.props.dispatch(getAssignmentList()).then(() => {
      const { liveTestIds } = this.props;
      // this.props.dispatch(getLiveStudentCount(liveTestIds)).then(() => {
      this.setState({ showLoader: false });
      // }); 
    });
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      const { cable, subscription } = this.state;
      if (cable) {
        cable.subscriptions.remove(subscription);
      }
    }
  }

  getStudentResultFile() {
    document.getElementById('uploadStudentResultFile').click();
  }

  sendSms(smsSentId) {
    this.props.dispatch(sendSms(smsSentId)).then(() => {
      this.setState({ showLoader: false });
    });
  }

  uploadTestResult() {
    return (
      <div className="col-12">
        <div className="App-Box-Title">Upload Test Result:</div>
        <div
          className="primary-button"
          data-toggle="modal"
          data-target="#uploadResultModel"
          style={{ height: 35, width: 150, margin: 15 }}
          onClick={(e) => this.uploadResult(e)}
          role="presentation"
        >
          {UPLOAD}
        </div>
      </div>
    );
  }


  handleCloseModal() {
    this.props.dispatch(updateAssignmentModal(false));
    this.setState({ showTestStatusId: '' });
  }

  handleSmsModal(id, status) {
    if (id && status) {
      this.sendSms(id);
    }
    this.setState({ showSmsModal: status ? 0 : id });
  }

  uploadResult(event, status = '', testID = 0) {
    this.props.dispatch(updateAssignmentModal(true));
    this.setState({ testID, status });
    event.stopPropagation();
  }

  uploadStudentResult(event) {
    const { testID } = this.state;
    const fileList = event.target.files;
    const files = Object.values(fileList);
    const sendFiles = files.map((file) => {
      return new File([file], `${UUID()}.xlsx`, { type: file.type });
    });
    this.props.dispatch(uploadStudentResults(sendFiles, testID));
  }

  reOrderList(list) {
    const orderedList = {
      online: [],
      offline: [],
      live: [],
    };
    list.forEach((assignment) => {
      if (assignment.is_online && !assignment.is_published) {
        orderedList.live.push(assignment);
      } else if (assignment.is_online) {
        orderedList.online.push(assignment);
      } else {
        orderedList.offline.push(assignment);
      }
    });
    return orderedList;
  }

  viewResult(assignment) {
    const { smsStatus, testTaken, showSentSms } = this.props;
    const testTakenData = testTaken.filter((obj) => obj.id === assignment.id)[0];
    const resultAvailable = parseInt(testTakenData.test_taken_count, 10) > 0;
    const smsSent = Object.keys(smsStatus).includes((assignment.id).toString())
      ? smsStatus[assignment.id] : assignment.sms_sent;
    const alreadySent = smsSent === 1;
    const smsFailed = smsSent === 2;
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', fontSize: 14, alignItems: 'center',
      }}
      >
        <div
          className="primary-button"
          style={{
            width: resultAvailable && showSentSms ? '45%' : '100%', cursor: !resultAvailable && 'not-allowed', opacity: !resultAvailable && '0.5',
          }}
          onClick={() => { this.renderResult(resultAvailable, assignment); }}
          role="presentation"
        >
          {VIEW_RESULT}
        </div>
        {!assignment.is_sample && resultAvailable && showSentSms && !smsFailed && (
        <div
          className="primary-button"
          style={{
            width: smsFailed ? '100%' : '45%', opacity: (alreadySent) ? '.5' : '1', cursor: (alreadySent) ? 'not-allowed' : 'pointer',
          }}
          onClick={() => (!alreadySent && this.handleSmsModal(assignment.id))}
          role="presentation"
        >
          {SEND_SMS[smsSent || 0]}
        </div>
        )}
        {smsFailed
        && (
        <div
          role="presentation"
          onClick={() => (this.handleSmsModal(assignment.id))}
          style={{ width: '45%', color: StyleConstants.color.error, cursor: 'pointer' }}
        >
          {SEND_SMS[smsSent]}
        </div>
        )}
      </div>
    );
  }

  renderResult(resultAvailable, assignment) {
    if (resultAvailable) {
      window.location.href = `/highlights/${assignment.id}`;
    } else {
      this.props.dispatch(showToast('Atleast One Student Should Have Took the Test To View Result'));
    }
  }

  renderListView(assignment) {
    const { showSmsModal } = this.state;
    const isResultPresent = (assignment.is_published);
    const pendingUpload = [
      UPDATE_STATUS.PROCESS, UPDATE_STATUS.ERROR].includes(assignment.summary.status);
    const pendingReason = assignment.summary.status === UPDATE_STATUS.ERROR ? ERRORS : IN_PROGRESS;
    const pendingColor = assignment.summary.status === UPDATE_STATUS.ERROR
      ? StyleConstants.color.error : StyleConstants.color.success;
    if (IS_MOBILE) {
      return (
        <div
          onClick={() => { window.location.href = `/highlights/${assignment.id}`; }}
          role="presentation"
        >
          {isResultPresent
            && (
              <div
                className="row m-2"
                key={assignment.id}
                style={{
                  backgroundColor: StyleConstants.color.white, boxShadow: '0px 3px 6px #343B4014', borderRadius: 4, cursor: (assignment.review) ? 'pointer' : 'default', padding: 10,
                }}
              >
                <div className="col">
                  <div style={{
                    fontWeight: 'bold', color: StyleConstants.color.primaryHeaderTextColor, fontSize: 13, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: 5,
                  }}
                  >
                    {assignment.name}

                  </div>
                  <div
                    className="d-flex flex-row"
                    style={{
                      fontSize: 12, marginTop: 5, color: '#aaa', whiteSpace: 'nowrap',
                    }}
                  >
                    {assignment.date}
                    {' '}
                    &emsp;
                    {' '}
                    {assignment.question_count || 0}
                    {' '}
                    {!assignment.review && assignment.total_question_count && (
                      <div>
                        {' '}
                        /
                        {' '}
                        {assignment.total_question_count}
                      </div>
                    )}
                    {' '}
                    &thinsp; questions
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    }
    return (
      <div
        className="row p-0 m-2"
        key={assignment.id}
        style={{
          backgroundColor: StyleConstants.color.white, padding: 85, boxShadow: '0px 3px 6px #343B4014', borderRadius: 4, cursor: (assignment.review) ? 'pointer' : 'default',
        }}
      >
        <Modal showModal={showSmsModal === assignment.id} handleCloseModal={this.handleSmsModal} header="Send Sms To Parents" top="50%" width={360} height={155} headerPadding={10}>
          <div>
            <div style={{
              display: 'flex', justifyContent: 'flex-end', marginTop: 30, height: '100%',
            }}
            >
              <div
                role="presentation"
                className="primary-button"
                style={{ cursor: 'pointer', marginLeft: 10, width: 70 }}
                onClick={() => this.handleSmsModal(assignment.id, true)}
              >
                Send
              </div>
              <div
                className="primary-button"
                role="presentation"
                style={{ cursor: 'pointer', marginLeft: 10, width: 75 }}
                onClick={() => this.handleSmsModal()}
              >
                Cancel
              </div>
            </div>
          </div>
        </Modal>
        <div className="col">
          <div style={{
            fontWeight: 'bold', color: StyleConstants.color.primaryHeaderTextColor, fontSize: StyleConstants.textSize.subHeader, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: 5,
          }}
          >
            {assignment.name}
          </div>
          <div
            className="d-flex flex-row"
            style={{
              fontSize: 12, marginTop: 5, color: '#aaa', whiteSpace: 'nowrap',
            }}
          >
            {assignment.date}
            {' '}
            &emsp;
            {' '}
            {assignment.question_count || 0}
            {' '}
            {!assignment.review && assignment.total_question_count && (
              <div>
                {' '}
                /
                {' '}
                {assignment.total_question_count}
              </div>
            )}
            {' '}
            &thinsp; questions
          </div>
          <div style={{ marginTop: 5, color: '#aaa', fontSize: 14 }}>
            {pendingUpload && (
              <div
                className="row align-items-center"
                role="presentation"
                onClick={(e) => this.uploadResult(e, assignment.summary.status, assignment.id)}
              >
                <div className="col-4" style={{ color: pendingColor, fontSize: 12 }}>
                  {' '}
                  {pendingReason}
                  {' '}
                </div>
                <div className="col-8" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                    className="primary-button"
                    style={{
                      width: 220,
                    }}
                    role="presentation"
                    onClick={(e) => this.uploadResult(e, UPDATE_STATUS.UNUPLOAD, assignment.id)}
                  >
                    {RESET}
                  </div>
                </div>
              </div>
            )}
          </div>
          {(!isResultPresent) && (!assignment.is_online) && (!pendingUpload)
            && (
              <div className="row mx-2">
                <div
                  className="primary-button"
                  data-toggle="modal"
                  data-target="#uploadResultModel"
                  style={{
                    width: '100%',
                    fontSize: 14,
                  }}
                  onClick={(event) => this.uploadResult(event, UPDATE_STATUS.UNUPLOAD, assignment.id)}
                  role="presentation"
                >
                  {UPLOAD}
                </div>
              </div>
            )}
          {!pendingUpload && isResultPresent
            && (
              this.viewResult(assignment)
            )}
          {!isResultPresent && assignment.is_online
            && (
              <div
                className="primary-button"
                role="presentation"
                onClick={() => (this.setState({ showTestStatusId: assignment.id }))}
              >
                View Status
              </div>
            )}
        </div>
      </div>
    );
  }

  renderStatusText(fieldName, value, background) {
    const style = {
      color: StyleConstants.color.white, margin: 0,
    };
    return (
      <div style={{
        display: 'flex', justifyContent: 'space-between', background, padding: '15px 25px', margin: '0px 40px 20px 40px', width: 360, borderRadius: 4,
      }}
      >
        <Text
          style={{ ...style }}
          text={fieldName}
        />
        <Text
          style={{ ...style }}
          text={value}
        />
      </div>
    );
  }

  renderTestStatus(assignmentId) {
    const { testTaken, liveStudentCount } = this.props;
    const showResultButton = testTaken.filter((obj) => obj.id === assignmentId)[0];
    if (showResultButton) {
      return (
        <div style={{
          display: 'flex', flexDirection: 'column', fontWeight: StyleConstants.textWeight.semiBold, fontSize: StyleConstants.textSize.Header, marginTop: 20,
        }}
        >
          {this.renderStatusText('No of Students Online : ', liveStudentCount[assignmentId] || 0, ZONE_COLORS.SAFE)}
          {this.renderStatusText('Test Assigned Students Count : ', showResultButton.test_assigned_count || 0, ZONE_COLORS.REMEDY)}
          {this.renderStatusText('Test Taken Count : ', showResultButton.test_taken_count || 0, ZONE_COLORS.UPGRADE)}
        </div>
      );
    }
    return (<div />);
  }

  renderTestModal() {
    const { status, testID } = this.state;
    if (status === UPDATE_STATUS.PROCESS) {
      return (
        <UploadResultProgress id={testID} />
      );
    } if (status === UPDATE_STATUS.ERROR) {
      return (
        <UploadResultError id={testID} />
      );
    } if (testID === 0) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 30px' }}>
          <div>Enter Test Name</div>
          <input
            id="enterTextName"
            type="text"
            name="Test Name"
            style={{
              background: 'transparent', borderColor: 'transparent', borderBottomColor: StyleConstants.textLineColors.dark, borderRadius: 0,
            }}
          />
        </div>
      );
    }
    return (
      <div>
        <div className="d-flex flex-column justify-content-center" style={{ marginRight: global.innerWidth * 0.1, marginLeft: global.innerWidth * 0.1 }}>
          <div
            style={{ textAlign: 'center' }}
            onClick={this.getStudentResultFile}
            role="presentation"
          >
            <img alt="" src="/static/images/upload.svg" width="50px" />
            <p> Supported .xlsx </p>
            <p style={{ fontSize: '12px', color: '#cdcdce' }}>Drag or browse the Excel file to upload.</p>
          </div>
          <div style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
            <input id="uploadStudentResultFile" type="file" name="result" accept=".xlsx" multiple="true" onChange={this.uploadStudentResult} />
          </div>
          <div>
            <a download="student_result.xlsx" target="_blank" href="/static/images/student_result.xlsx" style={{ width: '100%' }}>
              <div className="btn btn-outline-primary btn-block" style={{ fontSize: '14px', padding: 10 }}>
                <img alt="" src="/static/images/download.svg" style={{ width: 20, marginRight: 20, marginTop: -3 }} />
                Download
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderListGroup(list, boxStyle, clickableCard) {
    const { showTestStatusId } = this.state;
    return (
      (list.length > 0) && (
        <div className={IS_MOBILE ? 'row' : 'container'} style={{ ...boxStyle, padding: '10px 30px' }}>
          <div className="col-12" style={{ fontSize: IS_MOBILE ? StyleConstants.textSize.Header : StyleConstants.textSize.secondaryHeader, marginBottom: 10 }}>
            {`${clickableCard} Test :`}
          </div>
          <div className="col-12">
            <div className="row" style={{ marginBottom: 10 }}>
              {list.map((assignment) => {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-3 p-0 m-0" key={assignment.id} data-tip={assignment.name}>
                    <Modal showModal={showTestStatusId === assignment.id} handleCloseModal={this.handleCloseModal} header={assignment.name} top="25%">
                      {this.renderTestStatus(assignment.id)}
                    </Modal>
                    {this.renderListView(assignment)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )
    );
  }

  render() {
    const { testsList, showTestModal, smsBalance } = this.props;
    const { showLoader } = this.state;
    if (showLoader) {
      return <Loader />;
    }
    const { status, searchAssignmentString } = this.state;
    const list = testsList;
    const searchInProgress = (searchAssignmentString.length > 0);
    let header = 'Upload Results';
    const orderedList = this.reOrderList(list);
    let boxStyle = {};
    if (status === UPDATE_STATUS.PROCESS) {
      header = 'Processing Result';
    } else if (status === UPDATE_STATUS.ERROR) {
      header = 'Error';
    }

    if (searchInProgress) {
      boxStyle = {
        pointerEvents: 'none', opacity: '.1',
      };
    }

    return (
      <div className="row" style={styles.container}>
        <div className="col-12 col-sm-12 col-md-12" style={styles.mainBlock}>
          <Header menuData={MAIN_HEADERS[0]} smsBalance={smsBalance} showSmsBalance />
          <div className="container-fluid p-0 App-Contet">
            <div className="row no-gutter">
              <div className="container App-Test-List col-12 p-0" style={{ marginTop: 90 }}>
                <Modal showModal={showTestModal} handleCloseModal={this.handleCloseModal} header={header} top="25%">
                  {this.renderTestModal()}
                </Modal>
                {/* {!IS_MOBILE && orderedList.live.length > 0 && this.renderListGroup(orderedList.live,
                  boxStyle, 'Live')} */}
                {orderedList.online.length > 0 && this.renderListGroup(orderedList.online,
                  boxStyle, 'Online')}
                {orderedList.offline.length > 0 && this.renderListGroup(orderedList.offline, boxStyle, 'Offline')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TestList.propTypes = {
  testsList: PropTypes.array,
  smsStatus: PropTypes.object,
  showTestModal: PropTypes.bool,
  testTaken: PropTypes.array,
  showSentSms: PropTypes.bool,
  smsBalance: PropTypes.number,
  liveStudentCount: PropTypes.object,
  liveTestIds: PropTypes.array,
};

TestList.defaultProps = {
  testsList: [],
  showSentSms: false,
  smsStatus: {},
  showTestModal: false,
  testTaken: [],
  liveStudentCount: {},
  smsBalance: 0,
  liveTestIds: [],
};

const mapStateToProps = ({ dashboard, insight }) => ({
  testsList: dashboard.testsList,
  showTestModal: insight.showModal,
  smsStatus: dashboard.smsStatus,
  testTaken: dashboard.testTaken,
  showSentSms: true,
  smsBalance: dashboard.smsBalance,
  liveStudentCount: dashboard.liveStudentCount,
  liveTestIds: dashboard.liveTestIds,
});

export default connect(mapStateToProps)(TestList);
