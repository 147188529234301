import {
  TEST_OVERVIEW, INSTITUTE_DETAILS, TEST_LISTS, UPDATE_QUESTION_LIST, UPDATE_FILTERS_MODEL, UPDATE_MENU_MODEL,
  UPDATE_DATA_MANAGEMENT_MODAL, UPDATE_DATA_MANAGEMENT, USER_DETAILS, SHOW_LOADER, UPDATE_FILTERS,
} from '../actions/insight';
import {
 RESET_FILTERS, UPDATE_TEST_DETAIL, UPDATE_MODAL, STUDENT_RESULT_UPLOAD_STATUS_DELETE,
} from '../actions/dashboard';

const initialState = {
  id: '',
  name: '',
  rankList: [],
  testList: [],
  rank: {},
  date: '',
  attendancePercentage: 0,
  weakness: [],
  strengths: [],
  majorErrorQuestions: [],
  majorErrorCheckpoints: [],
  absenteeList: [],
  absenteeRate: [],
  scoreDistribution: [],
  campuses: {},
  classrooms: {},
  subjects: {},
  otherTests: {},
  tracks: {},
  campusSelected: '',
  testSelected: '',
  completedCountForTest: 0,
  classroomSelected: '',
  subjectSelected: '',
  trackSelected: '',
  campusSelectedLabel: '',
  testSelectedLabel: '',
  comparisionTestSelectedLabel: '',
  comparisionTestSelected: '',
  classroomSelectedLabel: '',
  subjectSelectedLabel: '',
  trackSelectedLabel: '',
  renderMoreQuestions: false,
  userType: '',
  subjectTrack1Comparison: [],
  subjectTrack2Comparison: [],
  track1PerformanceComparison: [],
  track2PerformanceComparison: [],
  currentTestPerformance: [],
  previousTestPerformance: [],
  testMinScore: 0,
  trackSetter: {},
  testStudentDistribution: {},
  graphRangeValue: [],
  subjectsMedian: {},
  previousTests: [],
  previousTestName: '',
  PageError: false,
  isOnline: false,
  testResponses: [],
  errors: [],
  status: -1,
  showModal: false,
  showLoader: true,
  medianData: {},
  institute: {},
  isFilter: false,
  filterType: 'Filter',
  isMenuBar: false,
  testType: {},
  testTypeSelected: '',
  testTypeSelectedLabel: '',
  userName: '',
  newUser: '',
  gender: 'M',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA_MANAGEMENT_MODAL: {
      return { ...state, showModal: action.payload };
    }
    case TEST_OVERVIEW: {
      return { ...state, ...action.payload };
    }
    case INSTITUTE_DETAILS: {
      return { ...state, institute: action.payload };
    }
    case UPDATE_FILTERS_MODEL: {
      return {
        ...state,
        isFilter: action.payload.state,
        filterType: action.payload.type,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        showLoader: true,
      };
    }
    case UPDATE_MENU_MODEL: {
      return { ...state, isMenuBar: !state.isMenuBar };
    }
    case UPDATE_QUESTION_LIST: {
      return {
        ...state,
        majorErrorQuestions: [...state.majorErrorQuestions, ...action.payload.majorErrorQuestions],
        renderMoreQuestions: action.payload.renderMoreQuestions,
      };
    }
    case TEST_LISTS: {
      return {
        ...state,
        testList: action.payload.testList,
        showLoader: false,
      };
    }
    case STUDENT_RESULT_UPLOAD_STATUS_DELETE: {
      let { errors, status } = action;
      if (!errors) {
        errors = [];
      }
      if (!status) {
        status = '';
      }
      return {
        ...state,
        errors,
        status,
      };
    }
    case UPDATE_FILTERS: {
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };
    }
    case RESET_FILTERS: {
      return initialState;
    }
    case UPDATE_MODAL: {
      return { ...state, showModal: action.payload };
    }
    case UPDATE_DATA_MANAGEMENT: {
      return { ...state, status: action.payload.status };
    }
    case UPDATE_TEST_DETAIL: {
      return { ...state, status: action.status };
    }
    case USER_DETAILS: {
      return {
        ...state,
        userName: action.payload.userName,
        newUser: action.payload.newUser,
        gender: action.payload.gender,
      };
    }
    default:
      return state;
  }
}
