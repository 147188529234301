import StyleConstants from './styleConstants/styles.json';

export const TRACKS = 'Tracks';
export const CLASSES = 'All Classes';
export const CAMPUSES = 'All Campuses';
export const SUBJECTS = 'All Subjects';
export const SUBJECT_SELECTED = 'subjectSelected';
export const CAMPUS_SELECTED = 'campusSelected';
export const CLASSROOM_SELECTED = 'classroomSelected';
export const TRACK_SELECTED = 'trackSelected';
export const SUBJECT_SELECTED_LABEL = 'subjectSelectedLabel';
export const TEST_TYPE_SELECTED_LABEL = 'testTypeSelectedLabel';
export const CAMPUS_SELECTED_LABEL = 'campusSelectedLabel';
export const CLASSROOM_SELECTED_LABEL = 'classroomSelectedLabel';
export const TRACK_SELECTED_LABEL = 'trackSelectedLabel';
export const COMPARISION_TEST_SELECTED_LABEL = 'comparisionTestSelectedLabel';
export const TESTS = 'tests';
export const TEST_SELECTED = 'testSelected';
export const COMPARISION_TEST_SELECTED = 'comparisionTestSelected';
export const TEACHERS = 'teachers';
export const TEACHER_SELECTED = 'teacherSelected';
export const TEST_TYPE = 'NEET';
export const TEST_TYPE_SELECTED = 'testTypeSelected';
export const COMPARE_WITH = 'Compare With';
export const FILTER = 'Filter';
export const TEST_LIST = 'Test List';
export const CAMPUS = 'Campus';
export const CLASSROOM = 'Classrooms';
export const TEST_COMPARSION = 'test_comparision';
export const TEST_LIST_PAGE = 'test_list';
export const HIGHLIGHTS = 'highlights';
export const ERRORS = 'Errors';
export const RESET = 'Reset';
export const VIEW_RESULT = 'View Result';
export const SEND_SMS = ['Send Sms', 'Sms Sent', 'Sms Not Sent Try Again'];
export const UPLOAD = 'Upload';
export const IN_PROGRESS = 'Processing Result';
export const SAMPLE_ID = 6195;
export const TOAST = {
  SMS: { TEXT: 'SMS to parents will be send', DURATION: 5000 },
  DENIED: { TEXT: "You don't have permission", DURATION: 5000 },
};
export const FILE_TYPE = {
  EXCEL: 1,
  PDF: 2,
};
export const DIFFICULTY_VALUE_MAPPING = [null, 'EASY', 'EASY', 'MEDIUM', 'HARD', 'HARD'];
export const COMPLEXITY_COLOR = [null, '#00BF70', '#00BF70', '#FFC352', StyleConstants.color.error, StyleConstants.color.error];
export const PAYLOAD = {
  campusSelected: 'campus_id', classroomSelected: 'classroom_id', subjectSelected: 'subject_id', testTypeSelected: 'test_type_id',
};
export const UPDATE_STATUS = Object.freeze({
  ERROR: 0,
  PROCESS: 1,
  COMPLETE: 2,
  UNUPLOAD: 3,
});
export const SMS_PERMISSION = {
  HAS_ACCESS: 0,
  PERMISSION_ISSUE: 1,
  INSTITUTE_ACCESS_ISSUE: 2,
};

export const getDropDownStyle = (length) => {
  return {
    control: (base) => ({
      ...base,
      height: 22,
      width: length,
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      background: 'transparent',
      boxShadow: '0 !important',
      color: '#454545',
    }),
    option: (base) => ({
      ...base,
      color: '#454545',
      textAlignLast: 'left',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      width: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: StyleConstants.color.webTitle,
      fontSize: 16,
      fontWeight: 'bold',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: StyleConstants.color.webTitle,
    }),
  };
};
