import React from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Api from '../../helpers/Api';
import StyleConstants from '../../shared/constants/styleConstants/styles'
class UploadResultError extends React.Component {

  static propTypes = {
      id: PropTypes.number
    };

    static defaultProps = {
      id: 0
    };

    constructor(props) {
      super(props);
      this.state = {
        errors: [],
        isLoading: true,
      };
    }

    componentDidMount() {
      const {id} = this.props;
      Api({
        method: 'get',
        url: `/tests/upload_student_result_status?test_id=${id}`,
      }).then((response) => {
        if (response) {
          this.setState({errors: response.errors, isLoading: false});
        }
      });
    }

    render() {
      const {errors, isLoading} = this.state;
      if (isLoading || errors.length < 1) {
        return(
          <div className="d-flex flex-column" style={{margin: '10%'}}>
            <p>Wait getting error Report</p>
          </div>
        );
      } else if (!isLoading || errors.length > 0) {
        return (
          <div className="row mt-5">
            <div className="col-sm-8 offset-2">
              <p style={{ fontSize: StyleConstants.textSize.text }}>Please resolve these errors - </p>
              <p style={{ color: "red", padding: 0, margin: 0 }}>
              {errors.map((error, index) => (index === 0 ? <p style={{ fontSize: StyleConstants.textSize.subHeader }}>{error}</p> : <span>{error}, </span>))}
              </p>
            </div>
          </div>
        );
      }
    }
}

export default UploadResultError;
