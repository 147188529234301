import React from 'react';
import { connect } from 'react-redux';
import {
  VerticalBarSeries, XAxis, Crosshair, HorizontalGridLines,
  VerticalGridLines, YAxis, LabelSeries, FlexibleWidthXYPlot,
} from 'react-vis';
import PropTypes from 'prop-types';
import { CustomAxisLabel } from './common';
import { isMobileDevice } from '../helpers/Utils';
import StyleConstants from '../shared/constants/styleConstants/styles.json';

const IS_MOBILE = isMobileDevice();
const BAR_CHART_COLOR = [StyleConstants.color.error, '#FFC352', '#01D28E'];

const styles = {
  barColor:
  {
    height: 14, width: 18, marginRight: 10,
  },
  barColorContainer:
  {
    display: 'flex', alignItems: 'center', marginTop: 10, marginLeft: 20,
  },
  barTitle:
  {
    fontSize: 10, marginRight: 10, whiteSpace: 'nowrap',
  },
};
class ScoreDistributionGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
    };
    this.onMouseOverUpdateGraph = this.onMouseOverUpdateGraph.bind(this);
    this.onMouseLeaveUpdateGraph = this.onMouseLeaveUpdateGraph.bind(this);
  }

  onMouseOverUpdateGraph(value, { index }) {
    const { scoreDistribution } = this.props;
    const { graph_range_values: graphRangeValues } = scoreDistribution;
    const graphValue = scoreDistribution.graph_scores;
    this.setState({ crosshairValues: [{ x: (index === 0) ? ` < ${graphRangeValues[index]}` : `${graphRangeValues[index - 1]} - ${graphRangeValues[index]}`, y: graphValue[`graph_range_${index + 1}`] }] });
  }

  onMouseLeaveUpdateGraph() {
    this.setState({ crosshairValues: [] });
  }

  renderDistribution() {
    return (
      <div style={{ ...styles.barColorContainer }}>
        <div style={{ ...styles.barColor, backgroundColor: StyleConstants.color.error }} />
        <div style={{ ...styles.barTitle }}>Remedy Zone</div>
        <div style={{ ...styles.barColor, backgroundColor: '#FFC352' }} />
        <div style={{ ...styles.barTitle }}>Upgrade Zone</div>
        <div style={{ ...styles.barColor, backgroundColor: '#01D28E' }} />
        <div style={{ ...styles.barTitle }}>Safe Zone</div>
      </div>
    );
  }

  render() {
    const {
      scoreDistribution, labelAngle,
    } = this.props;
    const { crosshairValues } = this.state;
    const { graph_range_values: graphRangeValues } = scoreDistribution;
    const scoreDistributionGraph = {
      series1: [],
      series2: [],
      series3: [],
      overall: [],
    };
    const barChartColor = [];
    const incrementer = Math.ceil(graphRangeValues.length / 3);
    const incrementerLength = graphRangeValues.length % 3;
    const maxValue = Math.max(...Object.values(scoreDistribution.graph_scores));
    let colorState = 0;
    for (let index1 = 0; index1 <= BAR_CHART_COLOR.length - 1; index1 += 1) {
      colorState = ((index1 === 1 && incrementerLength === 2)
      || (index1 === 2 && incrementerLength === 1)) ? incrementer + 1 : incrementer;
      colorState = incrementerLength === 0 ? colorState : colorState - 1;
      for (let index = 0; index < colorState; index += 1) {
        barChartColor.push(BAR_CHART_COLOR[index1]);
      }
    }
    let roundedValue = maxValue / 5;
    roundedValue = (roundedValue * 5) / 100;
    const graphValue = scoreDistribution.graph_scores;
    if (!graphValue || Object.keys(graphValue).length <= 0) {
      return (
        <div style={{ textAlign: 'center' }}>
          No scores for this Filter
        </div>
      );
    }
    for (let index = 0; index <= (graphRangeValues.length - 1); index += 1) {
      let yvalue = graphValue[`graph_range_${index + 1}`];
      yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
      yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue : roundedValue;
      const data = {
        x: (index === 0) ? ` < ${graphRangeValues[index]}` : `${graphRangeValues[index - 1]} - ${graphRangeValues[index]}`,
        y: yvalue,
        color: barChartColor[index],
      };
      scoreDistributionGraph.overall.push(data);
      scoreDistributionGraph[`series${parseInt((index / incrementer), 10) + 1}`].push(data);
    }
    return (
      <div style={{ userSelect: 'none', margin: 20 }}>
        <style jsx>
          {`
            .div-border {
              border-radius: 4px;
              border: 1px solid #BFBFBF;
              padding: 25px;
              margin-right: 20px;
            }
          `}
        </style>
        <FlexibleWidthXYPlot
          margin={{ left: IS_MOBILE ? 60 : 80, bottom: IS_MOBILE ? 60 : 70 }}
          onMouseLeave={this.onMouseLeaveUpdateGraph}
          xType="ordinal"
          height={258}
        >
          <XAxis title="" tickLabelAngle={labelAngle} style={{ ticks: { fontSize: IS_MOBILE ? 9 : 14, fontWeight: 'bold' } }} />
          <YAxis title="" style={{ ticks: { fontWeight: 'bold', fontSize: IS_MOBILE ? 9 : 14 } }} />
          <CustomAxisLabel title="No. of Students" style={{ fontSize: 12 }} />
          <CustomAxisLabel title="Score Range" style={{ fontSize: 12 }} innerHeight={200} xAxis />
          <HorizontalGridLines />
          <VerticalGridLines />
          <VerticalBarSeries
            data={scoreDistributionGraph.overall}
            barWidth={0.3}
            colorType="literal"
          />
          <LabelSeries
            onNearestX={this.onMouseOverUpdateGraph}
            data={scoreDistributionGraph.overall}
          />
          <Crosshair
            values={crosshairValues}
            titleFormat={(data) => ({ title: 'Marks', value: data[0].x })}
            itemsFormat={(data) => {
              return [{ title: 'No of Students', value: `${data[0].y}` }];
            }}
          />
        </FlexibleWidthXYPlot>
      </div>
    );
  }
}

ScoreDistributionGraph.propTypes = {
  labelAngle: PropTypes.number,
  scoreDistribution: PropTypes.object,
};

ScoreDistributionGraph.defaultProps = {
  labelAngle: 0,
  scoreDistribution: {},
};

const mapStateToProps = ({ insight }) => ({
  scoreDistribution: insight.scoreDistribution,
});

export default connect(mapStateToProps)(ScoreDistributionGraph);
