import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { connect } from 'react-redux';
import StyleConstants from '../../shared/constants/styleConstants/styles.json';
import Dropdown from '../../shared/images/Dropdown.svg';
import { updateFilterData } from '../../actions';
import {
  TEST_TYPE, TEST_TYPE_SELECTED, TRACKS, CLASSES, CAMPUSES, TRACK_SELECTED,
  CAMPUS_SELECTED, CLASSROOM_SELECTED, CLASSROOM_SELECTED_LABEL, SUBJECTS, SUBJECT_SELECTED,
  SUBJECT_SELECTED_LABEL, CAMPUS_SELECTED_LABEL, PAYLOAD,
} from '../../shared/constants/textConstants';

class FilterLabel extends React.Component {
  resetFields() {
    const { updateResults, testId, updateFilterData } = this.props;
    const payload = { id: testId };
    updateResults(payload);
    updateFilterData(CLASSROOM_SELECTED, '');
    updateFilterData(SUBJECT_SELECTED, '');
    updateFilterData(CAMPUS_SELECTED, '');
    updateFilterData(TEST_TYPE, '');
    updateFilterData(CLASSROOM_SELECTED_LABEL, '');
    updateFilterData(SUBJECT_SELECTED_LABEL, '');
    updateFilterData(CAMPUS_SELECTED_LABEL, '');
    updateFilterData(TEST_TYPE_SELECTED, '');
  }

  updateFilters(label, type) {
    const { updateFilterData, updateResults } = this.props;
    let value = label;
    if (type === CAMPUS_SELECTED || type === CLASSROOM_SELECTED
      || type === TRACK_SELECTED || type === SUBJECT_SELECTED || type === TEST_TYPE_SELECTED) {
      updateFilterData(`${type}Label`, label);
      value = label.value;
    }
    if (type === CAMPUS_SELECTED) {
      updateFilterData(CLASSROOM_SELECTED, '');
      updateFilterData(CLASSROOM_SELECTED_LABEL, '');
    }
    if (type === TEST_TYPE_SELECTED) {
      updateFilterData(CLASSROOM_SELECTED, '');
      updateFilterData(SUBJECT_SELECTED, '');
      updateFilterData(CAMPUS_SELECTED, '');
      updateFilterData(CLASSROOM_SELECTED_LABEL, '');
      updateFilterData(SUBJECT_SELECTED_LABEL, '');
      updateFilterData(CAMPUS_SELECTED_LABEL, '');
    }
    updateFilterData(type, value);
    const {
      testId, campusSelected, classroomSelected, subjectSelected,
      trackSelected, testTypeSelected,
    } = this.props;
    let payload = {};
    if (type === TEST_TYPE_SELECTED) {
      payload = { id: testId, test_type_id: testTypeSelected || '' };
    } else {
      payload = {
        id: testId,
        campus_id: campusSelected || '',
        classroom_id: type === CAMPUS_SELECTED ? '' : (classroomSelected || ''),
        subject_id: subjectSelected || '',
        track: trackSelected || '',
        test_type_id: testTypeSelected || '',
      };
    }
    payload[PAYLOAD[type]] = value;
    updateResults(payload);
  }


  renderDropDowns(dataSet, selectedItem, selectedFunction, item, selectWidth, showFilter = true) {
    const { campusSelected, subjectSelected } = this.props;
    const selectBoxStyle = {
      control: (base) => ({
        ...base,
        height: 37,
        backgroundColor: '#F2F2F2',
        border: '1px solid #E2E2E2',
        width: 140,
        fontSize: StyleConstants.textSize.text,
        cursor: 'pointer',
      }),
      option: (base) => ({
        ...base,
        border: '1px solid #F8F8F8',
        color: '#343B40',
      }),
      indicatorSeparator: (provided) => (
        {
          ...provided,
          width: 0,
        }
      ),
      singleValue: (provided) => ({
        ...provided,
        color: '#122E48',
        fontSize: StyleConstants.textSize.text,
        fontWeight: 'bold',
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#122E48',
          fontSize: StyleConstants.textSize.text,
        };
      },
    };
    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={Dropdown} alt="dropdown" />
        </components.DropdownIndicator>
      );
    };
    if (!showFilter) {
      return (
        <div />
      );
    }
    const filterItems = [];
    if (item === TRACKS && subjectSelected) {
      for (let i = 1; i <= parseInt(dataSet[subjectSelected], 10); i += 1) {
        filterItems.push({ label: `Track ${i}`, value: i });
      }
    } else {
      for (let i = 0; i < dataSet.length; i += 1) {
        if (item !== CLASSES || parseInt(campusSelected, 10) === parseInt(dataSet[i].campus_id, 10)) {
          filterItems.push({ label: dataSet[i].name, value: dataSet[i].id });
        }
        if (item === CLASSES && !campusSelected) {
          filterItems.push({ label: dataSet[i].name, value: dataSet[i].id });
        }
      }
    }
    const boxWidth = { width: selectWidth };
    return (
      <div className="ml-3" style={boxWidth}>
        <Select
          components={{ DropdownIndicator }}
          value={selectedItem}
          onChange={selectedFunction}
          options={filterItems}
          placeholder={item}
          styles={selectBoxStyle}
          isSearchable={false}
        />

      </div>
    );
  }

  render() {
    const {
      menuData, campuses, classrooms, subjects, tracks, testType,
      subjectSelectedLabel, campusSelectedLabel, classroomSelectedLabel,
      subjectSelected, campusSelected, classroomSelected, testTypeSelected,
      trackSelectedLabel, testTypeSelectedLabel,
    } = this.props;
    const filterApplied = subjectSelected || campusSelected
    || classroomSelected || testTypeSelected;
    return (
      <div className="hide-in-print">
        {menuData.isFilter && menuData
          && (
          <div style={{ alignItems: 'center' }}>
            <div style={{ marginLeft: 'auto', display: 'flex' }}>
              <div>
                {menuData.isTestType && this.renderDropDowns(
                  Object.values(testType), testTypeSelectedLabel,
                  (selectedListItem) => this.updateFilters(
                    selectedListItem, TEST_TYPE_SELECTED,
                  ), TEST_TYPE, 150,
                )}
              </div>
              <div>
                {menuData.isSubject && this.renderDropDowns(
                  Object.values(subjects), subjectSelectedLabel,
                  (selectedListItem) => this.updateFilters(
                    selectedListItem, SUBJECT_SELECTED,
                  ), SUBJECTS, 150,
                )}
              </div>
              <div>
                {menuData.isTrack && this.renderDropDowns(
                  tracks, trackSelectedLabel,
                  (selectedListItem) => this.updateFilters(
                    selectedListItem, TRACK_SELECTED,
                  ), 'Tracks', 100,
                )}
              </div>
              <div>
                {menuData.isCampus
                   && this.renderDropDowns(
                     Object.values(campuses), campusSelectedLabel,
                     (selectedListItem) => this.updateFilters(
                       selectedListItem, CAMPUS_SELECTED,
                     ), CAMPUSES, 150,
                   )}
              </div>
              <div>
                {menuData.isClassroom && this.renderDropDowns(
                  Object.values(classrooms), classroomSelectedLabel,
                  (selectedListItem) => this.updateFilters(
                    selectedListItem, CLASSROOM_SELECTED,
                  ), CLASSES, 100,
                )}
              </div>
              {menuData.isFilter && filterApplied && (
              <div
                style={{ marginLeft: 60, cursor: 'pointer' }}
                role="presentation"
                onClick={() => this.resetFields()}
              >
                <p style={{
                  padding: '0 14px',
                  margin: 0,
                  height: 37,
                  color: StyleConstants.color.white,
                  width: 'max-content',
                  fontSize: StyleConstants.textSize.text,
                  borderRadius: 4,
                  border: '1px solid #CBCBCB',
                  lineHeight: '34px',
                  background: '#112F56',
                }}
                >
                  {' '}
                  Clear Filters
                  {' '}
                </p>
              </div>
              )}
            </div>
          </div>
          )}
      </div>
    );
  }
}

FilterLabel.propTypes = {
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  tracks: PropTypes.object,
  subjects: PropTypes.object,
  testType: PropTypes.object,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  subjectSelected: PropTypes.number,
  trackSelected: PropTypes.number,
  testTypeSelected: PropTypes.number,
  campusSelectedLabel: PropTypes.object,
  testSelectedLabel: PropTypes.object,
  classroomSelectedLabel: PropTypes.object,
  subjectSelectedLabel: PropTypes.object,
  trackSelectedLabel: PropTypes.object,
  testTypeSelectedLabel: PropTypes.object,
  testId: PropTypes.number,
  updateFilterData: PropTypes.func.isRequired,
  updateResults: PropTypes.func.isRequired,
  menuData: PropTypes.object,
};

FilterLabel.defaultProps = {
  campuses: {},
  classrooms: {},
  tracks: {},
  subjects: {},
  testType: {},
  campusSelected: '',
  classroomSelected: '',
  subjectSelected: '',
  trackSelected: '',
  testTypeSelected: '',
  campusSelectedLabel: {},
  testSelectedLabel: {},
  classroomSelectedLabel: {},
  subjectSelectedLabel: {},
  trackSelectedLabel: {},
  testTypeSelectedLabel: {},
  testId: 0,
  menuData: {},
};

const mapStateToProps = ({ insight }) => ({
  campuses: insight.campuses,
  classrooms: insight.classrooms,
  subjects: insight.subjects,
  tracks: insight.tracks,
  testType: insight.testType,
  campusSelected: insight.campusSelected,
  classroomSelected: insight.classroomSelected,
  subjectSelected: insight.subjectSelected,
  trackSelected: insight.trackSelected,
  testTypeSelected: insight.testTypeSelected,
  campusSelectedLabel: insight.campusSelectedLabel,
  testSelectedLabel: insight.testSelectedLabel,
  classroomSelectedLabel: insight.classroomSelectedLabel,
  subjectSelectedLabel: insight.subjectSelectedLabel,
  trackSelectedLabel: insight.trackSelectedLabel,
  testTypeSelectedLabel: insight.testTypeSelectedLabel,
});

export default connect(mapStateToProps, { updateFilterData })(FilterLabel);
