import React from 'react';
import { connect } from 'react-redux';
import {
  XAxis, YAxis, HorizontalGridLines, VerticalBarSeries, Crosshair, FlexibleWidthXYPlot,
} from 'react-vis';
import PropTypes from 'prop-types';
import {
  Header, CustomAxisLabel, SideBar, Loader, Filter, TestHeader, SubjectView,
} from './common';
import { SUBJECTS } from '../shared/constants/textConstants';
import { isMobileDevice } from '../helpers/Utils';
import ScoreDistributionGraph from './scoreDistributionGraph';
import StyleConstants from '../shared/constants/styleConstants/styles.json';
import { SIDE_BAR_ITEMS } from '../shared/constants/fieldTypes';
import { getScoreDistributionData, enableLoader } from '../actions';

const IS_MOBILE = isMobileDevice();

const styles = {
  cardTitle:
  {
    fontSize: 18, fontWeight: 'bold', marginBottom: 2, textAlign: 'left',
  },
  class:
  {
    color: StyleConstants.color.white, fontSize: 22, width: '32%', padding: 11, textAlign: 'center', borderRadius: 3,
  },
};
const COLOR_SCHEMA = ['#4786FF', '#9B51E0', '#F2994A', '#8CC63F', '#6C9BAA'];

class TestScoreDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
      id: props.match.params.id,
    };
    this.updateResults = this.updateResults.bind(this);
    this.renderScoreCard = this.renderScoreCard.bind(this);
    this.renderCardEntry = this.renderCardEntry.bind(this);
    this.onMouseOverUpdateGraph = this.onMouseOverUpdateGraph.bind(this);
    this.onMouseLeaveUpdateGraph = this.onMouseLeaveUpdateGraph.bind(this);
    this.renderLegend = this.renderLegend.bind(this);
  }

  componentDidMount() {
    const {
      getScoreDistributionData,
    } = this.props;
    const { id } = this.state;
    const payload = { id };
    getScoreDistributionData(payload);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  onMouseLeaveUpdateGraph() {
    this.setState({ crosshairValues: [] });
  }

  onMouseOverUpdateGraph(value, { index }) {
    const { scoreDistribution, subjectSelected, subjects } = this.props;
    const subjectList = subjectSelected ? {} : subjects;
    const rangeStartValue = subjectSelected
      ? parseInt((20 / scoreDistribution.sub_fields.length), 10)
      : parseInt((80 / Object.keys(subjectList).length), 10);
    const scoreDistributionSubGraphs = [];
    for (let j = 0; j < scoreDistribution.sub_fields.length; j += 1) {
      const scoreDistributionSubGraph = [];
      for (let i = 1; i <= Object.values(scoreDistribution.graph_scores).length; i += 1) {
        scoreDistributionSubGraph.push({
          x: (i === 1) ? ` < ${rangeStartValue}` : `${(rangeStartValue * (i - 1)) + 1} - ${rangeStartValue * i}`,
          y: scoreDistribution.sub_graph_scores[`sub_graph_range_${scoreDistribution.sub_fields[j]}_${i}`],
        });
      }
      scoreDistributionSubGraphs.push(scoreDistributionSubGraph);
    }
    this.setState({ crosshairValues: scoreDistributionSubGraphs.map((d) => d[index]) });
  }

  getSubjectList() {
    const { subjects, subjectSelected, scoreDistribution } = this.props;
    let subjectList = subjectSelected ? {} : subjects;
    subjectList = subjectSelected && scoreDistribution.sub_fields.length === 2 ? { 1: { id: 1, name: 'Track 1' }, 2: { id: 2, name: 'Track 2' } } : subjectList;
    return subjectList;
  }

  resize() {
    this.forceUpdate();
  }

  updateResults(payload) {
    const { id } = this.state;
    const { getScoreDistributionData, enableLoader } = this.props;
    if (payload.id && payload.id !== id) {
      window.location.href = `/test_score_distribution/${payload.id}`;
    }
    enableLoader();
    getScoreDistributionData(payload);
  }

  renderCardEntry(data, highlightName, showBackgroundColor) {
    const { subjectSelected, scoreDistribution, subjects } = this.props;
    let subjectList = subjectSelected ? {} : subjects;
    subjectList = scoreDistribution.sub_fields.length === 2 ? { 1: { id: 1, name: 'Track_1' }, 2: { id: 2, name: 'Track_2' } } : subjectList;
    const headStyle = { paddingLeft: 10 };
    return (
      <tr key={data.name} style={{ backgroundColor: showBackgroundColor ? '#efefef' : '#000000080' }}>
        {(highlightName) && (
        <th style={headStyle}>
          <span style={{ fontWeight: 'bold', fontSize: StyleConstants.textSize.text }}>
            {' '}
            {data.name}
            {' '}
          </span>

        </th>
        )}
        {!(highlightName) && (
        <td style={headStyle}>
          {' '}
          {data.name}
          {' '}
        </td>
        )}
        {Object.values(subjectList).map((subject) => {
          return (
            <React.Fragment key={`${data.name}_${subject.name}_ranges`}>
              <td>
                {' '}
                {data[`${subject.name.toLowerCase()}_range_1`]}
                {' '}
              </td>
              <td>
                {' '}
                {data[`${subject.name.toLowerCase()}_range_2`]}
                {' '}
              </td>
              <td style={{ borderRightColor: '#EAECF1' }}>
                {' '}
                {data[`${subject.name.toLowerCase()}_range_3`]}
                {' '}
              </td>
            </React.Fragment>
          );
        })}
      </tr>
    );
  }

  renderScoreCard(data) {
    const { scoreDistribution } = this.props;
    const campusCount = Object.values(scoreDistribution.scores).length;
    const isShowCampus = campusCount !== 1;
    return (
      <React.Fragment key={data.name}>
        {isShowCampus && this.renderCardEntry(data, true)}
        {Object.values(data.classrooms).map((classroom) => (
          this.renderCardEntry(classroom, false)))}
      </React.Fragment>
    );
  }

  renderGraph(graphSeries, index) {
    const { scoreDistribution } = this.props;
    const subjectIDs = scoreDistribution.sub_fields;
    const subjectID = subjectIDs[index];
    return (
      <VerticalBarSeries
        key={`Graph_${index}`}
        onNearestX={this.onMouseOverUpdateGraph}
        data={graphSeries}
        color={COLOR_SCHEMA[subjectID - 1]}
        style={{ stroke: '#fff', strokeWidth: 2 }}
        barWidth={0.6}
      />
    );
  }

  renderClassroomWiseDistribution() {
    const { scoreDistribution } = this.props;
    const { scores } = scoreDistribution;
    return (
      <div>
        {Object.keys(scores).map(
          (campus) => Object.keys(scores[campus].classrooms).map((classroom) => (
            <div style={{ marginBottom: 25 }}>
              <div style={{
                fontFamily: 'Nunito Sans', fontSize: StyleConstants.textSize.subHeader, fontWeight: 'bolder', margin: '5px 0', textAlign: 'left',
              }}
              >
                {scores[campus].classrooms[classroom].name}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {Object.keys(StyleConstants.zone).map((type, index) => (
                  <div style={{ background: StyleConstants.zone[type], ...styles.class }}>{scores[campus].classrooms[classroom][`range_${index + 1}`]}</div>
                ))}
              </div>
            </div>
          )),
        )}
      </div>
    );
  }

  renderSubjectWiseDistribution() {
    const { scoreDistribution, subjectSelected } = this.props;
    const { crosshairValues } = this.state;
    const subjectList = this.getSubjectList();
    const rangeStartValue = subjectSelected
      ? parseInt((20 / scoreDistribution.sub_fields.length), 10)
      : parseInt((80 / Object.keys(subjectList).length), 10);
    const maxValue = Math.max(...Object.values(scoreDistribution.sub_graph_scores));
    let roundedValue = maxValue / 5;
    roundedValue = (roundedValue * 10) / 100;
    const scoreDistributionSubGraphs = [];
    for (let j = 0; j < scoreDistribution.sub_fields.length; j += 1) {
      const scoreDistributionSubGraph = [];
      for (let i = 1; i <= Object.values(scoreDistribution.graph_scores).length; i += 1) {
        let yvalue = scoreDistribution.sub_graph_scores[`sub_graph_range_${scoreDistribution.sub_fields[j]}_${i}`];
        yvalue = yvalue && yvalue !== 'undefined' ? yvalue : 0;
        yvalue = (yvalue > roundedValue || yvalue === 0 || roundedValue < 1) ? yvalue
          : roundedValue;
        scoreDistributionSubGraph.push({
          x: (i === 1) ? ` < ${rangeStartValue}` : `${(rangeStartValue * (i - 1)) + 1} - ${rangeStartValue * i}`,
          y: yvalue,
        });
      }
      scoreDistributionSubGraphs.push(scoreDistributionSubGraph);
    }
    return (
      <FlexibleWidthXYPlot
        onMouseLeave={this.onMouseLeaveUpdateGraph}
        xType="ordinal"
        height={258}
        margin={{ left: 80, bottom: 70 }}
      >
        <YAxis title="" style={{ ticks: { fontSize: 12 }, title: { fontWeight: 'bold', fontSize: StyleConstants.textSize.text, fill: 'black' } }} />
        <XAxis title="" style={{ ticks: { fontSize: 12 }, title: { fontWeight: 'bold', fontSize: StyleConstants.textSize.text, fill: 'black' } }} />
        <CustomAxisLabel title="Student Count" />
        <CustomAxisLabel title="Score Distribution" innerHeight={200} xAxis />
        <HorizontalGridLines />
        {scoreDistributionSubGraphs.map((graphSeries, index) => (
          this.renderGraph(graphSeries, index)))}
        <Crosshair
          values={crosshairValues}
          titleFormat={(data) => ({ title: 'Marks', value: data[0].x })}
        />
      </FlexibleWidthXYPlot>
    );
  }

  renderLegend(subjectID, type) {
    const subjectList = this.getSubjectList();
    const isSubjects = type === SUBJECTS;
    const style = {
      height: 20,
      width: isSubjects ? 20 : 30,
      marginLeft: isSubjects ? 20 : 0,
      marginRight: isSubjects ? 10 : 5,
      backgroundColor: isSubjects ? COLOR_SCHEMA[subjectID - 1] : StyleConstants.zone[type],
    };
    return (
      <div className="d-flex flex-row" key={subjectID}>
        <div style={style} />
        <p style={{
          marginBottom: 0,
          marginLeft: isSubjects ? 10 : 0,
          fontSize: 12,
          marginRight: isSubjects ? 0 : 15,
        }}
        >
          {isSubjects ? subjectList[subjectID].name : type}
        </p>
      </div>
    );
  }

  render() {
    const { id } = this.state;
    const {
      scoreDistribution, subjectSelected, isFilter, name, date,
      campuses, campusSelected, classrooms, classroomSelected, showLoader, subjects,
    } = this.props;
    if (showLoader || !scoreDistribution.sub_fields) {
      return <Loader />;
    }
    if (isFilter) {
      return (
        <div>
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_SCORE_DISTRIBUTION} />
          <Filter updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_SCORE_DISTRIBUTION} testId={id} />
        </div>
      );
    }
    const ranges = subjectSelected ? [['< 35', '36 - 55', '56 - 90'], ['< 75', '76 - 110', '111 - 180']] : [['< 75', '76 - 110', '111 - 180'], ['< 300', '301 - 450', '451 - 720']];
    const subjectList = this.getSubjectList();
    const subjectIDs = Object.keys(subjectList);
    const showTrackDistribution = scoreDistribution.sub_fields.length !== 1;
    const showClassroomDistribution = scoreDistribution.scores
    && Object.keys(scoreDistribution.scores).length > 0;
    if (IS_MOBILE) {
      return (
        <div style={{ marginTop: 60, userSelect: 'none' }}>
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_SCORE_DISTRIBUTION" testId={id} />
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_SCORE_DISTRIBUTION} />
          <TestHeader testName={name} testDate={date} />
          <SubjectView testId={id} updateResults={this.updateResults} />
          <div style={{ display: 'flex', margin: '15px 30px' }}>
            <div style={{
              background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '5px 10px', width: 'fit-content', fontSize: 12, borderRadius: 4, marginRight: 5,
            }}
            >
              {campusSelected && campuses[campusSelected] ? campuses[campusSelected].name : 'All campuses'}

            </div>
            <div style={{
              background: StyleConstants.color.inActive, color: StyleConstants.color.white, padding: '5px 10px', width: 'fit-content', fontSize: 12, borderRadius: 4,
            }}
            >
              {classroomSelected && classrooms[classroomSelected] ? classrooms[classroomSelected].name : 'All classrooms'}

            </div>
          </div>
          <div className="Card-View" style={{ padding: '20px 0', marginBottom: 20 }}>
            <ScoreDistributionGraph />
          </div>
          {showClassroomDistribution && !classroomSelected
            && (
              <div>
                <div style={{ ...styles.cardTitle, paddingLeft: 20 }}>Classroom Distribution</div>
                <div className="Card-View" style={{ padding: 20, marginBottom: 20 }}>
                  <div style={{ display: 'flex', marginBottom: 20 }}>
                    {Object.keys(StyleConstants.zone).map((type, index) => (
                      this.renderLegend(index, type)
                    ))}
                  </div>
                  <div>
                    {this.renderClassroomWiseDistribution()}
                  </div>
                </div>
              </div>
            )}
        </div>
      );
    }

    return (
      <div className="row" style={{ backgroundColor: 'rgb(243,248,250)', userSelect: 'none' }}>
        <div style={{
          width: '20%', padding: 0, zIndex: 1, marginTop: StyleConstants.headerHeight.web,
        }}
        >
          <Header testId={id} updateResults={this.updateResults} menuData={SIDE_BAR_ITEMS.TEST_SCORE_DISTRIBUTION} />
          <SideBar menuList={SIDE_BAR_ITEMS} selectedOption="TEST_SCORE_DISTRIBUTION" testId={id} />
        </div>
        <div style={{
          width: '80%', paddingBottom: 100, padding: 0, marginTop: StyleConstants.headerHeight.web,
        }}
        >
          {Object.values(scoreDistribution.graph_scores).length <= 0
            ? (
              <div style={{
                textAlign: 'center', paddingTop: '30%', fontWeight: 'semi-bold', fontSize: 24,
              }}
              >
                No Subject Distribution for this Subject
              </div>
            )
            : (
              <div>
                <div style={{ padding: '10px 90px 4px 90px' }}>
                  <div style={{ ...styles.cardTitle }}>{subjectSelected ? 'Subject Distribution' : 'Overall Distribution'}</div>
                  <div className="Card-View" style={{ padding: 30, marginBottom: 20 }}>
                    <ScoreDistributionGraph />
                  </div>
                  {showTrackDistribution
                  && (
                  <div className="Card-View" style={{ padding: 30, marginBottom: 20 }}>
                    <div>
                      <div style={{ ...styles.cardTitle }}>{subjectSelected ? 'Track Distribution' : 'Subject Distribution'}</div>
                      {this.renderSubjectWiseDistribution()}
                      <div className="d-flex flex-row">
                        {subjectIDs.map((subjectID) => (this.renderLegend(subjectID, SUBJECTS)))}
                      </div>
                    </div>
                  </div>
                  )}
                </div>
                {showTrackDistribution
                && (
                <div style={{ padding: '0 90px 40px 90px' }}>
                  <div style={{ ...styles.cardTitle }}>Score Distribution</div>
                  <div className="Card-View" style={{ width: 'fit-content', marginBottom: 20 }}>
                    <div style={{ width: 'fit-content' }}>
                      <table
                        className="table"
                        border="1"
                        style={{
                          border: 'none', width: '100%', borderColor: '#EAECF1', fontSize: 12, textAlign: 'center',
                        }}
                      >
                        <thead>
                          <tr>
                            <th rowSpan="2" style={{ verticalAlign: 'middle' }}>
                              {' '}
                              <span style={{ fontWeight: 'bold', fontSize: StyleConstants.textSize.text }}>Campus & Classroom </span>
                            </th>
                            {Object.values(subjects).map((subject) => (
                              <th colSpan={ranges[0].length} key={subject.name}>
                                {' '}
                                <span style={{ fontWeight: 'bold', fontSize: StyleConstants.textSize.text }}>{subject.name}</span>
                                {' '}
                              </th>
                            ))}
                          </tr>
                          <tr>
                            {Object.values(subjects).map((subject) => (
                              <React.Fragment key={`${subject.name}_ranges`}>
                                {ranges[0].map((data, index) => {
                                  if (index < ranges[0].length - 1) {
                                    return (
                                      <th key={`range_${data}`}>
                                        {' '}
                                        <span style={{ color: '#9EA0A5' }}>{data}</span>
                                        {' '}
                                      </th>
                                    );
                                  }
                                  return (
                                    <th key={`range_${data}`}>
                                      {' '}
                                      <span style={{ color: '#9EA0A5' }}>{data}</span>
                                      {' '}
                                    </th>
                                  );
                                })}
                              </React.Fragment>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(scoreDistribution.scores).map((data) => (
                            this.renderScoreCard(data)))}
                          {this.renderCardEntry(
                            scoreDistribution.overall_scores, true, true,
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

TestScoreDistribution.propTypes = {
  scoreDistribution: PropTypes.object,
  getScoreDistributionData: PropTypes.func.isRequired,
  enableLoader: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  subjects: PropTypes.object,
  campuses: PropTypes.object,
  classrooms: PropTypes.object,
  subjectSelected: PropTypes.number,
  campusSelected: PropTypes.number,
  classroomSelected: PropTypes.number,
  isFilter: PropTypes.bool,
  match: PropTypes.object,
  name: PropTypes.string,
  date: PropTypes.string,
};

TestScoreDistribution.defaultProps = {
  scoreDistribution: {},
  showLoader: false,
  subjects: {},
  subjectSelected: '',
  campusSelected: '',
  classroomSelected: '',
  campuses: {},
  classrooms: {},
  isFilter: false,
  match: {},
  name: '',
  date: '',
};

const mapStateToProps = ({ insight }) => ({
  name: insight.name,
  date: insight.date,
  scoreDistribution: insight.scoreDistribution,
  subjectSelected: insight.subjectSelected,
  showLoader: insight.showLoader,
  campuses: insight.campuses,
  campusSelected: insight.campusSelected,
  classrooms: insight.classrooms,
  classroomSelected: insight.classroomSelected,
  subjects: insight.subjects,
  isFilter: insight.isFilter,
});

export default connect(mapStateToProps, { getScoreDistributionData, enableLoader })(TestScoreDistribution);
