import React, { Component } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import RouteNavigation from './components/routeNavigation';
import constants from './shared/constants/environmentConfig';
import './App.css';

const Constants = constants();
const cookies = new Cookies();
const history = createBrowserHistory({
  forceRefresh: true,
});

class App extends Component {
  render() {
    const userProfile = cookies.get('user');
    if (!userProfile) {
      history.push('/login');
    }
    if (Constants.cookie.secure) {
      return (
        <HttpsRedirect>
          <RouteNavigation userProfile={userProfile} />
          ;
        </HttpsRedirect>
      );
    }

    return <RouteNavigation userProfile={userProfile} />;
  }
}

export default App;
