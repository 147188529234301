import { createStore, applyMiddleware, compose } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';


import thunk from 'redux-thunk';

import reducers from '../../reducers';

export default function configureStore(preloadedState) {
  const middlewares = [thunk];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const storeEnhancers = [middlewareEnhancer];
  const { REACT_APP_ENV } = process.env;
  const composedEnhancer = REACT_APP_ENV === 'production' || REACT_APP_ENV === 'staging' ? compose(...storeEnhancers) : composeWithDevTools(...storeEnhancers);

  const store = createStore(
    reducers,
    preloadedState,
    composedEnhancer,
  );

  return store;
}
