import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends PureComponent {
  render() {
    const {
      backgroundColor, fillColor, width, height, fillPercentage,
    } = this.props;
    return (
      <div style={{
        display: 'flex', borderRadius: 5, backgroundColor, width, height,
      }}
      >
        <div style={{
          borderRadius: 5, backgroundColor: fillColor, width: fillPercentage, height,
        }}
        />
      </div>
    );
  }
}

ProgressBar.propTypes = {
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  fillPercentage: PropTypes.string,
};

ProgressBar.defaultProps = {
  backgroundColor: '#EDF1F5',
  fillColor: '#6FE97A',
  width: 100,
  height: 50,
  fillPercentage: '0%',
};

export default ProgressBar;
